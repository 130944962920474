import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Rating from '@mui/material/Rating'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  reviewBox: {
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '6px 6px 10px 0px #0000001A',
      textAlign: 'left'
  },
  reviewTextSpaced: {
    padding: '50px 30px 55px 30px',
    textAlign: 'left',
    lineHeight: '1.7',
  },
  reviewText: {
    padding: '50px 30px 30px 30px',
    textAlign: 'left',
    lineHeight: '1.7',
    fontSize: '16px'
  },
  customerInfo: {
    backgroundColor: '#F3F8FF',
    borderRadius: '0 0 10px 10px',
    padding: '23px 39px',  
    fontSize: '12px',
    color: '#8E939F',      
    '& p': {
      margin: '0px', 
    }
  },
  customerImage: {
    height: "57.24px",
    width: "59.77px",
    "@media (max-width: 325px)": {
      height: "40px",
      width: "40px",
      marginTop: '10px'
    },
  },
  customerDetailsSmall:{
    marginTop: '10px',
    marginLeft: '18px',
    fontSize: '14px'
  },
  customerDetailsLarge:{
    marginTop: '10px',
    fontSize: '14px'
  },
  customerName:{
    color: "#37517A", 
    fontSize: '16px'  
  }, 
  rating: {
    textAlign: 'right',
    marginTop: '13px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '25px'
    },
  },
  extraText: {
    padding: '50px 30px 55px 30px',
    textAlign: 'left',
    lineHeight: '1.7',
  }
});

function CustomerReviews(props) {
  const { classes, reviews } = props;
  const theme = useTheme();  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
              {reviews.map((review, index) => (
                <Grid key={index} item xs ={12} sm={12} md={6} lg={6}>
                  <div className={classes.reviewBox}>
                    <div className={review.customerReview.split('').length > 250 ? classes.reviewText : classes.reviewTextSpaced}>
                      <img src="/img/quote.png" alt="quote image"></img>
                      <div>{review.customerReview}</div>
                    </div>
                    <div className={classes.customerInfo}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img src={review.customerImage} className={classes.customerImage} alt="customer image"></img>  
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classnames(isMobile ? classes.customerDetailsSmall : classes.customerDetailsLarge)}>
                            <div className={classes.customerName}>{review.customerName}</div>
                            <p>Remitbee Customer</p>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Rating name='read-only' value={5} readOnly className={classes.rating} size={isMobile ? 'small' : ''}/> 
                        </Grid>
                      </Grid>                                      
                    </div>
                  </div>
                </Grid>                  
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

CustomerReviews.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerReviews);