import React from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(5)
  },
  box: {
    background: "#fff",
    borderRadius: "6px",
    border: '1px solid #CACCD5',
    padding: theme.spacing(3),
    height: '100%'
  },
  title: {
    textAlign: 'left',
    color: '#31517A',
    fontSize: '18px',
    lineHeight: '26px',
    fontFamily: 'Poppins',
    fontWeight: 600
  },
  content: {
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#626680',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: theme.spacing(2)
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  }
});

const CheckCards = ({ list, classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (list)
    return (
      <Grid container className={classes.container} spacing={isMobile ? 3 : 5} justifyContent='center'>
        {list.map((item, index) => (
          <Grid item xs={12} md={4} sm={6} key={index}>
            <div className={classes.box}>
              <Box display='flex'>
                <Box className={classes.check}>
                  <img src="/new/list-icon.svg" alt="Check icon" width="21px" height="21px" />
                </Box>
                <Box flexGrow={1}>
                  <Typography component='div' variant='h3' className={classes.title}>{item.title}</Typography>
                </Box>
              </Box>
              <Typography component='div' className={classes.content}>
                <Markdown children={item.text} />
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  else return null;
};

export default withStyles(styles)(CheckCards);
