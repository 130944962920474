import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import { bigTitle, container } from '../globalStyles';

const useStyles = makeStyles(theme => ({
    container,
    root: {
        display: 'flex',
        //paddingTop: '100px',
        paddingBottom: '60px',
        background: '#ffffff',
        itemsCentered: 'true',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '60px',
          paddingBottom: '60px',
        },
    },
    bigTitle,
    featuresBox: {
        marginBottom: "30px",
        transition: "ease-in-out 0.3s",
        borderRadius: "10px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "0",
        },
        "&:hover": {
            boxShadow: "12px 15px 20px 6px rgba(11,65,153,0.1)"
        },
        "& a": {
            display: 'contents',
            cursor: 'pointer',
            textDecoration: 'none',
        },
    },
    boxBackground: { 
        textAlign: 'center', 
        height: '282px', 
        width: '100%',
        display: 'inline-block',
        justifyContent: 'center', 
        background: props => `url(${props.image}) no-repeat center`, 
        backgroundSize: '100% 100% !important',
        boxShadow: 'inset 0 0 0 1000px rgba(49,81,122,.71)'
    }, 
    boxText: {
        textAlign: 'center', 
        fontSize: '40px', 
        color: 'white', 
        paddingTop: '110px'
    }, 
    stepsContainer: {
        marginTop: '20px'
    }
}))

const OtherServices = ({title, stepItems}) => { 
    const classes = useStyles();
    const items = parseArrFromString(stepItems)

    const renderSectionItem = (item) => {
        let { text, image, link } = item;
        const { boxBackground } = useStyles({image})
        return (
            <Grid Grid item xs={12} sm={6} md={4} className={classes.featuresBox}>
                <a href={link} target='_blank'>
                    <div className={ boxBackground }>
                        <Typography className={classes.boxText}>{text}</Typography>
                    </div>
                </a>
            </Grid>
        )
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3} className={classes.container}>
                <Grid item xs={12} className={classes.bigTitle}>
                    {title}
                </Grid>
                {items && items.length &&
                <Grid container spacing={5} className={classes.stepsContainer}>
                    {items.map(renderSectionItem)}
                </Grid>}
            </Grid>
        </div>
    )

}

export default OtherServices