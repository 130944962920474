import React, { useEffect, useState } from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import CustomButton from '../../components/CustomButtonsWebsite/CustomButton';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #CACCD5'
    }
  },
  box: {
    background: "#fff",
    borderRadius: "6px",
    border: '1px solid #CACCD5',
    padding: theme.spacing(3),
    height: '100%'
  },
  title: {
    textAlign: 'left',
    color: '#31517A',
    fontFamily: 'Poppins',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '26px',
    }
  },
  location: {
    fontFamily: 'Poppins',
    lineHeight: '24px',
    fontSize: '16px',
    color: '#626680',
    textAlign: 'left',
    paddingTop: theme.spacing(1)
  },
  content: {
    textAlign: 'left',
    color: '#626680',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: theme.spacing(2)
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center'
  },
  blueArrow: {
    transform: "rotate(-90deg) !important",
  },
  skeleton: {
    width: '100%',
    height: '40px'
  },
  listContainer: {
    padding: theme.spacing(3, 0),
    borderBottom: '1px solid #CACCD5'
  }
});

const JobPositions = ({ classes, manualAddJobItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [qandleAddJobList, setQandleAddJobList] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getList = async () => {
      const result = await axios.get('https://remitbee.qandle.com/recruitment-frontend/open-jobs');
      if (result && result.data && result.data.status === 'success') {
        setQandleAddJobList(result.data.data);
      }
      setLoading(false);
    }

   getList();
  }, []);

  const handleOpenManulJobLink = (event, link) => {
    event.preventDefault();
    if (window) window.open(`${link}`, '_blank');
  };
  const handleOpenLink = (event, linkId) => {
    event.preventDefault();
    if (window) window.open(`https://remitbee.qandle.com/#/jobs?jobId=${linkId}`, '_blank');
  };

  if (loading)
    return (
      <Grid container className={classes.container} justifyContent='center' spacing={3}>
        <Grid item xs={12}>
          <div className={classes.box}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.box}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.box}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </div>
        </Grid>
      </Grid>
    )

  if (!isMobile)
    return (
      <Grid container className={classes.container} justifyContent='center'>
        <Grid item container xs={8} spacing={3}>
          {qandleAddJobList && qandleAddJobList.map((item, index) => (
            <Grid item xs={12} key={index}>
              <div className={classes.box}>
                <Box display='flex'>
                  <Box flexGrow={1}>
                    <Typography className={classes.title}>{item.job_title && item.job_title.value}</Typography>
                    <Typography className={classes.location}>{item.location}</Typography>
                  </Box>
                  <Box className={classes.button}>
                    <CustomButton type="outlinedBlue" onClick={event => handleOpenLink(event, item._id)}>
                      {`Apply now`}
                    </CustomButton>
                  </Box>
                </Box>
              </div>
            </Grid>
          ))}
          {manualAddJobItems && manualAddJobItems.map((item, index) => (
            <Grid item xs={12} key={index}>
              <div className={classes.box}>
                <Box display='flex'>
                  <Box flexGrow={1}>
                    <Typography className={classes.title}>{item.title}</Typography>
                    <Typography className={classes.location}>{item.location}</Typography>
                  </Box>
                  <Box className={classes.button}>
                    <CustomButton type="outlinedBlue" onClick={event => handleOpenManulJobLink(event, item.link)}>
                      {`Apply now`}
                    </CustomButton>
                  </Box>
                </Box>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  if (isMobile)
    return (
      <>
        <Grid container className={classes.container} justifyContent='center'>
          {qandleAddJobList && qandleAddJobList.map((item, index) => (
              <Grid item container key={index} direction="row" xs={12} className={classes.listContainer} onClick={event => handleOpenLink(event, item._id)}>
                <Grid item xs={11}>
                  <Typography className={classes.title}>{item.job_title && item.job_title.value}</Typography>
                  <Typography className={classes.location}>{item.location}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.arrow}><img src="/img/landing/svg/blue_arrow.svg" className={classes.blueArrow} alt={`blue arrow ${index + 1}`} /></Grid>
              </Grid>
          ))}
           {manualAddJobItems && manualAddJobItems.map((item, index) => (
              <Grid item container key={index} direction="row" xs={12} className={classes.listContainer} onClick={event => handleOpenManulJobLink(event, item.link)}>
                <Grid item xs={11}>
                  <Typography className={classes.title}>{item.title }</Typography>
                  <Typography className={classes.location}>{item.location}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.arrow}><img src="/img/landing/svg/blue_arrow.svg" className={classes.blueArrow} alt={`blue arrow ${index + 1}`} /></Grid>
              </Grid>
          ))}
        </Grid>
      </>
    )
  else return null;
};

export default withStyles(styles)(JobPositions);
