import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4)
    }
  },
  reviewBox: {
    transition: "ease-in-out 0.3s",
    background: "#fff",
    borderRadius: "10px",
    border: '1px solid #CACCD5',
    padding: '40px 0 !important',
  },
  rating: {
    fontWeight: 600,
    lineHeight: '36px',
    fontFamily: 'Poppins, Heebo',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    }
  },
  stars: {
    height: '22px',
  },
  image: {
    height: '45px',
    marginBottom: theme.spacing(3)
  }
});

const Reviews = props => {
  const { classes } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item container spacing={isMobile ? 3 : 5} className={classes.root}>
      <Grid item xs={12} md={4}>
        <Box className={classes.reviewBox}>
          <Typography variant="h3" className={classes.rating}>4.6 Rating on Google</Typography>
          <a rel="noreferrer nofollow" target="_blank" href='https://www.google.com/search?q=remitbee&rlz=1C1CHBF_enCA850CA850&oq=remitbee&aqs=chrome..69i57j69i59j69i60l4.4486j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b46fd01475905:0x546334d0f0b5f73b,1,,,'>
            <Box display='flex' justifyContent='center'>
              <img
                src='/new/landing/google-logo.svg'
                className={classes.image}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
            <Box display='flex' justifyContent='center' className={classes.stars}>
              <img
                src='/new/landing/stars-4-half.svg'
                className={classes.stars}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
          </a>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box className={classes.reviewBox}>
          <Typography variant="h4" className={classes.rating}>4.7 Rating on Trustpilot</Typography>
          <a rel="noreferrer nofollow" target="_blank" href='https://www.trustpilot.com/review/remitbee.com'>
            <Box display='flex' justifyContent='center'>
              <img
                src='/new/landing/trustpilot-logo.svg'
                className={classes.image}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
            <Box display='flex' justifyContent='center' className={classes.stars}>
              <img
                src='/new/landing/stars-4-half.svg'
                className={classes.stars}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
          </a>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box className={classes.reviewBox}>
          <Typography variant="h4" className={classes.rating}>4.9 Rating on Facebook</Typography>
          <a rel="noreferrer nofollow" target="_blank" href='https://www.facebook.com/pg/Remitbee/reviews/?ref=page_internal'>
            <Box display='flex' justifyContent='center'>
              <img
                src='/new/landing/facebook-logo.svg'
                className={classes.image}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
            <Box display='flex' justifyContent='center' className={classes.stars}>
              <img
                src='/new/landing/stars-4-half.svg'
                className={classes.stars}
                alt="Google logo Remitbee reviews"
                loading="lazy"
              />
            </Box>
          </a>
        </Box>
      </Grid>
    </Grid>
  );
}

Reviews.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reviews);
