import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';


const styles = (theme) => ({
  container,
  bigTitle,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    overflow: 'hidden',
    paddingTop: '100px',
    paddingBottom: '100px',
    backgroundColor: '#ffffff !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  jobOpeningContainer: {
    justifyContent: 'space-between',
  },
  image: {
    paddingLeft: '20px',
    '@media (max-width: 576px)': {
      paddingLeft: '0',
      paddingTop: '10px',
    },
  },
  jobOpeningTitle: {
    marginBottom: '60px',
  },
  jobOpeningBox: {
    boxShadow: '0px 3px 9px 0px rgba(11,65,153,0.2)',
    borderRadius: '8px',
    display: 'inline-flex',
    padding: '30px 25px',
    transition: 'ease-in-out 0.3s',
    cursor: 'pointer',
    marginBottom: '40px',
    '&:hover': {
      boxShadow: '12px 15px 20px 6px rgba(11,65,153,0.1)',
    },
    '& a': {
      display: 'contents',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '48% !important',
      marginBottom: '40px',
      alignItems: 'center',
    },
    '@media (max-width: 576px)': {
      textAlign: 'center',
      display: 'inline-grid',
    },
  },
  position: {
    justifyContent: 'flex-start',
    fontSize: '20px',
    fontWeight: '400',
    color: '#31517A',
    '@media (max-width: 576px)': {
      placeSelf: 'center',
    },
  },
  location: {
    fontSize: '18px',
    fontWeight: '400',
    color: '#6B708F',
  },
  jobOpeningFlagElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 576px)': {
      display: 'block',
    },
  },
});

function CareersJobOpenings(props) {
  const { classes, content } = props;
  return (
    <Grid container className={classes.container + ' ' + classes.jobOpeningContainer}>
      {content.map((item) => {
        return (
          <Grid item xs={12} md={6} className={classes.jobOpeningBox}>
            <a href={item.actionLink}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  marked="center"
                  className={classes.position}
                  component="h2"
                >
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.jobOpeningFlagElement}>
                {item.text && (
                  <Typography
                    variant="h6"
                    marked="center"
                    className={classes.location}
                    component="h2"
                  >
                    <Markdown children={item.text} />
                  </Typography>
                )}
                <img
                  src="/img/landing/svg/standard_canadian_flag.svg"
                  className={classes.image}
                  alt="Canadian flag icon"
                />
              </Grid>
            </a>
          </Grid>
        );
      })}
    </Grid>
  );
}

CareersJobOpenings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CareersJobOpenings);
