import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import TabsSection from '../../components/Tabs/Tabs';
import { Card, CardMedia ,CardContent} from '@mui/material';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
  },
    box: {
      alignItems: "center",
      textAlign : "center",
      border: '1px solid #CACCD5',
  
    },
    name: {
        textAlign: 'center',
        color: '#31517A',
        fontFamily: 'Poppins',
        fontWeight: 500,  
        fontSize: '18px',
        lineHeight: '32px',
        marginBottom: 0,
        padding: "16px 0 8px 0",
        [theme.breakpoints.down('md')]: {
          padding: "16px 0px 4px 0px",
        },
    },
    text: {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Poppins',
        color: '#626680',
        fontSize: '16px',
        lineHeight: '30px',
        padding: "8px 0 24px 0",
        [theme.breakpoints.down('md')]: {
          padding: "4px 0px 24px 0px",
        },
    },
    content: {
        width: '306px',
        height: '186px',
        padding: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
          padding: '0 5px 0 5px',
          width: '100%',
        }
    },
  }));
  
  

const renderListBlock = (list, classes) => {
    return (
      <Grid className={classes.container} container justifyContent='center' spacing={4}>
        {list && list.map((item, index) => (
          <Grid item xs={12} md={4} sm={6} key={index} >
            <Card className={classes.box} elevation={0}>
                <CardMedia
                    image={`${item.source}`}
                    component="img"
                    alt="Team"
                    width="100%"
                />
                <CardContent className={classes.content} >
                    <Typography gutterBottom variant="h5" component="div" className={classes.name}>
                    {item.title}
                    </Typography>
                    <Typography className={classes.text} component="div">
                         <Markdown children={item.text} />
                    </Typography>
                </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

const ColomnSlider = ({ list }) => {
    const classes = useStyles();
    const [sliced, setSliced] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const TEAM_PER_SLIDE = isTablet ? 2 : isMobile ? 1 : 3;

    useEffect(() => {
      if (list.length > 3) {
        const cardsArray = list.reduce((acc, curVal, currentIndex) => {
          if (currentIndex % TEAM_PER_SLIDE === 0) {
            acc.push(
              renderListBlock(list.slice(currentIndex, currentIndex + TEAM_PER_SLIDE), classes)
            );
          }
          return acc;
        }, []);
        setSliced(cardsArray);
      }
    }, [TEAM_PER_SLIDE]);

      return (
        <> {sliced.length ? <TabsSection slider data={sliced} /> : renderListBlock(list, classes)} </>
      );
}

export default ColomnSlider;
