import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { body1, container, mediumTitle, whiteColor } from '../globalStyles';


const styles = theme => ({
    container,
    mediumTitle,
    body1,
    root: {
        display: 'flex',
        backgroundColor: whiteColor,
        textAlign: 'left',
        // paddingTop: '100px',
        // paddingBottom: '100px',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            // paddingTop: '80px',
            // paddingBottom: '80px',
            clipPath: 'none',
        },
    },
    containerAdjustments: {

    },
    image: {
        width: '100%',
        filter: 'drop-shadow( 2px 4px 12px rgba(11, 65, 153, .35))',
    },
    textInfo: {
        marginBottom: '40px',
        textAlign: 'center !important',
        fontSize: '25px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '25px',
        },
    },
    imageAlign1: {
        display: 'grid',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            marginBottom: '80px',
        },
    },
    imageAlign2: {
        display: 'grid',
        justifyContent: 'center',
        gridTemplateRows: 'min-content',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '40px !important'
        },
    },
});

function HowToPayBanksSection(props) {
    const { classes, content } = props;
    const titles = content[0];
    return (
        <>
            <Grid item xs={12} md={6} className={classes.imageAlign1}>
                <Typography variant="h2" marked="center" className={classes.mediumTitle + " " + classes.textInfo} component="h2">
                    {titles.title1}
                </Typography>
                <img
                    src='/img/landing/svg/etransfer_banks_centered.svg'
                    className={classes.image}
                    alt="Logos of all banks that support e-transfer"
                />
            </Grid>

            <Grid item xs={12} md={6} className={classes.imageAlign2}>
                <Typography variant="h2" marked="center" className={classes.mediumTitle + " " + classes.textInfo} component="h2">
                    {titles.title2}
                </Typography>
                <img
                    src='/img/landing/bill-payments-bank.png'
                    className={classes.image}
                    alt="Logos of all banks that support bill payment"
                />
            </Grid>
        </>
    );
}

HowToPayBanksSection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HowToPayBanksSection); 