import React, { useState, useEffect } from 'react';
import { Box, InputLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { InputBase } from '@mui/material';
import { Button } from '@mui/material';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FlagIcon from '../common/FlagIcon';
import clsx from 'clsx';
import { subscribeRateAlert } from '../../redux/auth/actions';
import NumberFormat from '../common/NumberFormat';

const styles = theme => ({
  labelBlue: {
    color: '#31517A',
    fontWeight: 600,
  },
  selector: {
    margin: theme.spacing(1, 0, 3)
  },
  labelTitle: {
    fontFamily: 'Inter',
    color: '#31517A',
    fontSize: '36px',
    lineHeight: '54px',
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },
  labelSubtitle: {
    fontFamily: 'Inter',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#31517A',
    fontWeight: 500,
  },
  labelGray: {
    color: '#626680',
    fontSize: '16px',
    lineHeight: '34px',
    fontFamily: 'Inter'
  },
  button: {
    fontSize: '18px',
    fontWeight: 500,
    padding: '15px 0'
  },
  buttonDisabled: {
    backgroundColor: '#CACCD5 !important',
    color: '#fff !important'
  },
  divider: {
    background: 'transparent',
    borderBottom: '1px solid #CACCD5',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0)
    }
  },
  verticalDivider: {
    background: 'transparent',
    borderLeft: '1px solid #CACCD5',
    marginRight: theme.spacing(3)
  },
  currency: {
    fontFamily: 'Inter',
    color: '#626680',
    lineHeight: '33px',
    fontSize: '22px',
    minWidth: '46px'
  },
  fixedValue: {
    color: '#A5A5A5',
    fontWeight: 500,
    fontSize: '20px',
    width: '100%',
    marginRight: theme.spacing(2)
  },
  typedValue: {
    marginRight: '16px',
    textAlign: 'center',
    color: '#31517A',
    fontWeight: 500,
    fontSize: '20px',
    width: '100%',
  },
  image: {
    marginBottom: theme.spacing(4)
  },
  input: {
    background: '#fff',
    height: '60px',
    width: '100%',
    border: '1px solid #CACCD5',
    borderRadius: '4px',
    padding: '0 16px',
  },
  inputEmail: {
    background: '#fff',
    height: '60px',
    width: '100%',
    border: '1px solid #CACCD5',
    borderRadius: '4px',
    padding: '0 16px',
    margin: theme.spacing(1, 0, 6)
  },
  checkLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#31517A'
  },
  flagIcon: { 
    marginRight: 10
  },
  box: {
    height: '100%',
    minHeight: '60px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  link: {
    color: '#1160F7',
    textDecoration: 'underline',
    cursor: 'pointer',
    "&:hover": {
      color: '#31517A'
    },
  },
  secondBlock: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  }
});

const RateEmail = ({ selected, current, isCurrencyExchange }) => {
  const useStyles = makeStyles(styles);
  const [currency, setCurrency] = useState(null);
  const [weekly, setWeekly] = useState(true);
  const [email, setEmail] = useState('');
  const [rate, setRate] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (current) setRate(current);
  }, [current]);

  useEffect(() => {
    if (selected.currency_code && selected.iso2) setCurrency(selected)
  }, [selected]);

  const handleSetFrequency = () => {
    setWeekly(!weekly);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    const data = {
      lead_email: email,
      threshold: rate
    }

    if (isCurrencyExchange && selected.currency_code === 'USD') {
      data.exchange_rate_id = selected.id;
      data.exchange_type = 'BUY';
    } else if (isCurrencyExchange && selected.currency_code === 'CAD') {
      data.exchange_rate_id = selected.id;
      data.exchange_type = 'SELL';
    } else {
      data.rate_id = selected.id;
    }

    await subscribeRateAlert(data);
    setSubmitted(true);
  }

  const handleTryAgain = event => {
    event.preventDefault()
    setEmail('');
    setRate(current || '');
    setSubmitted(false);
  }

  if (!submitted) return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item container xs={12} md={8}>
        <Grid item xs={12}>
          <Typography
            variant='h3'
            align='center'
            className={classes.labelTitle}>Want to catch the best rate?</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='h3'
            align='center'
            className={classes.labelGray}>Don’t worry! We’ve got you covered. Sign up for email notifications and we’ll let you know when your rate is available. We’ll also update you on all of the latest currency news.</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider
            className={classes.divider}
            sx={{
              opacity: "0.6"
            }} />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography
            variant='h3'
            className={classes.labelSubtitle}>E-mail me when</Typography>
        </Grid>

        <Grid item container xs={12} className={clsx(classes.grid, classes.selector)}>
          <Grid item xs={12} md={4}>
            <Box display='flex' className={classes.input}>
              <Box display='flex' alignItems='center' flexGrow={1}>
                <Typography className={classes.fixedValue} align='center'>{'1'}</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Divider
                  orientation='vertical'
                  className={classes.verticalDivider}
                  sx={{
                    opacity: "0.6"
                  }} />
                <FlagIcon
                  id="flag-icon"
                  code={ 'CA'}
                  className={classes.flagIcon}
                />
              </Box>
              <Box display='flex' alignItems='center' mr={2}>
                <Typography variant='h3' className={classes.currency}>{'CAD'}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.box} display='flex' alignItems='center'>
              <Typography
                variant='h3'
                align='left'
                className={classes.labelSubtitle}>is above</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            {currency &&
              <Box display='flex' className={classes.input}>
                <Box display='flex' alignItems='center' flexGrow={1}>
                  <InputBase
                    aria-label="Amount"
                    fullWidth
                    value={rate}
                    placeholder={'0.00'}
                    inputComponent={NumberFormat}
                    inputProps={{ maxLength: 10, decimalScale: 4, thousandSeparator: true }}
                    onChange={event => setRate(event.target.value)}
                    classes={{ input: classes.typedValue }} />
                </Box>
                <Box display='flex' alignItems='center'>
                  <Divider
                    orientation='vertical'
                    className={classes.verticalDivider}
                    sx={{
                      opacity: "0.6"
                    }} />
                  <FlagIcon
                    code={currency.iso2}
                    className={classes.flagIcon}
                  />
                </Box>
                <Box display='flex' alignItems='center' mr={2}>
                  <Typography variant='h3' algin='center' className={classes.currency}>{currency.currency_code}</Typography>
                </Box>
              </Box>
            }
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={weekly}
                  onChange={handleSetFrequency}
                  color="primary"
                />
              }
              classes={{ label: classes.checkLabel }}
              label="Send me daily updates"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="email">
              <Typography variant='h3' className={classes.checkLabel}>Enter your email address</Typography>
            </InputLabel>
            <InputBase
              id="email"
              name='email'
              value={email}
              autoComplete='false'
              label="Email address"
              onChange={event => setEmail(event.target.value)}
              variant="outlined"
              className={classes.inputEmail} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button
              aria-label="Submit"
              disabled={!email || !rate || !email.includes('@') || !email.includes('.com') || rate === ''}
              variant="contained"
              color="primary"
              fullWidth
              classes={{ root: classes.button, disabled: classes.buttonDisabled }}
              onClick={handleSubmit}>
              Subscribe to rate alerts
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  else return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item container xs={12} md={8}>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center' className={classes.image}>
            <img src='/new/check-success.svg' />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='h3'
            align='center'
            className={classes.labelTitle}>Success! Check your email</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='h3'
            align='center'
            className={classes.labelGray}>Thank you for subscribing to our currency exchange rates. Check your email to confirm that you’ve subscribed successfully!
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.secondBlock}>
          <Typography
            variant='h3'
            align='center'
            className={classes.labelGray}>Didn’t receive an email? <a className={classes.link} onClick={handleTryAgain}>Try Again</a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RateEmail;