import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  image: {
    width: 96,
    height: 96,
    marginRight: 24,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    //   marginLeft: '-80%',
      width: 72,
      height: 72,
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '18',
    lineHeight: '27px',
    color: '#31517A',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '16',
    lineHeight: '30px',
    color: '#626680',
    marginRight: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: theme.spacing(5),
    },
  },
  boxImg: {
    [theme.breakpoints.down('md')]: {
        width: theme.spacing(3),
    }
  }
}));

const IconGrid = ({ list }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (list && list.length > 0)
    return (
      <Grid
        display="flex"
        direction="row"
        alignItems="center"
        container
        justifyContent="center"
        spacing={4}
        className={classes.grid}
      >
        <Box display="flex" flexDirection={!isMobile ? 'row' : 'column'} item xs={12} sm={4} md={2}>
          {list.map((item) => (
            <>
              {/* <Box width={isMobile && '80px'}> */}
              <Box className={classes.boxImg}>
                <img src={item.image} alt={item.alt} className={classes.image} />
              </Box>
              <Box flexDirection="row">
                <Typography className={classes.title}>{item.title}</Typography>
                <Typography className={classes.text}>
                  <div>{item.text}</div>
                </Typography>
              </Box>
            </>
          ))}
        </Box>
      </Grid>
    );
  else return null;
};
IconGrid.propTypes = {
  list: PropTypes.array,
};

export default IconGrid;
