import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';

const useStyles = makeStyles(theme => ({
  listText : {
    color: '#6B708F',
    fontSize: '16px'
  },
  whiteBox: {
    backgroundColor:'white', 
    display: 'inline-block', 
    boxShadow: '1px 7px 13px #15419445', 
    justifyContent: 'center',
    marginTop: '50px',
    width: "100%",
    padding: '30px 25px 60px',
    marginBottom: '50px',
    borderRadius: '5px'
  },
  title: {
    color: "#31517A", 
    fontSize: '22px', 
    fontWeight: 'Medium',
    marginLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px !important',
    },
  },
  mobileBox: {
    marginTop: '90px',
    marginBottom: '30px',
  }, 
  listIcon: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-10px !important',
      marginRight: '-10px !important',
    },
  }
}))


const ProsCons = ({listItems, type}) => {
  const classes = useStyles();
  const items = parseArrFromString(listItems)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderListItem = (item) => {
    let { text } = item;
    return (
    <ListItem>
     <ListItemIcon className={classes.listIcon}><img src={type === 'pros' ? '/img/landing/svg/check_mark.svg': '/img/landing/svg/red_dash.svg'} alt="proscons icon"></img></ListItemIcon>
     <Typography className={classes.listText}>{text}</Typography>
    </ListItem>
   )
  }

  return (
    <div className={isMobile ? classes.mobileBox : classes.whiteBox}>
      <Typography className={classes.title}>{type === 'pros' ? 'Pros' : 'Cons'}</Typography>
      {items.map(renderListItem)}
    </div>
        
)
}

export default ProsCons
