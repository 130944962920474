import { IconButton } from '@mui/material';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { Divider } from '@mui/material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { SwapHoriz } from '@mui/icons-material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import CurrencyListInput from '../common/CurrencyListInput';
import { getTransferRateHistory, getExchangeCurrencyRateHistory } from '../../redux/rates/actions';
import RateEmail from './RateEmail';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isInteger } from '../../shared/math';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

const RatesGraphV2 = dynamic(() => import('./ratesComponents/RatesGraphV2'), { ssr: false });

const styles = theme => ({
  sectionTitle: {
    color: '#31517A',
    fontFamily: 'Inter',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
      lineHeight: '72px',
      marginBottom: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '45px',
      marginBottom: theme.spacing(3)
    }
  },
  labelBlue: {
    color: '#31517A',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    paddingRight: theme.spacing(2),
  },
  labelBlueMiddle: {
    fontFamily: 'Inter',
    color: '#31517A',
    fontWeight: 600,
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3)
    }
  },
  labelBlueLine: {
    fontFamily: 'Inter',
    color: '#31517A',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '36px'
    }
  },
  labelGray: {
    color: '#626680',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600
  },
  labelGrayLine: {
    fontFamily: 'Inter',
    color: '#626680',
    fontSize: '18px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    fontWeight: 600
  },
  currencyInput: {
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '250px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      minWidth: '290px',
      maxWidth: '350px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '350px',
    }
  },
  graphContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px',
    border: "1px solid #CACCD5",
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      background: 'transparent',
      padding: '0 9px',
      border: '0px'
    }

  },
  graphMobileContainer: {
    background: '#fff',
    borderRadius: '5px',
    border: "1px solid #CACCD5",
  },
  right: {
    color: '#626680',
    fontSize: '16px',
    lineHeight: '23.5px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1)
    }
  },
  swapButton: {
    color: '#1160F7',
    [theme.breakpoints.up('md')]: {
      margin: '0 12px !important'
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0
    }
  },
  divider: {
    width: '100%',
    backgroundColor: '#CACCD5',
    height: '0.8px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  interval: {
    marginLeft: '18px !important',
    color: '#626680',
    fontWeight: 500,
    borderRadius: '5px !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '9px !important',
      marginRight: '9px !important',
    }
  },
  rateEmail: {
    paddingTop: '60px'
  },
  selectedButton: {
    marginLeft: '18px !important',
    color: '#fff',
    fontWeight: 500,
    borderRadius: '5px !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '9px !important',
      marginRight: '9px !important',
    }
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      justifyContent: 'center'
    }
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline'
    },
  },
  box: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      justifyContent: 'left',
    },
  },
  mobileXAxes: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  date: {
    color: '#626680',
    fontSize: '14px',
    fontWeight: 400
  }
});

const DAY = '1D', WEEK = '1W', MONTH = '1M';
const chartRangeOptions = [DAY, WEEK, MONTH];
let mapRangeOptions = {
  '1D': {
    from: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "hour",
    unitStepSize: 1,
  },
  "1W": {
    from: dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "day",
    unitStepSize: 1
  },
  "1M": {
    from: dayjs().subtract(1, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "day",
    unitStepSize: 7
  },
  "3M": {
    from: dayjs().subtract(3, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "day",
    unitStepSize: 10
  },
  "6M": {
    from: dayjs().subtract(6, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "month",
    unitStepSize: 1
  },
  "1Y": {
    from: dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    unit: "month",
    unitStepSize: 2
  },
}

const canada = {
  id: 1,
  country_to: "Canada",
  currency_code: "CAD",
  currency_name: "Canadian Dollar",
  iso2: "CA",
  iso3: "CAN",
};

export const RatesGraph = ({ isCurrencyExchange, selected, reverse, subscribe }) => {
  const useStyles = makeStyles(styles);
  const [dateFilter, setDateFilter] = useState(MONTH);
  const [currencyExchange] = useState(isCurrencyExchange);
  const [configSubscribe] = useState(subscribe);
  const [source, setSource] = useState(isCurrencyExchange && reverse ? '236' : canada);
  const [target, setTarget] = useState(isCurrencyExchange && !reverse ? '236' : selected ? selected: canada );
  const [ratesHistory, setRatesHistory] = useState(null);
  // const [range, setRange] = useState(null);
  // const [mobileXAxes, setMobileXAxes] = useState(null);
  const [last, setLast] = useState(null);
  // const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { countries } = useSelector(state => state.system);

  useEffect(() => {
     if(selected && countries && countries.length > 0) {
      const selectedCountry = countries.find(country => ((country.country_id === parseInt(selected)) || (country.rate_id === parseInt(selected))));
      setTarget(selectedCountry)
     }
  }, [selected, countries?.length]);


  const getRatesHistory = async () => {
    let to = dayjs().format('YYYY-MM-DD H:mm:ss');
    let options = mapRangeOptions[dateFilter];
    let rateId = target && +(target.rate_id ? target.rate_id : target.id);
    if (isInteger(rateId)) {
      const { success, data } = await getTransferRateHistory(
        {
          rateId,
          dateFrom: options.from,
          dateTo: to,
        });
      if (success) {
        if(data && data.transferRateHistory && data.transferRateHistory.length) {
          const history = [...data.transferRateHistory];
          setRatesHistory(history.sort((a, b) => new Date(a?.datetime_added) - new Date(b?.datetime_added)));
          } else {
            setRatesHistory(null)
          }
        setLast(data.transferRateHistory[data.transferRateHistory.length - 1]);
        // setRange({ to, ...options });
      }
      // setLoading(false);
    }
  }

  const getExchangeRatesHistory = async () => {
    let to = dayjs().format('YYYY-MM-DD H:mm:ss');
    let options = mapRangeOptions[dateFilter];
    if (target && target.currency_code) {
      const { data } = await getExchangeCurrencyRateHistory(
        {
          currency: target.currency_code,
          dateFrom: options.from,
          dateTo: to,
        });
      if (data) {
        setRatesHistory(data);
        setLast(data[data.length - 1]);
        // setRange({ to, ...options });
      }
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (currencyExchange) {
      getExchangeRatesHistory();
      return
    }
    getRatesHistory();
  }, [target, dateFilter,source]);

  useEffect(() => {
    const xAxes = [];
    if (ratesHistory && ratesHistory.length > 0) {
      xAxes.push(ratesHistory[0].date_added || ratesHistory[0].datetime_added);

      if (ratesHistory.length > 8) {
        const interval = Math.ceil(ratesHistory.length / 4);
        xAxes.push(ratesHistory[interval].date_added || ratesHistory[interval].datetime_added);
        xAxes.push(ratesHistory[interval * 2].date_added || ratesHistory[interval * 2].datetime_added);
      }

      xAxes.push(ratesHistory[ratesHistory.length - 1].date_added || ratesHistory[ratesHistory.length - 1].datetime_added);
      // setMobileXAxes(xAxes);
    }
  }, [ratesHistory]);

  const handleSetSource = (country) => {
    setSource(country);
  };

  const handleSetTarget = (country) => {
    if(country)setTarget(country);
  };

  const handleSwapClick = event => {
    event.preventDefault();
    const keepSource = source;
    setSource(target);
    setTarget(keepSource);
  }

  const onClickRangeButton = range => {
    setDateFilter(range);
  }

  return (
    <Grid item container xs={12} justifyContent='center'>
      <Typography variant='h2' align='center' className={classes.sectionTitle}>Check Exchange Rates</Typography>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.graphContainer}>

        <Grid container item xs={12} className={classes.grid}>
          <Grid item>
            <Box className={classes.box}>
              <Typography variant='h3' className={classes.labelBlue}>{currencyExchange ? 'Convert from:' : 'Exchange from:'}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <div className={classes.currencyInput}>
              <CurrencyListInput
                isCurrencyExchange={currencyExchange}
                source={true}
                value={source}
                onChange={(selected) => handleSetSource(selected)} />
            </div>
          </Grid>
          <Grid item>
            <Box className={classes.box} display='flex' alignItems='center' justifyContent='center'>
              {currencyExchange &&
                <IconButton className={classes.swapButton} onClick={handleSwapClick} aria-label="Swap currencies">
                  <SwapHoriz />
                </IconButton>
              }
              {!currencyExchange &&
                <Typography variant='h3' className={classes.labelBlueMiddle}>to</Typography>
              }
            </Box>
          </Grid>
          <Grid item>
            <div className={classes.currencyInput}>
              <CurrencyListInput
                isCurrencyExchange={currencyExchange}
                source={false}
                value={target}
                onChange={(selected) => handleSetTarget(selected)} />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box my={4}>
            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />
          </Box>
        </Grid>

        {source && target && typeof target === 'object' &&
          <>
            <Grid item container xs={12}>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <Typography variant='h3' className={classes.labelGrayLine}>
                    {`1 ${source.currency_name} equals`}
                  </Typography>
                </Box>
                {last && <Typography variant='h3' className={classes.labelBlueLine}>
                  {`${last.rate} ${target.currency_name}`}
                </Typography>}
              </Grid>
              <Grid item xs={12} md={6}>
                {last && <Typography variant='h3' className={classes.right}>
                  {last.datetime_added || last.date_added}
                </Typography>}
                <div className={classes.buttonGroup}>
                  <ButtonGroup size="small">
                    {
                      chartRangeOptions.map((item, index) => (
                        <Button
                          key={index}
                          onClick={() => onClickRangeButton(item)}
                          className={classes.interval}
                          variant='text'
                          aria-label={item}
                          {...((item == dateFilter) && { variant: "contained", color: "primary", className: classes.selectedButton })}
                        >
                          {item}
                        </Button>
                      ))
                    }
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Box pt={isMobile ? 3 : 4}>
                  {ratesHistory && ratesHistory.length ? <RatesGraphV2 ratesHistory={ratesHistory} currencyCode={target.currency_code} /> : null}
                </Box>
              </Grid>
            </Grid>
          </>}

      </Grid>

      {configSubscribe && target && (
        <div className={classes.rateEmail}>
          <RateEmail selected={target} reverse={reverse} current={last ? last.rate : null} isCurrencyExchange={isCurrencyExchange} />
        </div>
      )}
    </Grid>
  );
}

//export default publicPage(RatesGraph, { shouldIgnoreAuth : true, skipLoading: true });
export default RatesGraph;