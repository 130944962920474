import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableFooter, TablePagination, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { getCompetitionRanking } from '../../../redux/rewards/actions';

const REFERRAL_NEW_SYSTEM_DATE = '2023-11-16';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '100%',
  },
  container: {
      background: '#fff',
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: '1px solid #CACCD5'
  },
  padding: {
    marginTop: theme.spacing(5)
  },
  head: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    padding: theme.spacing(3, 2),
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#31517A',
    borderBottom: '1px solid #CACCD5',
    '&:not(:last-child)': {
        borderRight: '1px solid #CACCD5',
    }
  },
  body: {
    border: 0,
    color: '#626680',
    fontSize: '16px',
    lineHeight: '23.5px',
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child)': {
        borderRight: '1px solid #CACCD5',
      },
    }
  },
  oddRow: {
    background: '#F9F8F7',
    borderTop: '1px solid #CACCD5 !important',
    borderStyle: 'none'
  },
  row: {
    borderStyle: 'none',
    '&:not(:first-child)': {
      borderTop: '1px solid #CACCD5 !important',
    }
  },
  pagination: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    minHeight: 72,
    background: '#fff',
    borderBottom: '1px solid #CACCD5',
    borderRight: '1px solid #CACCD5',
    borderLeft: '1px solid #CACCD5',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingRight: theme.spacing(2),
    '&:last-child': {
        padding: theme.spacing(1)
    }
  },
  actions: {
    color: '#31517A'
  },
  paginationText: {
    color: '#6B708F',
    fontSize: '14px',
    lineHeight: '20px'
  },
  select: {
    border: '1px solid #CACCD5',
    borderRadius: 3
  },
  selectIcon: {
      marginTop: '-3px'
  },
  noData: {
    width: '100%',
    background: '#fff',
    fontSize: '16px',
    lineHeight: '23.5px',
    color: '#626680'
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(3)
  }
}));

const fields = [
  {
    name: "Position",
    attribute: "position",
    align: "center",
    width: '25%'
  },{
    name: "Name",
    attribute: "cus_firstname",
    width: '50%'
  },{
    name: "Referrals",
    attribute: "total_rewards",
    align: "center",
    width: '25%'
  }
];

const MainTable = ({ competition, paddingTop, noDataLabel, overrideLabel, competitionDate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [page, setPage] = useState(0);
  const [dataPaginated, setDataPaginated] = useState();

  useEffect(() => {
    if (competition && !overrideLabel) loadRewardsCompetition();
  }, []);

  useEffect(() => {
    setDataPaginated(leaderboardData && leaderboardData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [page, rowsPerPage, leaderboardData]);

  useEffect(() => {
    setPage(0)
  }, [rowsPerPage]);

  const loadRewardsCompetition = async () => {
    const { success, data } = await getCompetitionRanking({ start_date: competitionDate ? competitionDate : REFERRAL_NEW_SYSTEM_DATE });
    if (success && data) setLeaderboardData(data.getReferralCompetition && data.getReferralCompetition.length ? data.getReferralCompetition : null);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  }

  return (
      <>
        <TableContainer className={paddingTop ? [classes.container, classes.padding] : classes.container}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
            <TableRow className={classes.row}>
              {fields && fields.length && fields.map((field, index) => (
                  <TableCell style={{ width: field.width }}key={index} classes={{ head: classes.head }} align={field.align}>{field.name}</TableCell>
                ))
              }
            </TableRow>
            </TableHead>
            <TableBody>
            {dataPaginated && dataPaginated.length && dataPaginated.map((row, index) => (
                <TableRow key={index} classes={{ root: index%2 !== 0 ? classes.oddRow : classes.row }}>
                  {fields && fields.length && fields.map((field, index) => (
                    <TableCell key={index} classes={{ body: classes.body }} align={field.align}>{field.attribute === 'cus_firstname' ? row[field.attribute] || 'Customer' : row[field.attribute]}</TableCell>
                    ))
                  }
                </TableRow>
            ))}
            </TableBody>
        </Table>
        {overrideLabel && <TableFooter className={classes.footer}>
          <Typography align='center' className={classes.noData}>{overrideLabel}</Typography>
        </TableFooter>}
        {!overrideLabel && !leaderboardData && <TableFooter className={classes.footer}>
          <Typography align='center' className={classes.noData}>{noDataLabel || 'No data available.'}</Typography>
        </TableFooter>}
        </TableContainer>
        {leaderboardData && <TablePagination
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage={isMobile ? null : 'Entries per page'}
            rowsPerPageOptions={[10, 25, 100]}
            count={leaderboardData && leaderboardData.length}
            page={page}
            rowsPerPage={rowsPerPage}
            component="div"
            classes={{ 
              root: classes.pagination,
              actions: classes.actions, 
              selectLabel: classes.paginationText, 
              displayedRows: classes.paginationText, 
              select: classes.select,
              selectIcon: classes.selectIcon
            }}
        />}
    </>
  );
}

//export default publicPage(MainTable, { shouldIgnoreAuth: true });
export default MainTable;
