import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, InputLabel, InputBase, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import Markdown from 'markdown-to-jsx';
import { subscribeSignUpPromo } from '../../redux/auth/actions';
import { getClientCookieInfo, setClientCookieInfo } from '../../shared/cookie-handler';
import { useRouter } from 'next/router';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#1160F7',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 2)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2)
        }
    },
    dialogTitleSuccess: {
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 2, 0)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2, 0)
        }
    },
    paper: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '500px',
        },
        background: 'transparent'
    },
    title: {
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            fontSize: '36px',
            lineHeight: '54px',
            paddingTop: theme.spacing(6),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '39px',
            paddingTop: theme.spacing(5),
        }
    },
    labelTitle: {
        color: '#31517A',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 600,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            fontSize: '26px',
            lineHeight: '39px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
            lineHeight: '32px',
        }
    },
    subtitle: {
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 400,
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
            paddingBottom: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
            paddingBottom: theme.spacing(2),
        }
    },
    labelSubtitle: {
        color: '#626680',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 400,
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
    emailLabel: {
        color: '#31517A',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '26px',
    },
    dialogContent: {
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(6, 3, 4)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6, 2, 4)
        }
    },
    dialogContentSuccess: {
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 4, 6)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2, 4)
        }
    },
    inputEmail: {
        background: '#fff',
        height: '60px',
        width: '100%',
        border: '1px solid #CACCD5',
        borderRadius: '4px',
        padding: '0 16px',
        margin: theme.spacing(1, 0, 0)
    },
    closeIcon: {
        color: '#fff',
        float: 'right',
        display: 'flex'
    },
    closeIconSuccess: {
        color: '#1160F7',
        float: 'right',
        display: 'flex'
    },
    dialogActions: {
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3, 6)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2, 6)
        }
    },
    button: {
        height: '56px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 500,
        padding: '15px 0'
    },
    buttonDisabled: {
        backgroundColor: '#CACCD5 !important',
        color: '#fff !important'
    },
}));

const PromoPopUp = ({ title, subtitle, promoCode, timeout }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState('');
    const router = useRouter();
    if (typeof timeout === 'string') timeout = Number(timeout)
    else timeout = 0;

    useEffect(() => {
        const timer = setTimeout(() => {
            const subscribed = getClientCookieInfo('promo_sign_up') && getClientCookieInfo('promo_sign_up') === promoCode;
            if (!subscribed) setOpenDialog(true);
        }, timeout)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const handleClose = (event) => {
        event.preventDefault();
        setOpenDialog(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const page = router.asPath;
        setSubmitted(true);
        setClientCookieInfo('promo_sign_up', promoCode, 365);
        await subscribeSignUpPromo({ email, page });
    }

    return (
        <Dialog open={openDialog} classes={{ paper: classes.paper }} onClose={handleClose}>
            {!submitted && <>
                <DialogTitle className={classes.dialogTitle}>
                    <IconButton size='small' className={classes.closeIcon} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography compoennt='div' className={classes.title}>{title}</Typography>
                    <Typography compoennt='div' className={classes.subtitle}><Markdown children={subtitle} /></Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <InputLabel htmlFor="email">
                        <Typography compoennt='div' variant='h3' className={classes.emailLabel}>Enter your email address</Typography>
                    </InputLabel>
                    <InputBase
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        id="email"
                        name='email'
                        autoComplete='false'
                        label="Email address"
                        variant="outlined"
                        className={classes.inputEmail}
                        fullWidth />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={handleSubmit}
                        disabled={!email || !email.includes('@') || !email.includes('.com')}
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.button, disabled: classes.buttonDisabled }}>
                        {`Continue`}
                    </Button>
                </DialogActions>
            </>
            }
            {submitted && <>
                <DialogTitle className={classes.dialogTitleSuccess}>
                    <IconButton size='small' className={classes.closeIconSuccess} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContentSuccess}>
                    <Box display='flex' justifyContent='center' className={classes.image}>
                        <img src='/new/check-success.svg' />
                    </Box>
                    <Typography
                        variant='h3'
                        align='center'
                        className={classes.labelTitle}>Success! Check your email</Typography>
                    <Typography
                        variant='h3'
                        align='center'
                        className={classes.labelSubtitle}>
                            {`We’ve sent you an email on how to redeem this offer.`}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.button }}>
                        {`Got it!`}
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    )
}

//export default publicPage(PromoPopUp, { skipLoading: true });
export default PromoPopUp;
