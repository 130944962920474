import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { classStyles } from './SearchInputStyles';
import CloseIcon from '../../../../public/icons/other/closeIcon.svg';
import SearchIcon  from '../../../../public/icons/other/Search.svg'

const useStyles = makeStyles(() => ({
  icon: {
    color: '#1160F7',
    margin: '5px',
    marginLeft:'12px'
  },
  iconSecondary: {
    color: '#626680',
    margin: '5px',
    marginRight:'12px'
  },
  outlined: {
    border: '1px solid #CACCD5',
    width: '100%',
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    height: 48,
    borderRadius: '5px'
  },
  searchBox : {
    width:'100%'
  }
}))

const SearchInput = ({
  // onClickSearchHandler,
  onSearchInputChange,
  searchValue,
  InputProps,
  onMountFocus,
  fullWidth,
  outlined,
  variant,
  placeholder,
  waitInterval,
  onClose,
  ...props
}) => {
  const { root, formControl, form } = classStyles();
  const classes = useStyles();
  const router = useRouter();

  const [value, setValue] = useState(searchValue);
  const inputRef = React.createRef();
  const { t } = useTranslation('common');
  let firstUpdate = useRef(true);

  const WAIT_INTERVAL = waitInterval || 1000;

  React.useEffect(() => {
    if (onMountFocus && inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timerID = setTimeout(() => {
      onSearchInputChange(value);
    }, WAIT_INTERVAL)
    return () => clearTimeout(timerID);
  }, [value])

  const searchInputChangeHandler = async () => {
    if (inputRef.current && inputRef.current.value !== null) {
      setValue(inputRef.current.value)
    }
  };
  // useEffect(() => {
  //   const handlePopState = () => {
  //     router.push({
  //       pathname: '/help',
  //     })
  //   };
  //   window.addEventListener('popstate', handlePopState);
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value !== '' && router.asPath.split('/')[1] === 'help') {
      router.push({
        pathname: '/help/search',
        query: { value }
      })
    }
  }

  const clearInputHandler = (event) => {
    setValue('')
    onClose && onClose(event)
  }

  return (
    <Box {...props} display="flex"  flexDirection="row" style={fullWidth ? { width: '100% !important'} : null}>
      <Paper className={outlined ? classes.outlined : root}>
        <form onSubmit={handleSubmit} className={form}>
          <FormControl className={formControl}>
            <InputBase
              id="search-input"
              inputRef={inputRef}
              className={classes.searchBox}
              placeholder={placeholder || t('search')}
              startAdornment={<img src={SearchIcon} alt='searchicon' className={variant === 'secondary' ? classes.iconSecondary : classes.icon} />}
              value={value}
              onChange={searchInputChangeHandler}
              endAdornment={onClose && value && <img src={CloseIcon} alt='closeIcon' onClick={clearInputHandler} className={classes.icon}/>}
              {...InputProps}
              autoComplete='off'
            />
          </FormControl>
        </form>
      </Paper>
    </Box>
   
  );
};

SearchInput.propTypes = {
  // onClickSearchHandler: func,
  onSearchInputChange: func.isRequired,
  searchValue: string,
  onMountFocus: bool,
  fullWidth: bool,
  onClose: func
};

SearchInput.defaultProps = {
  searchValue: '',
};

export default SearchInput;
