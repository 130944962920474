import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import { body1, container, mediumTitle, subTitle } from '../globalStyles';

const useStyles = makeStyles(theme => ({
  container,
  mediumTitle: {
    ...mediumTitle,
    marginBottom: '0 !important'
  },
  subTitle,
  body1,
  root: {
    display: 'flex',
    paddingTop: '100px',
    paddingBottom: '100px',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  image: {
    maxWidth: '55px',
    paddingBottom: '16px',
  },
  sectionWrapper: {
    display: 'flex',
    marginTop: '-106px',
    overflow: 'hidden',
    paddingTop: '200px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  stepsTitle: {
    padding: '20px',
  },
  howToSubheading: {
    fontSize: '18px',
    fontWeight: '500',
  },
  content: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  featuresBox: {
    marginBottom: "30px",
    transition: "ease-in-out 0.3s",
    borderRadius: "10px",
    "&:hover,&:focus": {
        boxShadow: "12px 15px 20px 6px rgba(11,65,153,0.1)"
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: "0",
    },
},
textCenter: {
  textAlign: 'center',
},
textLeft: {
  textAlign: 'left',
},
  white: {
    color: '#FFFFFF !important'
  },
  grey: {
    color: '#31517A !important'
  },
  stepsContainer: {
     marginTop: '20px'
  },
  bgBlue: {
    background: "#1160F7"
  },
  bgWhite: {
    background: "#FFFFFF"
  },
  bgGrey: {
    background: "#F6F8FC"
  },
  dividerImage: {
    display: 'flex',
    position: 'inherit',
    margin: 'auto',
  },
  circularBlue: {
    background: '#1160F7',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    border: 'none',
    borderRadius: '50%',
    transition: "ease-in-out 0.3s",
    width: '30px',
    height: '30px',
    boxShadow: '2px 2px 8px 3px #0000001A',
    '&:hover': {
        background: '#1160F7',
        color: '#ffffff',
        boxShadow: '3px 8px 24px rgba(55,56,60,.35)',
        transform: 'translateY(-2px)',
    },
    cursor: 'pointer'
},
circularWhite: {
  background: '#ffffff',
  fontSize: '16px',
  fontWeight: '500',
  color: '#1160F7',
  border: 'none',
  borderRadius: '50%',
  transition: "ease-in-out 0.3s",
  width: '30px',
  height: '30px',
  boxShadow: '2px 2px 8px 3px #0000001A',
  '&:hover': {
      background: '#ffffff',
      color: '#1160F7',
      boxShadow: '3px 8px 24px rgba(55,56,60,.35)',
      transform: 'translateY(-2px)',
  },
  cursor: 'pointer'
},
codeBlue: {
  color: '#1160F7',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '30px'
},
codeGrey: {
  color: '#31517A',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '30px'
},
dash: {
  color: '#31517A',
  fontSize: '30px'
},
whiteBox: {
  backgroundColor:'#ffffff', 
  height:'105px', 
  width:'100%', 
  display: 'inline', 
  boxShadow: '2px 2px 8px 3px #0000001A', 
  justifyContent: 'center',
  marginTop: '50px',
  minWidth: '300px'
}, 
underline: {
  marginTop: '20px'
}, 
footer: {
  fontSize: '16px', 
  color: '#626680',
  marginTop: '40px'
},
buttonText: {
  fontSize: '16px',
  float:"left",
  marginTop:"4px", 
  textAlign:"left", 
  marginRight:"20%",
}
}));


const CodeDissector = ({
    title,
    text,
    stepItems,
    footer,
    hiddenUnderline
}) => {
    const classes = useStyles();
    const items = parseArrFromString(stepItems);
    const [highlight, setHighlight] = React.useState(0);

    const renderSectionItem = (item, index) =>  {
      let {text} = item;
      let number = index + 1 ;
      return (
        <Grid container spacing={2}>
            <Grid item xs={3} sm={4}>
              <button style={{float:"right", marginBottom:"15px"}} onClick={() => setHighlight(number)} className={(number == highlight) ? classes.circularBlue : classes.circularWhite}>{number}</button>
            </Grid>
            <Grid item xs={9} sm={8}>
              <Typography className={classnames(classes.buttonText, classes.grey)}> {text}</Typography>
            </Grid> 
        </Grid>
      )
    };

    const renderSectionCode = (item, index) => {
      let { code } = item;
      let number = index + 1 ;
      let dash = <span className={classes.dash}>&nbsp;-&nbsp;</span>
      code = <span onClick={() => setHighlight(number)} className={(number == highlight) ? classes.codeBlue : classes.codeGrey}>{code}</span>
      return (
      <Grid item>
        <Typography>
          {code}{number!=items.length && dash}
        </Typography> 
      </Grid>
      )
    }

    return (
    <section className={classnames(classes.root, classes.textCenter, classes.bgGrey)}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.stepsTitle}>
          <Typography variant="h2" className={classnames(classes.mediumTitle, classes.grey)}>
            {title}
          </Typography>
          {!hiddenUnderline && <CustomUnderlineBigHeading className={classes.underline} color='#1160F7' />}
        </Grid>
        {text && <Grid item xs={12}>
          <Typography variant="h5" className={classnames(classes.content, classes.grey)}>
            {text}
          </Typography>
        </Grid>}
        {items && items.length &&
          <Grid container xs={12} className={classes.stepsContainer} justifyContent='center' alignItems='center'>
            {items.map(renderSectionItem)}
            <Grid container justify = 'center' xs={12} md={6} className={classes.whiteBox}>
                <Grid container justify = 'center' alignContent='flex-start' style={{marginTop: "30px"}} >
                  {items.map(renderSectionCode)}
                </Grid>
            </Grid>
          </Grid>}
        {footer && <Grid item xs={12}>
          <Typography variant="h5" className={classnames(classes.footer, classes.grey)}>
            {footer}
          </Typography>
        </Grid>}  
      </Grid>
    </section>
    )
}

export default CodeDissector;