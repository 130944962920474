import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = makeStyles(() => ({
    bigUnderline: {
        marginBottom: '30px',
        "@media (max-width: 959px)": {
            marginBottom: '20px',
        },
    },
    one: {
       
    },
}))

function CustomUnderlineBigHeading({ color, type, ...rest }) {
    const classes = styles()
    return (
        <div {...rest}>
            <svg xmlns="https://www.w3.org/2000/svg" width="164" height="4" viewBox="0 0 164 4" className={classes[type] + " " + classes.bigUnderline}>
                <g id="Group_38005" data-name="Group 38005" transform="translate(-471.806 -489.581)">
                    <path id="Path_39600" data-name="Path 39600" d="M-4257.2-1075.419h130.852" transform="translate(4731.001 1567)" fill="none" stroke={[color]} strokeLinecap="round" strokeWidth="4" />
                    <path id="Path_39601" data-name="Path 39601" d="M-4257.195-1075.419h13.025" transform="translate(4877.976 1567)" fill="none" stroke={[color]} strokeLinecap="round" strokeWidth="4" />
                </g>
            </svg>
        </div>
    )
}
CustomUnderlineBigHeading.defaultProps={color: "#1160F7",}

export default CustomUnderlineBigHeading;