import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { interpolate } from '../../../../../shared/utility';
import { bigTitle, container, whiteColor } from '../../../globalStyles';
import CustomUnderlineBigHeading from '../../CustomUnderlinesWebsite/CustomUnderlineBigHeading';

const styles = theme => ({
  container,
  bigTitle,
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: whiteColor,
    paddingTop: '250px',
    marginTop: '-200px',
    clipPath: 'polygon(0 3%, 100% 0%, 100% 100%, 0% 100%)',
    zIndex: '0',
    '@media (min-width: 1450px)': {
      marginTop: '-180px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '230px',
      paddingBottom: '60px',
      clipPath: 'polygon(0 1%, 100% 0%, 100% 100%, 0% 100%)'
    }
  },
  centerredUnderline: {
    textAlign: 'center',
    lineHeight: '1.4',
    '@media (min-width: 959px)': {
      maxWidth: '86%'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5)
  },
  image: {
    height: 55
  },
  featuresTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '500',
    color: '#31517A',
    margin: '30px 0 10px 0 !important'
  },
  featuresDescription: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#6B708F'
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180
  },
  featuresBox: {
    marginBottom: '50px',
    transition: 'ease-in-out 0.3s',
    borderRadius: '10px',
    padding: '50px 10px !important',
    '&:hover,&:focus': {
      boxShadow: '12px 15px 20px 6px rgba(11,65,153,0.1)'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
      padding: '40px 10px !important'
    }
  }
});

function FeaturesSection(props) {
  const { classes } = props;
  let { title, section_item, data } = props;
  title = interpolate(title, data);

  const renderSectionItem = (item) => {

    let {title, text, image} = item;
    text = interpolate(text, data);
    title = interpolate(title, data);
    let imageUrl = image && image.url || "";
    if(imageUrl.startsWith('/uploads')) imageUrl = process.env.STRAPI_URL + imageUrl;

    return (
      <Grid item xs={12} md={4} className={classes.featuresBox}>
        <div className={classes.item}>
          {imageUrl &&
            <img
              className={classes.image}
              src={imageUrl}
              alt="Features image"
            />  
          }
          <Typography variant="h6" className={classes.featuresTitle}>
            {title}
          </Typography>
          {text && <Typography variant="h5" className={classes.featuresDescription}>
            <Markdown
              children={text}
            />
          </Typography>}
        </div>
      </Grid>
    )
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} className={classes.centerredUnderline}>
            <Typography variant="h2" className={classes.bigTitle}>
              {title}
            </Typography>
            <CustomUnderlineBigHeading color={'#1160F7'} />
          </Grid>
          {section_item.map(renderSectionItem)}
        </Grid>
      </Container>
    </section>
  );
}

FeaturesSection.propTypes = {
  classes: PropTypes.object.isRequired
};

//export default publicPage(withStyles(styles)(FeaturesSection));
export default withStyles(styles)(FeaturesSection);
