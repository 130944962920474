import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const CANADA_CURRENCY_CODE = 'CAD';
const CANADA_CURRENCY_NAME = 'Canadian dollar';

const styles = () => ({
  root: {
    padding: "24px",
  },
  sendingValue: {
    color: "#2EBC31",
    fontSize: "16px",
    fontWeight: "medium",
    "&:hover": {
      color: "#008000",
      cursor: "pointer"
    },
  },
  sendingLink: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  receivingValue: {
    color: "#37517A",
    fontSize: "16px",
  },
  currencyNameRow: {
    background: "#37517A",
  },
  currencyName: {
    color: "#FFFFFF",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    margin: "10px 0"
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  evenRow: {
    background: "#EDF7FF"
  },
  table: {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.160784)"
  },
});

const sendingValues = [1, 5, 10, 20, 50, 100, 150, 200, 250, 500, 1000, 1200, 1500, 2000, 2500];

function ConversionTable({ currency_code, reverse, classes, countries }) {
  const [currencyCode] = useState(currency_code);
  const [rate, setRate] = useState(1);
  const [currencyName, setCurrencyName] = useState(null);
  const [countryName, setCountryName] = useState(null); // eslint-disable-line no-unused-vars
  const [tranferRates, setTransferRates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (countries && countries.length) setTransferRates(countries)

    setLoading(false);
  }, [countries]);

  const updateCurrencyInfo = (code) => {
    const selectedCountry = tranferRates.filter((country) => {
      if (country.currency_code === code) return true;
      return false;
    });
    if (selectedCountry && selectedCountry.length) {
      const { rate, country_to, currency_name } = selectedCountry[0];
      setRate(rate);
      setCurrencyName(currency_name)
      setCountryName(country_to)
    }
  }

  // Load rate and currency name info from API
  useEffect(() => {
    if (tranferRates && tranferRates.length && currencyCode) updateCurrencyInfo(currencyCode)
  }, [currencyCode, tranferRates])

  const sendingValueRows = sendingValues.map(value => ({
    sending: `${value} ${CANADA_CURRENCY_CODE}`,
    receiving: `${Number(rate * value).toFixed(2)} ${currencyCode}`
  }));
  const receivingValueRows = sendingValues.map(value => ({
    sending: `${value} ${currencyCode}`,
    receiving: `${Number(value / rate).toFixed(2)} ${CANADA_CURRENCY_CODE}`
  }));

  const Table = ({ rows = [], sending, receiving }) => {
    return (
      <TableContainer component={Paper} className={classes.table}>
        <MuiTable className={classes.table} aria-label="simple table">
          <TableHead style={{ background: "#37517A" }}>
            <TableRow className={classes.currencyNameRow}>
              <TableCell>
                <h2 className={classes.currencyName}>{sending} to {receiving} exchange rate</h2>
              </TableCell>
              <TableCell align="right">
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={`conversion-table-row-${index}`} className={index % 2 !== 0 ? classes.evenRow : null}>
                <TableCell>
                  <Link
                    className={classes.sendingLink}
                    style={{ pointer: 'cursor' }}
                    href={`/currency-converter/convert-${row.sending?.split(' ')[1]}-to-${row.receiving?.split(' ')[1]}?amount=${row.sending?.split(' ')[0]}`.toLowerCase()}
                  >
                    <span className={classes.sendingValue}>{row.sending}</span>
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.receivingValue}>
                    {row.receiving}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
  };

  if (loading) return <></>
  return (
    <>
      <section className={classes.root}>
        <Container
          className={classes.container}
        >
          <Grid
            container
            spacing={5}
            justifyContent="center"
            direction={reverse ? 'row-reverse' : 'row'}
          >
            <Grid
              item
              xs={12} sm={6}
            >
              <Table
                rows={sendingValueRows}
                sending={CANADA_CURRENCY_NAME}
                receiving={currencyName}
              />
            </Grid>
            <Grid
              item
              xs={12} sm={6}
            >
              <Table
                rows={receivingValueRows}
                sending={currencyName}
                receiving={CANADA_CURRENCY_NAME}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

ConversionTable.propTypes = {
  classes: PropTypes.object.isRequired
};

// const mapStateToProps = ({ system }) => ({
//   countries: system.countries,
// });

// const mapDispatchToProps = (dispatch) => ({
//   fetchCountries: () => dispatch(fetchCountriesRest()),
// });

//const PublicConversionTable = publicPage(withStyles(styles)(ConversionTable));
const ConversionTableWithStyles = withStyles(styles)(ConversionTable);

//export default publicPage(connect(mapStateToProps, mapDispatchToProps)(ConversionTableWithStyles), { skipLoading: true });
// export default connect(mapStateToProps, mapDispatchToProps)(ConversionTableWithStyles);

export default ConversionTableWithStyles;
