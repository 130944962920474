import react from 'react';
import Box from '../../components/box/Box';
import Typography from '../../components/typography/Typography';
import theme from '../../theme/Theme';
import Container from '../../components/container/Container';
import classes from './ContactSection.module.scss';
import Spacing from '../../styles/spacing/Spacing';
import Markdown from 'markdown-to-jsx';
import Image from '../../components/image/Image';
import ArrowRight from '../../icons/ArrowRight';
import email from '../../../../public/design-system/help/email.svg';
import chat from '../../../../public/design-system/help/chat.svg';
import phone from '../../../../public/design-system/help/phone.svg';

type FullWidthContactSectionProps = {
    tag?: string;
    tagVariant?: string;
    tagWeight?: string;
    tagColor?: string;
    title?: string | React.ReactNode;
    titleVariant?: string;
    titleWeight?: string;
    titleColor?: string;
    text?: string | React.ReactNode;
    textVariant?: string;
    textWeight?: string;
    textColor?: string;
    background?: string;
    isMobile?: boolean;
}

function openCrisp() {
    const crispChatbox = document.querySelector('#crisp-chatbox > div > a') as HTMLAnchorElement;
    crispChatbox && crispChatbox.click();
  }

const FullWidthContactSection: React.FC<FullWidthContactSectionProps> = ({ tag, tagVariant,tagColor,tagWeight, title, titleVariant,titleColor,titleWeight, text, textVariant,textColor,textWeight, background, isMobile }) => {

    return (
        <div style={{
            background: background || null
        }}
        >
            <Box
                flexDirection={'column'}
                className={classes['rb-root-full-width']}
            >
                 <Box display='flex' flexDirection={'column'}>
                    {(tag || title) && <Box display='flex' flexDirection={'column'} gap={8}>
                        {tag && <Typography
                            variant={tagVariant || 'textMd'}
                            weight={tagWeight || 'bold'}
                            color={tagColor || theme.palette.primary.blue}
                            align={'center'}
                        >
                            {tag}
                        </Typography>}
                        {title && <Typography
                            variant={(titleVariant || 'displayMd')}
                            weight={titleWeight || 'bold'}
                            color={titleColor || theme.palette.primary.navy}
                            align={'center'}
                        >
                            {title}
                        </Typography>}
                    </Box>}
                    {(tag || title)&& text && <Spacing variant='betweenHeaderToSubheader' />}
                    {!text && <Spacing variant='headerToContent' />}
                    {text && <Box display='flex' justifyContent={ 'center'}>
                        <Typography
                            paragraph={true}
                            variant={textVariant || 'textMd'}
                            weight={textWeight || 'regular'}
                            color={textColor || theme.palette.input.body}
                            align={ 'center'}
                            style={{width : '900px'}}
                        >
                            {typeof text === 'string' ? <Markdown children={text} /> : text}
                        </Typography>
                    </Box>}
                    {text && <Spacing variant='headerToContent' />}
                    <Box display='flex' alignItems='center' justifyContent='center'>
                    <div className={classes['rb-contact-us-cards']}>
          <div className={classes['rb-contact-us-card']}>
            <div className={classes['rb-contact-us-card-img']}>
              <Image
                url={email}
                alt={'email'}
                width={48}
                height={48}
              />
            </div>
            <div className={classes['rb-contact-us-card-body']}>
              <Typography
                align="center"
                variant="textXl"
                weight="semibold"
                color={theme.palette.primary.navy}
              >
                {"Email"}
              </Typography>
              <div>
                <Typography align="center" variant="textMd">
                  {'Our friendly team is here to help.'}
                </Typography>
                <Typography align="center" variant="textMd">
                  {'Expected response time: '}
                  <span className={classes['rb-bold']}>'24 hours'</span>
                </Typography>
              </div>
            </div>
            <a href="mailto:customercare@remitbee.com" className={classes['rb-link']}>
              customercare@remitbee.com
            </a>
          </div>
          <div className={classes['rb-contact-us-card']}>
            <div className={classes['rb-contact-us-card-img']}>
              <Image
                url={chat}
                alt={'chat'}
                width={48}
                height={48}
              />
            </div>
            <div className={classes['rb-contact-us-card-body']}>
              <Typography
                align="center"
                variant="textXl"
                weight="semibold"
                color={theme.palette.primary.navy}
              >
                {'Chat with us'}
              </Typography>
              <div>
              <Typography align="center" variant="textMd">
                  {'Get instant help through our chat.'}
                </Typography>
              <Typography align="center" variant="textMd">
                {'Expected response time: '}
                <span className={classes['rb-bold']}>{`5-10 minutes`}</span>
              </Typography>
              </div>
            </div>
            <span className={classes['rb-link']} onClick={() => openCrisp()}>
              Start a chat
            </span>
          </div>
          <div className={classes['rb-contact-us-card']}>
            <div className={classes['rb-contact-us-card-img']}>
              <Image
                url={phone}
                alt={'phone'}
                width={48}
                height={48}
              />
            </div>
            <div className={classes['rb-contact-us-card-body']}>
              <Typography
                align="center"
                variant="textXl"
                weight="semibold"
                color={theme.palette.primary.navy}
              >
                {'Phone'}
              </Typography>
              <div>
                <Typography align="center" variant="textMd">
                  {'Here for you 24/7.'}
                </Typography>
                <Typography align="center" variant="textMd">
                  {'Expected response time:'}
                  <span className={classes['rb-bold']}>'5-10 minutes'</span>
                </Typography>
              </div>
            </div>
            <a href="tel:+16476892323" className={classes['rb-link']}>
              1-647-689-2323
            </a>
          </div>
        </div>
        </Box>
                </Box>
            </Box>
        </div>
    );
}

export default FullWidthContactSection;