import { ClickAwayListener, Fade, FormControl, InputLabel, Popper } from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import FlagIcon from '../../../common/FlagIcon';
import CountrySelectionDropdown from '../../../common/countrySelectionDropbox/CountrySelectionDropdown';
import NumberFormat from '../../../common/NumberFormat';


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '45px',
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #B9B9B9',
    borderRadius: '5px',
    boxShadow: 'none',
  },
  formControl: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    flex: 1,
    fontSize: '22px',
    color: theme.palette.primary.dark,
    '& input': {
      marginTop: '5px',
    },
  },
  inputLabel: {
    color: theme.palette.primary.dark,
    paddingLeft: '4px',
    paddingBottom: '10px',
    fontSize: '13px',
    fontWeight: '500',
  },
  inputLabelShrink: {
    fontSize: '15px',
    marginTop: '6px',
  },
  iconButton: {
    maxHeight: '45px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '15px',
    paddingRight: '15px',
    minWidth: '120px',
    alignContent: 'right',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconContentStyle: {
    marginRight: '0.4em',
  },
  labelContentStyle: {
    marginTop: '-0.45em',
    marginRight: '0.6em',
  },
}));

function HorizontalCurrencyInput({
  countriesList,
  defaultCountryCurrency,
  defaultCountryCode,
  selectable,
  selected,
  onSelectChange,
  editable,
  value,
  onValueChange,
  label,
  eraseOnFocus,
  onBlur,
}) {
  const classes = useStyles();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [touched, setTouched] = useState(false);

  const handleMenuItemClick = (countryId) => {
    onSelectChange(countryId);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const handleChange = (changedValue) => {
    if (!touched) setTouched(true);
    onValueChange(changedValue);
  };

  let countrySelected = null;
  if (selected) {
    countrySelected = countriesList.filter((country) => country.country_to_id == selected)[0];
  }

  return (
    <div>
      <InputLabel
        htmlFor="component-simple"
        className={classes.inputLabel}
        classes={{ shrink: classes.inputLabelShrink }}
      >
        {label}
      </InputLabel>
      <Paper className={classes.root}>
        <Button
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={selectable ? handleClick : null}
          disableRipple={true}
        >
          <FlagIcon
            code={(countrySelected || {}).iso2 || defaultCountryCode}
            className={classes.iconContentStyle}
          />

          {(countrySelected || {}).currency_code || defaultCountryCurrency}
          {selectable && <ExpandMoreIcon />}
        </Button>
        <FormControl className={classes.formControl}>
          <InputBase
            className={classes.input}
            value={value}
            readOnly={editable ? false : true}
            inputProps={{ decimalScale: 2, thousandSeparator: true }}
            inputComponent={NumberFormat}
            onFocus={() => !touched && eraseOnFocus && handleChange('')}
            onChange={(e) => (editable ? handleChange(e.target.value) : null)}
            onBlur={onBlur}
          ></InputBase>
        </FormControl>
      </Paper>
      {selectable && (
        <Popper
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
          }}
          disablePortal={true}
          placement="bottom-end"
          style={{ zIndex: 1300 }}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={200}>
                <Paper>
                  {countriesList && (
                    <CountrySelectionDropdown
                      onChange={({ country_id }) => handleMenuItemClick(country_id)}
                      isDropdown={false}
                      countriesList={countriesList}
                      searchTextOptions={[
                        'currency_code',
                        'currency_name',
                        'country_name',
                      ]}
                    />
                  )}
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </div>
  );
}

HorizontalCurrencyInput.propTypes = {
  onSelectHandler: func,
  selectable: bool,
  editable: bool,
  eraseOnFocus: bool,
};

HorizontalCurrencyInput.defaultProps = {
  countriesList: [],
  eraseOnFocus: false,
};
export default HorizontalCurrencyInput;
