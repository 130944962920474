import { makeStyles } from '@mui/styles';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Skeleton from '@mui/material/Skeleton';
import https from 'https';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { formatNumber, MathOperation, roundAmount } from '../../../../shared/math';
import Button from '../../../common/Button';
import HorizontalCurrencyInput from './HorizontalCurrencyInput';


const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '8px 10px 18px #6B6B6B29',
        background: '#fff',
        borderRadius: '10px',
        width: '100%',
        padding: '15px 20px 20px 20px',
        "@media (max-width: 959px)": {
            width: '100%',
            marginTop: '0'
        }
    },
    root: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        "@media (max-width: 959px)": {
            display: 'block',
            flexDirection: 'column',
            padding: '20px'
        },
    },
    iconContainer: {
        display: 'flex',
        flex: '0.8',
        alignSelf: 'flex-end',
        justifyContent: 'center',
        "@media (max-width: 959px)": {
            margin: '30px 0 10px 0'
        },
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '50px',
        "@media (max-width: 959px)": {
            transform: 'rotate(90deg)'
        },
    },
    rate: {
        position: 'absolute',
        marginTop: '-60px',
        marginLeft: '60px',
        textAlign: 'left',
        display: 'inline-block',
        fontSize: '14px',
        color: theme.palette.primary.dark,
    },
    rateAmount: {
        fontSize: '14px',
        color: theme.palette.secondary.main,
    },
    inputRight: {
        flex: '3',
    },
    inputLeft: {
        flex: '3',
    },
    buttonContainer: {
        width: '120px',
        marginLeft: '10px',
        "@media (max-width: 959px)": {
            marginTop: '40px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    sendBtn: {
        backgroundColor: '#2EBC31',
        color: 'white',
    },
}));

const fields = {
    SENDING: 'SENDING',
    RECEIVING: 'RECEIVING',
};

const fetchSystemCountries = async () => {
    // const url =  new URL(`${process.env.REACT_APP_API}/public-services/system-countries`);
    const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
    let stagingProps = {};
    if (isStagingEnv) {
        stagingProps = {
            agent: new https.Agent({
                rejectUnauthorized: false,
            }),
        };
    }

    let url = `${process.env.REACT_APP_API}/public-services/system-countries`;
    const config = {
        method: 'GET',

        ...stagingProps,
    };
    const res = await fetch(url, config);
    const data = await res.json();
    return data;
};

function HorizontalCurrencyConverter({
    value,
    onChangeTransferAmount,
    onChangeTransferRate,
    onChangeReceivingAmount,
    onChangeDestinationCountryCurrencyCode,
    receivingCountrySelectable,
    countryIdSelected,
    onChangeReceivingCountry,
    eraseOnFocus,
    hasInstantSpeed,
    onChangeRateId,
    roundingPattern,
    activeCountries
}) {
    const classes = useStyles();
    const [tranferRates, setTranferRates] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [currentRateText, setCurrentRateText] = useState('');
    const [sendingValue, setSendingValue] = useState('');
    const [receivingValue, setReceivingValue] = useState(0.0);
    const [selectedCountryId, setSelectedCountryId] = useState(countryIdSelected);
    const [lastFieldChanged, setLastFieldChanged] = useState(fields.SENDING);
    const { sendBtn } = useStyles;

    useEffect(() => {
        onSendingValueChange(value || '0.00');
        loadTransferRates();
        onChangeTransferRate(receivingValue / sendingValue);
    }, []);

    useEffect(() => {
        calculateConversion();
    }, [sendingValue, receivingValue, selectedCountryId, tranferRates, hasInstantSpeed]);

    const loadTransferRates = async () => {
        setLoading(true);
        let data = await fetchSystemCountries();
        setLoading(false);
        if (data) {
            if(activeCountries) {
                data = data.filter(country => activeCountries.includes(country.country_id))
            }
            setTranferRates(data)
            roundTransferRate(null, data);
        }
    };

    const calculateConversion = () => {
        if (tranferRates.length) {
            let selectedCountry = null;

            if (selectedCountryId) {
                selectedCountry = tranferRates.filter((rate) => rate.country_to_id == selectedCountryId)[0];
            } else selectedCountry = tranferRates[0];
            let rate = selectedCountry.rate;
            if (
                hasInstantSpeed &&
                selectedCountry.manual_instant_rate &&
                selectedCountry.manual_instant_rate.length > 0
            ) {
                rate = selectedCountry.manual_instant_rate;
            }
            const currencyCode = selectedCountry.currency_code;
            // let rateText = ` 1.00 CAD → ${rate} ${currencyCode}`;
            // setCurrentRateText(rateText);

            // check last field changed

            if (lastFieldChanged === fields.SENDING) {
                let converted = MathOperation.multiply(sendingValue, rate, 4) || 0;
                if (converted && roundingPattern) {
                    converted = roundAmount(converted, roundingPattern);
                }
                converted = formatNumber(converted, { useGrouping: true });
                setReceivingValue(converted || '0.00');
                onChangeReceivingAmount && onChangeReceivingAmount(converted);
            } else {
                let converted = MathOperation.divide(receivingValue, rate, 4) || 0;
                converted = formatNumber(converted, { useGrouping: true });
                setSendingValue(converted || '0.00');
                onChangeTransferAmount && onChangeTransferAmount(converted);
            }
            onChangeTransferRate && onChangeTransferRate(rate);
            onChangeRateId && onChangeRateId(selectedCountry.rate_id);
            onChangeReceivingCountry && onChangeReceivingCountry(selectedCountry.country_to_id);
            onChangeDestinationCountryCurrencyCode &&
                onChangeDestinationCountryCurrencyCode(currencyCode);
            if (!selectedCountryId) setSelectedCountryId(selectedCountry.country_to_id);
        }
    };

    const onSendingValueChange = (value) => {
        setLastFieldChanged(fields.SENDING);
        setSendingValue(value);
        onChangeTransferAmount && onChangeTransferAmount(value);
    };

    const onReceivingValueChange = (value) => {
        setLastFieldChanged(fields.RECEIVING);
        setReceivingValue(value);
        onChangeReceivingAmount && onChangeReceivingAmount(value);
    };

    const onChangeReceivingCountryId = (countryId) => {
        setSelectedCountryId(countryId);
        onChangeReceivingCountry && onChangeReceivingCountry(countryId);
    };

    const roundTransferRate = (e, newTransferRates) => {
        const rates = newTransferRates || tranferRates;
        if (roundingPattern && rates.length) {
            let selectedCountry = null;

            if (selectedCountryId) {
                selectedCountry = rates.filter((rate) => rate.country_to_id == selectedCountryId)[0];
            } else selectedCountry = rates[0];
            let rate = selectedCountry.rate;
            if (
                hasInstantSpeed &&
                selectedCountry.manual_instant_rate &&
                selectedCountry.manual_instant_rate.length > 0
            ) {
                rate = selectedCountry.manual_instant_rate;
            }
            let converted = MathOperation.divide(receivingValue, rate, 4) || 0;
            converted = formatNumber(converted, { useGrouping: true });
            setSendingValue(converted || '0.00');
            onChangeTransferAmount && onChangeTransferAmount(converted);
        }
    }

    const { t } = useTranslation('send-money');

    const sendButton = (
        <Button
            size="large"
            style={sendBtn}
            href="/send-money"
        >
            {t('transfer_details.send_now')}
        </Button>
    );

    return !loading ? (
        <div className={classes.card}>
            <div className={classes.root}>
                <div className={classes.inputLeft}>
                    <HorizontalCurrencyInput
                        label={t('you_send')}
                        value={sendingValue}
                        selectable={false}
                        editable={true}
                        onValueChange={onSendingValueChange}
                        defaultCountryCode={'CA'}
                        defaultCountryCurrency={'CAD'}
                        eraseOnFocus={eraseOnFocus}
                        onBlur={roundTransferRate}
                    />
                </div>
                <div
                    className={classes.iconContainer}
                >
                    <SyncAltIcon
                        className={classes.icon}
                    />
                </div>
                <div className={classes.inputRight}>
                    <HorizontalCurrencyInput
                        label={t('they_receive')}
                        value={receivingValue}
                        editable={true}
                        countriesList={tranferRates}
                        selectable={receivingCountrySelectable}
                        onValueChange={onReceivingValueChange}
                        selected={selectedCountryId}
                        onSelectChange={onChangeReceivingCountryId}
                        eraseOnFocus={eraseOnFocus}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    {sendButton}
                </div>
            </div>
        </ div>
    ) : (
            <div className={classes.root}>
                <Skeleton variant="rectangular" width="100%" height={40} style={{ marginBottom: 70 }} />
                <Skeleton variant="rectangular" width="100%" height={40} />
            </div>
        );
}

HorizontalCurrencyConverter.defaultProps = {
    value: '',
    receivingCountrySelectable: true,
};

export default HorizontalCurrencyConverter;
