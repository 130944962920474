import { apiSessionQuery } from '../../../settings/apollo/api/session/query';
import {
  calcMoneyTransferAmountOnlineV2Query
} from '../graphql.graphql';

export const calcMoneyTransferAmountOnlineSession = async ({ transfer_amount, special_rate_type,  business_unique_id, is_promo_rate, country_id, receiving_amount, is_instant_rate, is_spot_rate, currency_code, beneficiary_payment_info_id, is_special_rate, transaction_date, include_timeline }) =>
  await apiSessionQuery(calcMoneyTransferAmountOnlineV2Query, {
    transfer_amount,
    special_rate_type,
    business_unique_id, 
    is_promo_rate,
    country_id,
    receiving_amount,
    is_instant_rate,
    is_spot_rate,
    is_special_rate,
    currency_code,
    beneficiary_payment_info_id,
    transaction_date,
    include_timeline
  })