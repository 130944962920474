import React,{useState} from 'react';
import theme from '../../theme/Theme';
import Typography from '../../components/typography/Typography';
import Box from '../../components/box/Box';
import { parseArrFromString } from '../../../shared/utility';
import classes from './FaqSection.module.scss';
import Spacing from '../../styles/spacing/Spacing';
import Markdown from 'markdown-to-jsx';
import ArrowDown from '../../icons/ArrowDown';
import ArrowUp from '../../icons/ArrowUp';

type FaqSectionProps = {
    tag?: string;
    tagVariant?: string;
title?: string | React.ReactNode;
titleVariant?: string;
text?: string | React.ReactNode;
textVariant?: string;
faqList?: any;
}

const FaqSection: React.FC<FaqSectionProps> = ({ tag, tagVariant, title, titleVariant, text, textVariant, faqList }) => {
    const faq = faqList ? typeof faqList === 'string' ? parseArrFromString(faqList) : faqList : null;
    const [openFaq, setOpenFaq] = useState(null);
    return (
        <div style={{
            backgroundPosition: "center"
        }}>
            <Box
                flexDirection={'column'}
                className={classes['rb-root']}
            >
                 <Box display='flex' flexDirection={'column'}>
                    {(tag || title) && <Box display='flex' flexDirection={'column'} gap={8}>
                        {tag && <Typography
                            variant={tagVariant || 'textMd'}
                            weight={'bold'}
                            color={theme.palette.primary.blue}
                            align={ 'center'}
                        >
                            {tag}
                        </Typography>}
                        {title && <Typography
                            variant={(titleVariant || 'displayMd')}
                            weight={'bold'}
                            color={theme.palette.primary.navy}
                            align={'center'}
                        >
                            {title}
                        </Typography>}
                    </Box>}
                    {(tag || title)&& text && <Spacing variant='betweenHeaderToSubheader' />}
                    {!text && <Spacing variant='headerToContent' />}
                    {text && <Box display='flex' justifyContent={'center'}>
                        <Typography
                            paragraph={true}
                            variant={textVariant || 'textMd'}
                            weight={'regular'}
                            color={theme.palette.input.body}
                            align={'center'}
                        >
                            {typeof text === 'string' ? <Markdown children={text} /> : text}
                        </Typography>
                    </Box>}
                    {(text) && <Spacing variant='headerToContent' />}
                </Box>
                <Box className={classes['rb-faq-box']}>
                        {faq.map((item, index) => {
                            return (
                                <Box key={index} className={classes['rb-faq-item-box']} onClick={() => setOpenFaq(openFaq !== index ? index : null)}>
                                    <Box key={index} className={classes['rb-faq-question-box']}>
                                        <Typography variant='textXl' weight='medium' color={theme.palette.primary.navy} className={classes['rb-faq-question']}>
                                            {item.question}
                                        </Typography>
                                        {openFaq === index ? <ArrowUp width={24} height={24} color={theme.palette.primary.blue} />
                                            : <ArrowDown width={24} height={24} color={theme.palette.primary.blue} />}
                                    </Box>
                                    {openFaq === index && <Box className={classes['rb-faq-answers-box']}>
                                        <Typography variant='textMd' className={classes['rb-faq-answer']}>
                                            <Markdown>{item.answers}</Markdown>
                                        </Typography>
                                    </Box>}
                                </Box>
                            )
                        })}
                    </Box>
            </Box>
          </div>      
    );
};

export default FaqSection;