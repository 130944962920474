import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';

const useStyles = makeStyles(theme => ({
    container,
    mediumTitle,
    body1,
    bigTitle: {
        ...bigTitle,
        textAlign: 'center'
    },
    root: {
        paddingTop: '60px',
        paddingBottom: '60px',
        /* backgroundColor: '#1160F7' */
        /* [theme.breakpoints.down('sm')]: {
        }, */
    },
    textCenter: {
        textAlign: 'center',
    },
    title_h2: {
        fontSize: '30px !important'
    },
    widthset: {
        width: '70%',
        margin:'auto',
        paddingBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    text: {
        fontSize: '16px',
        opacity: '0.85',
        lineHeight: '1.6',
    },
    grey: {
        color: '#31517A !important'
    },
    white: {
        color: '#FFFFFF !important'
    },
}));

const TrustPilotWidget = ({ title, titleColor, bgColor }) => {
    const classes = useStyles();
    const themeColor = (titleColor == 'white') ? 'dark' : 'light';
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (

        <Container maxWidth={false} className={classnames(classes.root)} style={{ background: bgColor }}>
            <Grid item xs={12} className={classes.widthset}>
                <Typography variant="h2" className={classnames(classes.bigTitle, classes.title_h2, classes[(titleColor == 'white') ? 'white' : 'grey'])}>
                    {title}
                </Typography>
            </Grid>

            <div className="trustpilot-widget" data-locale="en-CA" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="56426bbe0000ff0005856187" data-style-height="240px" data-style-width="100%" data-theme={themeColor} data-stars="4,5">
                <a href="https://ca.trustpilot.com/review/remitbee.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
        </Container>

    );

}

TrustPilotWidget.proptypes = {
    title: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string
};

export default TrustPilotWidget;