import React, { useState, useEffect } from 'react';
import { Fade, Tooltip, Box, Button, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import Markdown from 'markdown-to-jsx';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 2)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2, 1)
        }
    },
    paper: {
        maxWidth: '500px',
        background: 'transparent',
        [theme.breakpoints.up('sm')]: {
            width: '500px'
        }
    },
    title: {
        color: '#1160F7',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            fontSize: '36px',
            lineHeight: '54px',
            paddingTop: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '39px',
            paddingTop: theme.spacing(2),
        }
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#31517A',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 400,
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: '26px',
            lineHeight: '39px',
            paddingBottom: theme.spacing(6),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
            paddingBottom: theme.spacing(3),
        }
    },
    emailLabel: {
        color: '#31517A',
        fontFamily: 'Poppins',
        fontWeight: 500,
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
        }
    },
    dialogContent: {
        background: '#fff',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0, 3, 6)
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2, 6)
        }
    },
    inputEmail: {
        background: '#fff',
        height: '60px',
        width: '100%',
        border: '1px solid #CACCD5',
        borderRadius: '4px',
        padding: '0 16px',
        margin: theme.spacing(1, 0, 0)
    },
    closeIcon: {
        color: '#1160F7',
        float: 'right',
        display: 'flex'
    },
    dialogActions: {
        display: 'flex',
        flexWrap: 'wrap',
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 4, 6)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2, 6)
        }
    },
    image: {
        [theme.breakpoints.up('sm')]: {
            maxHeight: '160px'
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '120px'
        }
    },
    button: {
        height: '56px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 500,
        padding: '15px 0'
    },
    buttonOutlined: {
        height: '56px',
        width: '100%',
        marginTop: theme.spacing(2),
        fontSize: '18px',
        fontWeight: 500,
        padding: '15px 0',
        color: '#1160F7',
        backgroundColor: '#fff',
        border: '1px solid #1160F7',
        borderRadius: '3px',
        transition: "ease-in-out 0.3s",
        '&:hover': {
            background: '#1160F7',
            color: '#ffffff',
        }
    },
    promoCode: {
        fontWeight: 700,
        margin: theme.spacing(0, 1)
    },
    tooltip: {
        margin: theme.spacing(0.5, 0)
    },
    copy: {
        cursor: 'pointer',
        display: 'inline',
        '&:active': {
            opacity: '0.5s'
        }
    }
}));

const PromoSignUp = ({ title, subtitle, timeout, promoCode }) => {
    const classes = useStyles();
    const router = useRouter();
    const [openDialog, setOpenDialog] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    if (typeof timeout === 'string') timeout = Number(timeout);
    else timeout = 0;

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenDialog(true);
        }, timeout)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        let timerId;
        if (openTooltip) {
            timerId = setTimeout(() => {
                setOpenTooltip(false);
            }, 2000);
        }
        return () => { if (timerId) clearTimeout(timerId); }
    }, [openTooltip])


    const handleClose = (event) => {
        event.preventDefault();
        setOpenDialog(false);
    }

    const handleCopy = (event) => {
        event.preventDefault();
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(promoCode);
            setOpenTooltip(true);
        }
    }

    const redirect = (event) => {
        event.preventDefault();
        router.push('/signup');
    }

    return (
        <Dialog open={openDialog} classes={{ paper: classes.paper }} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton size='small' className={classes.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Box display='flex' justifyContent='center'>
                    <img src='/new/img/pot-of-gold-coins.png' className={classes.image} alt="Pot of gold and coins" />
                </Box>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>
                    <Markdown children={subtitle} />
                    {promoCode && (
                        <Tooltip
                            arrow
                            classes={{ tooltipPlacementBottom: classes.tooltip }}
                            onClose={() => setOpenTooltip(false)}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={`Copied to clipboard`}
                            placement='bottom'>
                            <Box className={classes.copy} onClick={handleCopy}>
                                <span className={classes.promoCode}>{promoCode}</span>
                                <img src='/new/copy.svg' className={classes.image} alt="Copy to clipboard" />
                            </Box>
                        </Tooltip>
                    )}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={redirect}
                    classes={{ root: classes.button }}>
                    {`Join now!`}
                </Button>
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    classes={{ root: classes.buttonOutlined }}>
                    {`Not today`}
                </Button>
            </DialogContent>
        </Dialog >
    )
}

//export default publicPage(PromoSignUp, { skipLoading: true });
export default PromoSignUp;
