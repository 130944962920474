import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { container } from '../globalStyles';

const useStyles = makeStyles(theme =>({
    container,
    title : {
        fontSize: "20px", 
        fontWeight: "500",
        color: "#31517A", 
        marginBottom: '20px'
    },
    text : {
        fontSize: "16px", 
        fontWeight: "400",
        color: "#626680"
    },
    root: {
        width: "100%",
        background: props => (props.backgroundImg && `url('${props.backgroundImg}') center no-repeat `), 
        backgroundSize: 'cover !important',
        backgroundColor: 'white',
        paddingTop: '120px', 
        paddingBottom: '120px',
        [theme.breakpoints.down('sm')]: {
            background: props => (props.backgroundImg && '#f7f8fc')
        },
    }
}))

const TextWithBG = ({
    title, 
    text, 
    backgroundImg,
    reverse
}) => {
    const classes = useStyles({backgroundImg});
    return (
        <div className={classes.root}>
            <Grid container className={classes.container} justifyContent={ reverse ? "flex-end" : "flex-start"}>
                <Grid item xs={12} md={6} className={classes.textArea}>
                    <Typography variant="h2" marked="center" className={classes.title} component="h2" >
                        {title}
                    </Typography>
                    <Typography marked="center" className={classes.text}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default TextWithBG;