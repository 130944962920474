import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { bigTitle, body1, container, subTitle } from '../globalStyles';



const styles = theme => ({
  container,
  bigTitle,
  subTitle,
  body1,
  root: {
    display: 'flex',
    textAlign: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  image: {
    maxWidth: '300px',
    maxHeight: '350px',
  },
  howToSubheading: {
    fontSize: '24px',
    color: '#31517A',
    fontWeight: '500',
    margin: '0 auto 20px auto'
  },
  stepBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '40px !important',
    },
  },
  stepBox2: {
    marginTop: '200px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      paddingBottom: '40px !important',
    },
  },
  imageShadow5: {
    filter: 'drop-shadow(6px 10px 16px rgba(18,71,155,0.25))',
    marginBottom: '40px',
    right: '40px',
    position: 'relative',
  },
});

function HowToSendMoneySection(props) {
  const { classes, content } = props;
  return (
    // <section className={classes.root}>
    //   <Grid container spacing={3} className={classes.container}>
    //     <Grid item xs={12}>
    //       <Typography variant="h2" className={classes.bigTitle}>
    //         How to send money through Remitbee?
    //       </Typography>
    //       <CustomUnderlineBigHeading />
    //     </Grid>
    //     <Grid item xs={12} md={6} className={classes.stepBox}>
    //       <img
    //         src='/img/landing/how_to_send_step_1.svg'
    //         className={classes.image}
    //         alt="Sign up form image"
    //         loading="lazy"
    //       />
    //       <Typography variant="h3" className={classes.subTitle}>
    //         1. Create your free account
    //       </Typography>
    //       <Typography variant="h6" marked="center" className={classes.body1} >
    //           Create your online money transfer account and verify your identity to send money from Canada to family and friends around the world.     
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12} md={6} className={classes.stepBox2}>
    //       <img
    //         src='/img/landing/how_to_send_step_2.svg'
    //         className={classes.image}
    //         alt="Verification form image"
    //         loading="lazy"
    //       />
    //       <Typography variant="h3" className={classes.subTitle}>
    //         2. Verify your identity
    //       </Typography>
    //       <Typography variant="h6" marked="center" className={classes.body1} >
    //         Complete one-time identity verification required by 
    //         government and send money without problems.           
    //         </Typography>
    //     </Grid>
    //     <Grid item xs={12} md={6} className={classes.stepBox}>
    //       <img
    //         src='/img/landing/how_to_send_step_3.svg'
    //         className={classes.image}
    //         alt="Add new recipient form image"
    //         loading="lazy"
    //       />
    //       <Typography variant="h3" className={classes.subTitle}>
    //         3. Add your recipient
    //       </Typography>
    //       <Typography variant="h6" marked="center" className={classes.body1} >
    //         You can send directly to your recipient’s bank account! Remitbee allows you to send money to recipients in India, Sri Lanka, the Philippines and many more countries.
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12} md={6} className={classes.stepBox2}>
    //       <img
    //         src='/img/landing/how_to_send_step_4.svg'
    //         className={classes.image}
    //         alt="Payment method selection image"
    //         loading="lazy"
    //       />
    //       <Typography variant="h3" className={classes.subTitle}>
    //         4. Choose a Payment Method
    //       </Typography>
    //       <Typography variant="h6" marked="center" className={classes.body1} >
    //         Whether you use the app or online site, you can pay with Interac, Visa and Mastercard debit, or straight from your bank account.          
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12} md={6} className={classes.stepBox}>
    //       <img
    //         src='/img/landing/how_to_send_step_5.svg'
    //         className={classes.image + " " + classes.imageShadow5}
    //         alt="Transaction successfull image"
    //         loading="lazy"
    //       />
    //       <Typography variant="h3" className={classes.subTitle}>
    //         5. Let us take care of the rest
    //       </Typography>
    //       <Typography variant="h6" marked="center" className={classes.body1} >
    //         That’s it! Sit back and let us take care of your international money transfer. With our 100% money-back guarantee, you can finally have financial peace of mind.          
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </section>
    <>
      {content.map((item, index) => {
        return (
          <Grid key={item.title} item xs={12} md={6} className={index % 2 == 0 ? classes.stepBox : classes.stepBox2}>
            <img
              src={`/img/landing/how_to_send_step_${index + 1}.svg`}
              className={classes.image}
              alt={`How to send step ${index + 1}`}
              loading="lazy"
            />
            <Typography variant="h3" className={classes.subTitle}>
              {item.title}
            </Typography>
            {item.text && <Typography variant="body1" marked="center" className={classes.body1}>
            <Markdown
              children={item.text}
            />
            </Typography>}
          </Grid>
        )
      })}
    </>
  );
}

HowToSendMoneySection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HowToSendMoneySection);