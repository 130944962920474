import React, { useEffect } from "react";
import Typography from "../../../../../remitbee/components/typography/Typography";
import theme from "../../../../../remitbee/theme/Theme";
import Box from "../../../../../remitbee/components/box/Box";
import classes from "./BlogSection.module.scss";
import Spacing from "../../../../../remitbee/styles/spacing/Spacing";
import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Container from "../../../../../remitbee/components/container/Container";

interface Props {
    heading?: string;
    articles: {
        title: string;
        description: string;
        imageUrl: string;
        url: string;
        author: string;
        date: string;
    }[];
    numberOfArticles?: number;
    latestBlog?: boolean;
    filterByBlog?: string;
    filterByTag?: string;
}

const BlogSection: React.FC<Props> = ({ heading, articles, numberOfArticles = 3, latestBlog = false, filterByBlog, filterByTag }) => {
    const articlesData = articles ? typeof articles==='string' ? parseArrFromString(articles) : articles : null
    const router = useRouter();
    const isTablet  = useMediaQuery(`(min-width:${theme.breakpoints.sm}px) and (max-width:${theme.breakpoints.lg}px)`);
    const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.sm}px)`);

    const navigateToUrl = (url) =>{
       router.push(url);
      }    

    const card = (article, imageBoxClass) => {
        return(
            <div className={!isMobile && imageBoxClass} onClick={() => navigateToUrl(article.url)} >
            <div className={classes['rb-blog-section-image']}  >
                <img className={`${classes['rb-blog-image']} `}  src={article.imageUrl} alt="Article image" />
            </div>
            <Spacing variant="blog" />
            <div>
                <Typography variant="h2" weight="semibold" color={theme.palette.primary.navy}>{article.title}</Typography>
                <Spacing variant="betweenSectionsSmall" />
                <Typography variant="body1" >{article.description}</Typography>
                <Spacing variant="contentToCtaSmall" />
                <Box display="flex" alignItems="center">
                <Typography variant="label2"  weight="semibold">{article.author}</Typography>
                <li className={classes['rb-blog-text']}></li><Typography variant="label2" >{`${article.date}`}</Typography>
                </Box>
            </div>
        </div>
        )
    }

    return (
        <Container type="large">
        <div className={classes['rb-blog-section-root'] }>
            <Typography weight="bold" color={theme.palette.primary.navy} className={classes['rb-blog-section-heading']}>{heading}</Typography>
            <Spacing variant="betweenSectionToInputFields" />
            {
                isTablet && !isMobile ? <div  className={classes['rb-blog-section-image-box']}>
                {articlesData?.map((article, index) => (
                   card(article, classes['rb-blog-section-image-class-tablet'])
                ))}
            </div> : isMobile  ? <div  className={classes['rb-blog-section-image-box']}>
                {articlesData?.map((article, index) => (
                   card(article, classes['rb-blog-section-image-class-mobile'])
                ))}
            </div> :  <div className={classes['rb-blog-section-image-box']}>
                {articlesData?.map((article, index) => (
                   card(article, classes['rb-blog-section-image-class-desktop'])
                ))}
            </div>
            }
        </div>
        </Container>
    );
};

export default BlogSection;