import React, { useCallback, useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import classes from './ConverterGraph.module.scss'
import { useMediaQuery } from '@mui/material';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import CustomDropDown from './CustomDropDown';
import dayjs from 'dayjs';
import { isInteger } from '../../../../../shared/math';
import { getTransferRateHistory } from '../../../../../redux/rates/actions';
import { useSelector } from 'react-redux';
import Loader from '../../../../../remitbee/icons/Loader';
import { fetchCountriesRest } from '../../../../../redux/system/actions';
import { useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';

const Chart = dynamic(() => import('./Chart'), { ssr: false });

const DAY = '1D', WEEK = '1W', MONTH = '1M', TRIMESTER = '3M', SEMESTER = '6M', YEAR = '1Y';
const chartRangeOptions = [DAY, WEEK, MONTH, TRIMESTER, SEMESTER, YEAR];

let mapRangeOptions = {
    '1D': {
        from: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "hour",
        unitStepSize: 2,
    },
    "1W": {
        from: dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 1
    },
    "1M": {
        from: dayjs().subtract(1, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 7
    },
    "3M": {
        from: dayjs().subtract(3, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 10
    },
    "6M": {
        from: dayjs().subtract(6, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 1
    },
    "1Y": {
        from: dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 2
    },
}

const ConverterGraph = ({ countryId }) => {
    const { countries } = useSelector((state:any) => state.system)
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const [ratesHistory, setRatesHistory] = useState([]);
    const [duration, setDuration] = useState(MONTH);
    const [lastPosition, setLastPosition] = useState(null);
    const [selectedRate, setSelectedRate] = useState(countryId);
    const [range, setRange] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (countries?.length && countryId) {
            const parsedCountryId = parseInt(countryId);
            const country = countries.find(
              (c: any) =>
                (c?.country_id === parsedCountryId || c?.country_to_id === parsedCountryId) &&
                c.currency_code !== 'USD'
            ) || countries.find(
              (c: any) =>
                c?.country_id === parsedCountryId || c?.country_to_id === parsedCountryId
            );
            if (country) setSelectedCurrency(country);
          } else {
            dispatch(fetchCountriesRest());
          }
      }, [countries]);

    useEffect(() => {
        setLoading(true)
        loadCurrencyRateHistory();
    }, [duration, selectedCurrency])

    const loadCurrencyRateHistory =  async () => {
        let to = dayjs().format('YYYY-MM-DD H:mm:ss');
        let options = mapRangeOptions[duration];
        let rateId = selectedCurrency?.rate_id || selectedCurrency?.id;
        if (isInteger(rateId)) {
            const { success, data } = await getTransferRateHistory(
                {
                    rateId,
                    dateFrom: options.from,
                    dateTo: to,
                });
            if (success) {
                setRatesHistory(data.transferRateHistory);
                setRange({ to, ...options });
            }
        }
        setLoading(false);
    }


    function getPercentageDifference(value1, value2) {
        const difference = value2 - value1;
        const percentage = (difference / value1) * 100;
        const symbol = difference >= 0 ? '+' : '-';
        const absolutePercentage = Math.abs(percentage).toFixed(2);
        
        return {text:`${symbol} ${absolutePercentage}%`, status: symbol === '+' ? 'up' : 'down', value:difference };
    }


  const difference = getPercentageDifference(ratesHistory.at(-2)?.rate, ratesHistory.at(-1)?.rate)  

  
  return (
    <>
    <section className={classes['rb-section-root']}>
    <Box className={classes['rb-root']}>
    {(loading && !ratesHistory.length) ? <Loader width={80} height={80} /> :
    <>
    <Box>
       <Typography variant='displayXl' color={theme.palette.primary.navy} weight='semibold'>{`Canadian Dollar to ${selectedCurrency?.currency_name}`}</Typography> 
    </Box> 
    {isMobile && 
    <Box mt={4} mb={4}><CustomDropDown
            options={[{ label: '1 Day', value: '1D' }, { label: '1 Week', value: '1W' }, { label: '1 Month', value: '1M' }, { label: '3 Month', value: '3M' }, { label: '6 Month', value: '6M' }, { label: '1 Year', value: '1Y' }]}
            value={duration}
            setValue={setDuration}
            fullWidth={true}
    /></Box>
    }
    {<Spacing variant={isMobile ? 'titleToDivider' : 'betweenSections'}/>}
    <Box className={classes['rb-graph-header-container']}>
         <div className={classes['rb-frame-1']}>
          <div>
            <Typography variant='displayLg' weight='semibold' color={theme.palette.primary.navy}>
                {ratesHistory.at(-1)?.rate}
            </Typography>
            {ratesHistory.length ? <><div className={`${classes['rb-rate-status']} ${classes[`rb-rate-status--${difference?.status}`]}`}><Typography variant='textXs' weight='medium'>{difference.text}</Typography></div>
            <Typography variant='textMd' weight='medium' color={difference?.status === 'down' ? theme.palette.error.dark : theme.palette.success.dark}>{`${difference?.value?.toFixed(4)} Today`}</Typography></> : null}
          </div>
          <div>
            <Typography variant='textXs'>{`${ratesHistory.at(-1)?.datetime_added || ''} |`}</Typography>
            <Typography color={theme.palette.primary.blue} variant='textXs'>Disclaimer</Typography>
          </div>
        </div>
        {!isMobile && <CustomDropDown
            options={[{ label: '1 Day', value: '1D' }, { label: '1 Week', value: '1W' }, { label: '1 Month', value: '1M' }, { label: '3 Month', value: '3M' }, { label: '6 Month', value: '6M' }, { label: '1 Year', value: '1Y' }]}
            value={duration}
            setValue={setDuration}
            fullWidth={false}
        />}
    </Box>
    <Box mt={4} className={classes['rb-chart-container']}><Chart ratesHistory={ratesHistory} setRatesHistory={setRatesHistory} lastPosition={lastPosition} setLastPosition={setLastPosition} /></Box>
    <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}></Box>
    </>}
    </Box>    
    </section>
</>
  )
}

export default ConverterGraph