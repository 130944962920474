import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(5),
    border: '1px solid #CACCD5',
    borderRadius: '10px'
  },
  title: {
    padding: theme.spacing(3),
    borderBottom: '1px solid #CACCD5',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  primary: {
    color: '#31517A',
    backgroundColor: '#fff'
  },
  secondary: {
    color: '#fff',
    backgroundColor: '#31517A'
  },
  content: {
    padding: theme.spacing(2, 3),
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#626680',
  },
  verticalLines: {
    borderLeft: '1px solid #CACCD5',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none'
    }
  },
  odd: {
    backgroundColor: '#F9F8F7',
  },
  row: {
    backgroundColor: '#fff',
  },
  line: {
    borderBottom: '1px solid #CACCD5',
    '&:last-child': {
      borderBottom: 'none'
    },
  },
  topDivider: {
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #CACCD5',
    }
  },
  firstLeft: {
    borderTopLeftRadius: '10px',
    [theme.breakpoints.down('md')]: {
      borderTopRightRadius: '10px',
    }
  },
  firstRight: {
    borderTopRightRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '0px',
    }
  },
  lastLeft: {
    borderBottomLeftRadius: '10px',
  },
  lastRight: {
    borderBottomRightRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: '10px',
    }
  }
});

const DynamicTable = ({ columns, titleColor, verticalLines, classes }) => {
  const grid = columns ? Math.ceil(12 / columns.length) : null;
  const displayLines = verticalLines === 'true';
  const style = titleColor || 'primary';

  if (columns) return (
    <Grid container className={classes.container}>
      {columns.map((column, index) => {
        const align = column.align || 'center';

        return (
          <Grid item xs={12} md={grid} key={index} className={displayLines && index !== 0 ? classes.verticalLines : null}>
            <Box
              className={clsx(classes.title,
                classes[style],
                index !== 0 ? classes.topDivider : '',
                index === 0 ? classes.firstLeft : index === columns.length - 1 ? classes.firstRight : '')}
              display='flex'
              justifyContent={align}>
              {column.title}
            </Box>
            {column.content && column.content.split(',').map((content, innerIndex, array) => (
              <Box
                key={innerIndex}
                display='flex'
                justifyContent={align}
                className={clsx(classes.content,
                  (innerIndex === array.length - 1) && (index === 0) ? classes.lastLeft : '',
                  (innerIndex === array.length - 1) && (index === columns.length - 1) ? classes.lastRight : '',
                  classes.line,
                  innerIndex % 2 === 0 ? classes.odd : classes.row)}>
                <Markdown children={content} />
              </Box>
            ))}
          </Grid>
        )
      })}
    </Grid>
  );
  else return null;
}

export default withStyles(styles)(DynamicTable);
