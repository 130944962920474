import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import FlagIcon from '../../common/FlagIcon';
import { useTranslation } from 'next-i18next';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import { bigTitle, container } from '../globalStyles';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  container,
  bigTitle: {
    ...bigTitle,
    marginBottom: '25px !important',
    marginTop: '0px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important'
    },
  },
  root: {
    background: "url('/img/landing/map_bg_transparent.png') center no-repeat",
    backgroundSize: '100% auto !important',
    backgroundPosition: 'left top',
    paddingTop: '40px',
    paddingBottom: '60px',
  },
  countryName: {
    paddingLeft: theme.spacing(2),
    color: "#37517A",
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px !important'
    },
  },
  countryItem: {
    width: '100%',
    padding: '20px 20px 0px 20px',
    "& a": {
      color: "#37517A",
      textDecoration: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },
  countriesSection: {
    paddingBottom: '40px'
  },
  whitebg: {
    background: 'white',
  },
  blueArrow: {
    transform: "rotate(-90deg) !important",
  },
  mobileContainer: {
    width: '100%',
    paddingTop: '30px'
  },
  mobileLink: {
    width: '100%'
  },
  greyLine: {
    borderTop: '0px',
    opacity: '0.4'
  },
  bottomLine: {
    marginTop: '30px'
  }
}))

const AllCountriesSection = ({ countries }) => {
  const underlineColor = '#1160F7';
  const { t } = useTranslation('landing');
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [fullCountriesList, setFullCountriesList] = useState([]);
  const [shortCountriesList, setShortCountriesList] = useState([]);
  const [displayCountries, setDisplayCountries] = useState(shortCountriesList);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('xs'));

  useEffect(() => {
    if (countries && countries.length) {
      setFullCountriesList(countries);
      let halfIndex = countries.length / 2;
      if (halfIndex % 4 != 0) halfIndex += 4 - (halfIndex % 4);
      const shortList = countries.slice(0, isNotMobile ? 6 : halfIndex);
      setShortCountriesList(shortList);
    }
  }, [countries]);

  useEffect(() => {
    if (shortCountriesList.length) {
      setDisplayCountries(shortCountriesList);
    }
  }, [shortCountriesList]);

  const handleClickEvent = () => {
    if (expanded) {
      setDisplayCountries(shortCountriesList);
    } else {
      setDisplayCountries(fullCountriesList);
    }
    setExpanded(!expanded)
  }

  const expandButton = (
    <CustomButton type="outlinedBlue" onClick={handleClickEvent}>{t('expand_section')}</CustomButton>
  );
  const collapseButton = (
    <CustomButton type="outlinedBlue" onClick={handleClickEvent}>{!isNotMobile ? t('hide_section') : t('collapse_section')}</CustomButton>
  );

  const renderCountryItem = (country, index) => {
    const { country_to, country_name, iso2 } = country;
    const countryName = country_to || country_name;
    const country_url = iso2 === 'US' ? 'us' : iso2 === 'CG' ? 'republic-of-the-congo' : countryName?.toLowerCase().split(" ").join("-")
    return (
      <Grid item container xs={12} sm={6} md={4} lg={3} className={classes.countryItem} key={index}>
        <a href={`https://www.${process.env.CP_URL}/send-money-to-${country_url}`}>
          <Grid item container direction="row" xs={12}>
            <Box display="flex" justifyContent="center">
              <Box>
                <Grid><FlagIcon code={iso2}></FlagIcon></Grid>
              </Box>
              <Box>
                <Grid><Typography component='span' className={classes.countryName}>{countryName}</Typography></Grid>
              </Box>
            </Box>
          </Grid>
        </a>
      </Grid>
    )
  }

  const renderMobileItem = (country, index) => {
    const { country_to, country_name, iso2 } = country;
    const countryName = country_to || country_name;
    const country_url = iso2 === 'US' ? 'us' : countryName?.toLowerCase().split(" ").join("-");
    return (
      <Grid item container xs={12} md={3} className={classes.countryItem} key={index}>
        <a href={`https://www.${process.env.CP_URL}/send-money-to-${country_url}`} className={classes.mobileLink}>
          <hr className={classes.greyLine} />
          <Grid item container direction="row" xs={12} className={classes.mobileContainer}>
            <Grid item xs={1}><FlagIcon code={iso2}></FlagIcon></Grid>
            <Grid item xs={10}><Typography className={classes.countryName}>{countryName}</Typography></Grid>
            <Grid item xs={1}><img src="/img/landing/svg/blue_arrow.svg" className={classes.blueArrow} alt={`blue arrow ${index + 1}`}></img></Grid>
          </Grid>
          {index + 1 === displayCountries.length && <hr className={classnames(classes.greyLine, classes.bottomLine)} />}
        </a>
      </Grid>
    )
  }

  return (
    <div>
      <div className={classes.whitebg}>
        <Grid item container xs={12} justifyContent='center' className={classnames(classes.container, classes.root)}>
          <Typography variant="h2" className={classes.bigTitle}>
            {t('around_the_globe')}
          </Typography>
          <CustomUnderlineBigHeading color={underlineColor} />
          <Grid item container xs={12} className={classes.countriesSection}>
            {displayCountries?.map(!isNotMobile ? renderMobileItem : renderCountryItem)}
          </Grid>
          {expanded ? collapseButton : expandButton}
        </Grid>
      </div>
    </div>
  )
}

// const mapStateToProps = ({ system }) => ({
//   countries: system.countries,
// });

// const mapDispatchToProps = (dispatch) => ({
//   fetchCountries: () => dispatch(fetchCountriesRest()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AllCountriesSection);
export default AllCountriesSection;