import { makeStyles } from '@mui/styles';
import React from 'react';
import Link from 'next/link';
import { strapiQuery } from '../../../../settings/apollo/strapi/query';
import AutocompleteSearch from './AutocompleteSearch';
import { SEARCH_HELP_QUERY } from '../../apollo/queries/help/article/search';

const useStyles = makeStyles(() => ({
    searchInput: {
        width: "100%",
        "& div, input": {
            width: "100%"
        }
    },
    link: {
        textDecoration: 'none',
        color: '#37517A'
    }
}));

function ArticleSearchInput() {
    const classes = useStyles();

    const getResults = async input => {
        const { data } = await strapiQuery(SEARCH_HELP_QUERY, { search: input });
        return data ? data.helpArticles : null;
    }

    const getItem = ({ slug, title, categorySlug }) => (
        <Link
            href="/help/[category]/[article]" 
            as={`/help/${categorySlug || slug}/${slug}`}
        >
            <a className={classes.link}>{title}</a>
        </Link>
    );

    return (
        <AutocompleteSearch 
            getResults={getResults}
            getItem={getItem}
            className={classes.searchInput}
        />
    )
}

export default ArticleSearchInput

