import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid';
import FlagIcon from '../common/FlagIcon';
import { useTranslation } from 'next-i18next';
import { container, bigTitle } from '../landingPage/globalStyles';
import classnames from 'classnames';
import CustomButton from '../landingPage/components/CustomButtonsWebsite/CustomButton';

const styles = theme => ({
	container,
	bigTitle: {
		...bigTitle,
		marginBottom: '40px !important',
		marginTop: '0px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px !important'
		},
	},
	root: {
		background: "url('/img/landing/map_bg_transparent.png') center no-repeat",
		backgroundSize: 'contain',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		paddingTop: 40,
		paddingBottom: 60
	},
	padding: {
		paddingTop: 40,
		paddingBottom: 40
	},
	currencySection: {
		display: 'flex',
		marginBottom: 40
	},
	flagIcon: { marginRight: 10 },
	currency: {
		width: '100%',
		padding: 10,
		color: '#626680',
		justifyContent: 'left',
		textDecoration: 'none'
	},
	currencyBox: {
		border: '1px solid #CACCD5',
		borderRadius: 3,
		padding: theme.spacing(1.5, 0)
	},
	button: {
		padding: 10,
		color: '#1160F7',
		borderColor: '#1160F7'
	}
});

const CurrencyListSection = ({ classes, countries }) => {
	const [countriesCurrencyList, setCountriesCurrencyList] = useState([])
	const [shortCountriesCurrencyList, setShortCountriesCurrencyList] = useState([])
	const [expanded, setExpanded] = useState(false);
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		loadTransferRates();
	}, [countries]);

	const loadTransferRates = async () => {
		if (countries && countries.length) {
			const countriesList = [], currencyList = []
			countries.forEach(item => {
				const { currency_code } = item
				if (currencyList.indexOf(currency_code) < 0) {
					currencyList.push(currency_code)
					countriesList.push(item)
				}
			})
			setCountriesCurrencyList(countriesList)
			const shortList = countriesList.slice(0, 16)
			setShortCountriesCurrencyList(shortList)
			setLoading(false);
			return
		}

		setLoading(false);
	}

	const handleClick = () => {
		setExpanded(!expanded)
	}

	const currencyList = expanded ? countriesCurrencyList : shortCountriesCurrencyList;

	if (loading) return <></>

	return (
		<section className={classes.root}>
			<Grid container item xs={12} justifyContent="center" className={classnames(classes.container, classes.padding)}>
				<Typography variant="h2" className={classes.bigTitle}>
					{t('landing:around_the_globe')}
				</Typography>
				<Grid item container xs={12} spacing={2} className={classes.currencySection}>
					{currencyList?.map((country, index) => (
						<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
							<div className={classes.currencyBox}>
								<a className={classes.currency} href={country?.currency_code.toLowerCase() === 'usd' ? `/currency-exchange/exchange-cad-to-usd` : `/currency-converter/convert-cad-to-${country?.currency_code.toLowerCase()}`}>
									<FlagIcon
										className={classes.flagIcon}
										code={country.iso2}
										size={30}
									/>
									{`${country.currency_code} - ${country.currency_name}`}
								</a>
							</div>
						</Grid>
					))}
				</Grid>
				<Grid item xs={12}>
					<CustomButton type="outlinedBlue" onClick={handleClick} className={classes.button}>
						{expanded ? t('landing:see_fewer_countries') : t('landing:see_all_countries')}
					</CustomButton>
				</Grid>
			</Grid>
		</section>
	);
}

CurrencyListSection.propTypes = {
	classes: PropTypes.object.isRequired,
};

const CurrencyListSectionWithStyles = (withStyles(styles)(CurrencyListSection));


// const mapStateToProps = ({ system }) => ({
// 	countries: system.countries,
// });

// const mapDispatchToProps = (dispatch) => ({
// 	fetchCountries: () => dispatch(fetchCountriesRest()),
// });

// export default (connect(mapStateToProps, mapDispatchToProps)(CurrencyListSectionWithStyles), { skipLoading: true });
// export default connect(mapStateToProps, mapDispatchToProps)(CurrencyListSectionWithStyles);
export default CurrencyListSectionWithStyles;