import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { interpolate } from '../../../../../shared/utility';
import { bigTitle, container } from '../../../globalStyles';

const styles = theme => ({
    container,
    bigTitle,
    root: {
        display: 'flex',
        overflow: 'hidden',
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundColor: '#ffffff !important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '60px',
            paddingBottom: '60px',
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '30px 10px',
    },
    image: {
        height: 50,
    },
    whatWeOfferTitle: {
        marginBottom: theme.spacing(5),
        textAlign: "left",
        fontSize: "18px",
        fontWeight: "500",
        color: "#31517A",
        margin: "10px 0 10px 0 !important",
    },
    whatWeOfferDescription: {
        fontSize: "1rem",
        textAlign: "left",
        color: "#6B708F",
        lineHeight: '1.6',
    },
    whatWeOfferBox: {
        marginBottom: "50px",
        transition: "ease-in-out 0.3s",
        borderRadius: "10px",
        boxShadow: "0 3px 12px 0 rgba(11,65,153,0.1)",
        "&:hover,&:focus": {
            boxShadow: "0 3px 12px 0 rgba(11,65,153,0.1)",
        },
        [theme.breakpoints.up('sm')]: {
            margin: '15px',
            boxShadow: "0 34px 24px 0 rgba(11,65,153,0.1)",
            "&:hover,&:focus": {
                boxShadow: "0 10px 8px 0 rgba(11,65,153,0.2)",
            },
        },
    },
});

function Cards(props) {
    let { classes, title, section_item, data } = props;
    title = interpolate(title, data);

    const renderSectionItem = (item) => {
        let {title, text, image} = item;
        text = interpolate(text, data)
        title = interpolate(title, data)

        let imageUrl = image && image.url || "";
        if(imageUrl.startsWith('/uploads')) imageUrl = process.env.STRAPI_URL + imageUrl;

        return (
            <Grid item xs={12} sm={6} md={4} className={classes.whatWeOfferBox}>
                <div className={classes.item}>
                    <img
                        className={classes.image}
                        src={imageUrl}
                        alt="Section image"
                    />
                    <Typography variant="h6" className={classes.whatWeOfferTitle}>
                        {title}
                </Typography>
                    {text && <Typography variant="h5" className={classes.whatWeOfferDescription}>
                        <Markdown
                            children={text}
                        />
                    </Typography>}
                </div>
            </Grid>
        )
    }


    return (
        <section className={classes.root}>
            <Container className={classes.container}>

                <Grid container spacing={5} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.bigTitle}>
                            {title}
                        </Typography>
                    </Grid>
                    {section_item.map(renderSectionItem)}
                        
                </Grid>
            </Container>
        </section>
    );
}

Cards.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cards);