import { Box, Card, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { getClientHasAccountCookie, setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import { Button } from '../../../common';
import ExchangeInput from '../../../common/currencyInput/ExchangeInput';
import PayWithInput from '../../../common/currencyInput/PayWithInput';
import styles from "./styles";
import { useRouter } from 'next/router';
import Skeleton from '@mui/material/Skeleton';
import { connect } from 'react-redux';
import { fetchCountriesRest } from '../../../../redux/system/actions';

const useStyles = makeStyles(styles);
function ExchangeInputBox(props) {
  const router = useRouter();
  const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [deliveryETA, setDeliveryETA] = useState(null);
  const [transferAmount, setTransferAmount] = useState(props.isTransactionFlow ? null : props.defaultTransferValue || 1000);
  const [receivingAmount, setReceivingAmount] = useState(null);
  const [hasAccount, setHasAccount] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState();
  const [additionalInfo, setAdditionalInfo] = useState();
  const { countryIdSelected, currencySelected, showSpotRate } = props;
  const [countryList] = useState(null);
  const { t } = useTranslation();
  const [fundingHolidayDates, setFundingHolidayDates] = useState();
  const [settlementHolidayDates, setSettlementHolidayDates] = useState();

  const classes = useStyles();
  let classnames = classes.exchangeInputBox;
  if (props.className) classnames += (' ' + props.className);

  const loadSystemCountries = async () => {
    if (!props?.countries) await props.fetchCountries();
  }

  useEffect(() => {
    const getCookie = async () => {
      if (await getClientHasAccountCookie()) {
        setHasAccount(true);
      }
    }

    getCookie();
    loadSystemCountries();
  }, []);

  useEffect(() => {
    if (router.asPath && router.asPath.includes('currency-converter') && router.asPath.includes('amount')) {
      const amount = router.query?.amount || 1000;
      if (router.asPath.includes('cad-to'))
        setTransferAmount(amount);
      else {
        setReceivingAmount(amount);
      }
    }
  }, [router && router.query])

  useEffect(() => {
    const { countries } = props || {};
    if (countries && countries.length && selectedCountry) {
      countries.some(({ country_id, country_name }) => {
        if (+country_id === +selectedCountry) return setSelectedCountryName(country_name);
      });
    }
  }, [selectedCountry])

  const handleChangeReceivingCountry = (countryId) => {
    setSelectedCountry(countryId);
    props.onChangeReceivingCountry && props.onChangeReceivingCountry(countryId)
  }

  const startCurrencyExchange = async () => {
    await setRedirectSignupCookie(router.asPath)
    if (hasAccount) {
      router.push('/login?redirectTo=/dashboard');
    } else {
      router.push('/signup');
    }
  }

  const isHolidayTooltip = fundingHolidayDates || settlementHolidayDates;

  return (
    <Box className={classnames + ' ' + (props.altBox ? classes.altBox : '') + ' ' + (props.side ? (props.side === 'left' ? classes.leftSide : classes.rightSide) : '')}>
      <Card variant={'outlined'} className={classes.exchangeInputCard}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <ExchangeInput
              {...props}
              value={transferAmount}
              list={countryList}
              convertedValue={receivingAmount}
              activeCountries={props.activeCountries}
              onChangeTransferAmount={props.onChangeTransferAmount ? props.onChangeTransferAmount : setTransferAmount}
              onChangeTransferRate={props.onChangeTransferRate}
              countryIdSelected={countryIdSelected}
              onChangeReceivingCountry={handleChangeReceivingCountry}
              showSpotRate={showSpotRate}
              authUser={false}
              sendingCountrySelectable
              receivingCountrySelectable
              defaultCurrency={currencySelected}
              onChangePaymentTypes={types => setPaymentTypes(types)}
              onChangeAdditionalInfo={info => setAdditionalInfo(info)}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center" alignItems="center">
            <Grid item className={classes.divider} container sm={6} xs={12} justifyContent="center" alignItems="center" direction={!isNotMobile ? "row" : "column"} style={!isNotMobile ? { marginBottom: 8 } : {}}>
              <PayWithInput availablePaymentTypes={paymentTypes} additionalInfo={additionalInfo} onChangeSelect={setDeliveryETA} setFundingHolidayDates={(e) => setFundingHolidayDates(e)} setSettlementHolidayDates={(e) => setSettlementHolidayDates(e)} />
            </Grid>

            <Box display='flex' alignItems="center" style={{ width: '100%' }}>
              <Box flexGrow={1}>
                <Typography variant="body1" className={classes.exchangeCardSubtitle}>{t('landing:estimated_delivery')} </Typography>
              </Box>
              {deliveryETA ?
                (<Box className={classes.deliveryETAHolder}>
                  <Typography variant="body2" className={classes.exchangeCardText}>
                    {deliveryETA}
                  </Typography>
                  <br />
                  {isHolidayTooltip ? <Tooltip
                    arrow
                    leaveDelay={100}
                    title={fundingHolidayDates && settlementHolidayDates
                      ? t('landing:holiday_message_info.combined_message', { recipient_country: selectedCountryName || `Recipient's country`, funding_formatted_dates: fundingHolidayDates, settlement_formatted_dates: settlementHolidayDates })
                      : fundingHolidayDates ? t('landing:holiday_message_info.funding_message', { formatted_dates: fundingHolidayDates })
                        : settlementHolidayDates ? t('landing:holiday_message_info.settlement_message', { recipient_country: selectedCountryName || `Recipient's country`, formatted_dates: settlementHolidayDates })
                          : ''}
                    placement='top'
                    classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
                    <img src="/info.svg" className={classes.imgPadding} width={16} />
                  </Tooltip> : <></>}
                </Box>) :
                <Box>
                  <Skeleton variant="rectangular" width={60} height={23} />
                </Box>
              }
            </Box>
          </Grid>
          {/*           <Grid item container xs={12} justifyContent="left" alignItems="left">
            {holidayDates?.canada?.length > 0 &&
              <Typography variant="body1" className={classes.exchangeCardSubtitle}>{t('landing:holiday_dates', { country: 'Canada', dates: `${holidayDates.canada.join(', ')}` })}</Typography>
            }
            {holidayDates?.overseas?.length > 0 &&
              <Typography variant="body1" className={classes.exchangeCardSubtitle}>{t('landing:holiday_dates', { country: selectedCountryName || `Recipient's country`, dates: holidayDates.overseas.join(', ') })}</Typography>
            }
          </Grid> */}
          <Grid item xs={12}>
            <Button fullWidth className={classes.button} onClick={() => startCurrencyExchange()}>{t('landing:send_now')}</Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}



const mapStateToProps = state => ({
  countries: state.system.countries,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountriesRest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeInputBox);
