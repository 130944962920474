import { Grid, IconButton } from '@mui/material';
import Typography from '../../../../remitbee/components/typography/Typography';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Skeleton from '@mui/material/Skeleton';
import dynamic from 'next/dynamic';
import { bool, func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setConvertedAmount,
  setConvertedCountryId,
  setExchangeAmount,
  setExchangeRate,
  setOriginalCountryId,
} from '../../../../redux/exchange/actions';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getClientHasAccountCookie, setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import { formatNumber, MathOperation, roundAmount } from '../../../../shared/math';
import Button from '../../../../remitbee/components/button/Button';
import ExchangeInput from '../../../exchangeCurrency/exchangeCurrencyWizard/exchangeDetails/exchangeConverter/ExchangeInput';
import { calculateCurrencyExchangeSession } from '../../../../redux/exchange/session/actions';
import theme from '../../../../remitbee/theme/Theme';

const CurrencyHorizontalInput = dynamic(() =>
  import('../../../exchangeCurrency/currencyHorizontalInput/CurrencyHorizontalInput')
);
const { getCurrencyExchangeRates } = require('../../../../shared/exchange-rates');

const useStyles = makeStyles(() => ({
  exchangeBtn: {
    backgroundColor: '#2EBC31',
    color: 'white',
  },
  horizontalCard: {
    border: '1px solid #CACCD5',
    background: '#fff',
    borderRadius: '10px',
    '@media (min-width: 576px)': {
      padding: theme.spacing(4),
    },
    '@media (max-width: 576px)': {
      padding: theme.spacing(3, 3, 2, 3),
    },
  },
  card: {
    maxWidth: '460px',
    border: '1px solid #CACCD5',
    background: '#fff',
    borderRadius: '10px',
    position: 'relative',
    '@media (max-width: 768px)': {
      marginRight: 'auto',
      marginLeft: 'auto',
      left: '0px',
    },
    '@media (min-width: 576px)': {
      padding: theme.spacing(4),
    },
    '@media (max-width: 576px)': {
      padding: theme.spacing(3, 3, 2, 3),
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '39px',
  },
  icon: {
    color: theme.palette.primary.green,
    cursor: 'pointer',
    fontSize: '50px',
    '@media (max-width: 959px)': {
      display: 'none',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  mobileIcon: {
    color: theme.palette.primary.green,
    cursor: 'pointer',
    fontSize: '70px',
    display: 'none',
    '@media (max-width: 959px)': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  },
  rate: {
    position: 'absolute',
    textAlign: 'left',
    display: 'inline-block',
    fontSize: '14px',
    color: theme.palette.primary.green,
  },
  rateAmount: {
    fontSize: '14px',
    color: theme.palette.secondary.green,
  },
  inputRight: {
    flex: '2',
    '@media (max-width: 959px)': {
      display: 'none',
    },
  },
  inputLeft: {
    flex: '2',
    '@media (max-width: 959px)': {
      display: 'none',
    },
  },
  button: {
    width: '100%',
    '@media (min-width: 768px)': {
      height: '60px',
      fontSize: '18px',
      lineHeight: '26px',
    },
    '@media (max-width: 768px)': {
      height: '48px',
      padding: '12px 48px',
      fontSize: '16px',
      lineHeight: '23.5px',
    },
  },
  buttonContainerHorizontal: {
    width: '100%',
    paddingTop: '32px',
    '@media (min-width: 576px)': {
      paddingLeft: theme.spacing(4),
    },
  },
  buttonContainerVertical: {
    width: '100%',
  },
  feesContainer: {
    display: 'block',
    fontSize: '16px',
    color: theme.palette.primary.light,
    '@media (min-width: 768px)': {
      marginTop: theme.spacing(2),
    },
    '@media (max-width: 768px)': {
      marginTop: theme.spacing(3),
    },
    '@media (max-width: 576px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  feesAmount: {
    marginLeft: '4px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.primary.green,
    fontSize: '18px',
    lineHeight: '26px',
  },
  feesLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '26px',
  },
  rateContainer: {
    marginTop: theme.spacing(2),
  },
  iconContainerMobile: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    marginLeft: '15px',
    '@media (max-width: 576px)': {
      marginLeft: '20px',
    },
  },

  iconMobile: {
    cursor: 'pointer',
    '@media (max-width: 576px)': {
      width: '40px',
    },
  },
  dottedLine: {
    position: 'relative',
    top: '0px',
    left: '40px',
    width: '0px',
    height: '130px',
    border: '#105CED',
    borderStyle: 'dashed',
    borderWidth: '0 2px 0 0',
    opacity: '1',
    '@media (max-width: 576px)': {
      height: '100px',
    },
  },
  rateContainerMobile: {
    textAlign: 'left',
    marginLeft: '20px',
    alignItems: 'center',
  },
  dotIconMobile: {
    color: theme.palette.primary.green,
    fontSize: 'small',
    textAlign: 'right',
    alignSelf: 'flex-start',
  },
  topRateMobile: {
    color: '#626680',
    display: 'block',
    fontWeight: 500,
    fontSize: '16px',
    '@media (max-width: 576px)': {
      fontSize: '16px',
    },
  },
  bottomRateMobile: {
    fontSize: '14px',
    marginTop: '6px',
    color: theme.palette.input.body,
  },
  topRate: {
    color: '#626680',
    display: 'block',
    fontWeight: 500,
    fontSize: '18px',
  },
  bottomRate: {
    fontSize: '16px',
    marginTop: '10px',
    color: theme.palette.input.body,
    display: 'block',
  },
  dotIcon: {
    color: theme.palette.success.dark,
    fontSize: 'small',
    textAlign: 'right',
    alignSelf: 'flex-start',
  },
  loadingSplash: {
    height: '175px',
  },
  skeletonStyle1: {
    marginBottom: 0,
    marginTop: 40,
  },
  skeletonStyle2: {
    marginBottom: 100,
    marginTop: 0,
  },
}));

const fields = {
  SENDING: 'SENDING',
  RECEIVING: 'RECEIVING',
};

const UNITED_STATES_ID = 236;
const CANADA_ID = 40;
const UNITED_STATES_CURRENCY_CODE = 'USD';
const CANADA_CURRENCY_CODE = 'CAD';

export function HorizontalExchangeConverter({
  value,
  eraseOnFocus,
  hasInstantSpeed,
  roundingPattern,
  onChangeTransferAmount,
  setExchangeRate,
  setExchangeAmount,
  setConvertedAmount,
  setConvertedCountryId,
  setOriginalCountryId,
  convertedCountryId,
  originalCountryId,
  exchangeRate,
  type,
  // reroute,
  vertical,
  showSpotRate,
}) {
  const classes = useStyles();
  const router = useRouter();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const horizontal = !isTablet && !vertical; 
  const { exchangeBtn } = useStyles;
  const [transferRates, setTransferRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendingValue, setSendingValue] = useState(1000.00);
  const [receivingValue, setReceivingValue] = useState(0.0);
  const [lastFieldChanged, setLastFieldChanged] = useState(fields.SENDING);
  const [originalCurrency, setOriginalCurrency] = useState(CANADA_CURRENCY_CODE);
  const [convertedCurrency, setConvertedCurrency] = useState(UNITED_STATES_CURRENCY_CODE);
  const [reverseRate, setReverseRate] = useState(0);
  const [hasAccount, setHasAccount] = useState(false);
  const [initialValue] = useState(value !== '' ? value : null);
  const [initialReceivingValue, setInitialReceivingValue] = useState(null);
  const [clickEvent, setClickEvent] = useState(false);

  useEffect(() => {
    value && onSendingValueChange(value || '');
    const timer = setTimeout(() => {
      loadTransferRates();
    }, 500);

    if (type === 'usd-to-cad' && originalCountryId === 40) {
      swapCurrencies(236);
    } else if (type === 'cad-to-usd' && originalCountryId === 236) {
      swapCurrencies(40);
    }

    return () => { clearTimeout(timer) }
  }, []);

  useEffect(() => {
    if (router && router.query && router.query.amount) {
      if (router.asPath.includes('cad-to')) {
        setSendingValue(parseFloat(router.query.amount));
      } else {
        setSendingValue(parseFloat(router.query.amount));
      }
    }
  }, [router && router.query]);

  useEffect(() => {
    const requestTimerId = setTimeout(updateCurrencies, 600);

    const getCookie = async () => {
      if (await getClientHasAccountCookie()) {
        setHasAccount(true);
      }
    };
    getCookie();

    return () => clearTimeout(requestTimerId);
  }, [sendingValue, receivingValue, transferRates, hasInstantSpeed]);

  useEffect(() => {
    updateCurrencies();
  }, [convertedCountryId, originalCountryId]);

  function updateCurrencies() {
    if (originalCountryId !== convertedCountryId && transferRates.length) {
      if (sendingValue !== initialValue || receivingValue !== initialReceivingValue)
        calculateConversion();
    }
    if (transferRates) {
      const fromInfo = transferRates.filter(
        (country) => country.country_from_id == originalCountryId
      )[0];
      const toInfo = transferRates.filter(
        (country) => country.country_from_id == convertedCountryId
      )[0];
      const fromCurrency = fromInfo ? fromInfo.currency_code : null;
      const toCurrency = toInfo ? toInfo.currency_code : null;
      if (fromCurrency) setOriginalCurrency(fromCurrency);
      if (toCurrency) setConvertedCurrency(toCurrency);
    }
  }

  const loadTransferRates = async () => {
    let res = null;
    let exchangeData = null;
    exchangeData = await getCurrencyExchangeRates();
    res = { success: true, data: { ExchangeRatesOnline: exchangeData } };
    const { success, data } = res;
    if (success) {
      const { ExchangeRatesOnline } = data;
      let countries = [...ExchangeRatesOnline];
      const canada = {
        country_from_id: 40,
        country_to_id: 236,
        currency_code: 'CAD',
        country_from: 'Canada',
        currency_name: 'Canadian Dollar',
        iso2: 'CA',
        iso3: 'CAN',
      };
      countries.push(canada);
      setTransferRates(countries);
      roundTransferRate(countries);
    }
  };

  const calculateConversion = async () => {
    let rate = 1;
    let reverseRate = 1;
    const { data } = await calculateCurrencyExchangeSession({
      country_from_id: originalCountryId,
      country_to_id: convertedCountryId,
    });

    if (data) {
      const { rate: rates, spot_rate } = data.CalculateCurrencyExchangeAmount;
      rate = showSpotRate ? spot_rate : rates;
      reverseRate = MathOperation.divide(1, rate, 4);
    }

    if (lastFieldChanged === fields.SENDING) {
      let converted = MathOperation.multiply(sendingValue, rate, 4) || 0;
      if (converted && roundingPattern) {
        converted = roundAmount(converted, roundingPattern);
      }
      converted = formatNumber(converted, { useGrouping: true });
      setReceivingValue(converted || '');
      if (!initialReceivingValue) setInitialReceivingValue(converted || '');
    } else {
      let converted = MathOperation.divide(receivingValue, rate, 4) || 0;
      converted = formatNumber(converted, { useGrouping: true });
      setSendingValue(converted || '');
      onChangeTransferAmount && onChangeTransferAmount(converted);
    }

    setExchangeAmount(sendingValue);
    setConvertedAmount(receivingValue);
    setExchangeRate(rate);
    setReverseRate(reverseRate);
    setLoading(false);
  };

  const onSendingValueChange = (value) => {
    setLastFieldChanged(fields.SENDING);
    setSendingValue(value);
    onChangeTransferAmount && onChangeTransferAmount(value);
  };

  const onReceivingValueChange = (value) => {
    setLastFieldChanged(fields.RECEIVING);
    setReceivingValue(value);
    setSendingValue('');
  };

  const onChangeReceivingCountryId = (countryId) => {
    setConvertedCountryId(countryId);
    setLastFieldChanged(fields.RECEIVING);
    setSendingValue('');
    if (countryId === CANADA_ID) {
      setOriginalCountryId(UNITED_STATES_ID);
    } else if (countryId === UNITED_STATES_ID) {
      setOriginalCountryId(CANADA_ID);
    }
  };

  const onChangeSendingCountryId = (countryId) => {
    setLoading(true);
    setOriginalCountryId(countryId);
    setLastFieldChanged(fields.SENDING);
    setReceivingValue(0);
    if (countryId === CANADA_ID) {
      setConvertedCountryId(UNITED_STATES_ID);
    } else if (countryId === UNITED_STATES_ID) {
      setConvertedCountryId(CANADA_ID);
    }
  };

  const roundTransferRate = (newTransferRates) => {
    const rates = newTransferRates || transferRates;
    if (roundingPattern && rates.length) {
      let selectedCountry = null;
      if (convertedCountryId) {
        selectedCountry = rates.filter((rate) => rate.country_to_id == convertedCountryId)[0];
      } else selectedCountry = rates[0];
      let rate = selectedCountry.rate;
      if (
        hasInstantSpeed &&
        selectedCountry.manual_instant_rate &&
        selectedCountry.manual_instant_rate.length > 0
      ) {
        rate = selectedCountry.manual_instant_rate;
      }
      let converted = MathOperation.divide(receivingValue, rate, 4) || 0;
      converted = formatNumber(converted, { useGrouping: true });
      setSendingValue(converted || '');
      onChangeTransferAmount && onChangeTransferAmount(converted);
    }
  };

  const swapCurrencies = () => {
    setClickEvent(true);
    onChangeSendingCountryId(convertedCountryId);
    setClickEvent(false);
  };

  const startCurrencyExchange = async () => {
    await setRedirectSignupCookie(router.asPath)
    if (hasAccount) {
      router.push('/login?redirectTo=/dashboard');
    } else {
      router.push('/signup');
    }
  };

  const { t } = useTranslation('landing');

  const exchangeButton = (
    <Button
      className={classes.button}
      style={exchangeBtn}
      onClick={() => startCurrencyExchange()}
    >
      {t('exchange_now')}
    </Button>
  );

  const exchangeDetails = (
    <>
      <Grid item xs={12} sm={12}>
        <Grid item className={classes.feesContainer} style={{ marginTop: horizontal ? '4px' : null }}>
          <Typography align='center' className={classes.feesLabel}>
            Our exchange fee: <span className={classes.feesAmount}>0.00 CAD</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          className={classes.buttonContainerVertical}
          style={{ paddingTop: horizontal ? '4px' : '24px' }}
        >
          {exchangeButton}
        </div>
      </Grid>
    </>
  );

  let isSendingValueAvail = !sendingValue || sendingValue === '0.00' ? true : false;

  return <>
    <Grid
      container
      className={horizontal ? classes.horizontalCard : classes.card}
      direction={horizontal ? 'row' : 'column'}
    >
      {!loading ? (horizontal ? (
        <Grid item xs={12} sm={4}>
          <CurrencyHorizontalInput
            label={t('you_have')}
            value={sendingValue}
            selectable={true}
            editable={true}
            countriesList={transferRates}
            onValueChange={onSendingValueChange}
            onSelectChange={onChangeSendingCountryId}
            selected={originalCountryId}
            defaultCountryCode={'CA'}
            defaultCountryCurrency={'CAD'}
            eraseOnFocus={eraseOnFocus}
            onBlur={roundTransferRate}
          />
            </Grid> 
      ) : (
        <Grid>
          <ExchangeInput
            currency
            inputId="input1"
            label={t('I_have')}
            authUser={false}
            value={sendingValue}
            selectable={true}
            editable={true}
            countriesList={transferRates}
            onValueChange={onSendingValueChange}
            onSelectChange={onChangeSendingCountryId}
            selected={originalCountryId}
            defaultCountryCode={'CA'}
            defaultCountryCurrency={CANADA_CURRENCY_CODE}
            eraseOnFocus={eraseOnFocus}
            onBlur={roundTransferRate}
            autoFocus={isSendingValueAvail}
          />
        </Grid>
      )) : (
        <Grid item lg={4}>
          <Skeleton
            variant="rectangular"
            height={horizontal ? 60 : 70}
            className={horizontal ? classes.skeletonStyle1 : classes.skeletonStyle2}
          />
        </Grid>
      )}

      {!loading ? (horizontal ? (
        <Grid item xs={12} sm={1}>
          <div className={classes.iconContainer}>
            <IconButton
              size="medium"
              className={classes.icon}
              onClick={() => {
                if (!clickEvent) swapCurrencies();
              }}
            >
              <img src="/new/landing/swap-arrows.svg" alt="Swap currencies" />
            </IconButton>
          </div>
        </Grid>
      ) : (
        <Grid item>
          <div style={{ position: 'relative' }}>
            <div className={classes.dottedLine}></div>
            <div className={classes.iconContainerMobile}>
              <img
                className={classes.iconMobile}
                style={{ transform: 'rotate(90deg)' }}
                src="/img/horizontalTransfer.svg"
                alt="horizontal transfer icon"
                width="55px"
                height="55px"
                onClick={() => {
                  if (!clickEvent) swapCurrencies();
                }}
              />

              <div className={classes.rateContainerMobile}>
                <div className={classes.topRateMobile}>
                  {showSpotRate && `Spot Rate: `} 
                  {`1 ${originalCurrency} = ${parseFloat(exchangeRate).toFixed(
                    4
                  )} ${convertedCurrency}  `}
                  <FiberManualRecordIcon className={classes.dotIconMobile} />
                </div>
                <div className={classes.bottomRateMobile}>
                  {`1 ${convertedCurrency} = ${reverseRate} ${originalCurrency}`}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      )) : ( 
      <Grid item lg={1}/>
      )}

      {!loading ? (horizontal ? (
        <Grid item xs={12} sm={4}>
          <CurrencyHorizontalInput
            label={t('you_want')}
            value={receivingValue}
            selectable={true}
            editable={true}
            countriesList={transferRates}
            onValueChange={onReceivingValueChange}
            onSelectChange={onChangeReceivingCountryId}
            selected={convertedCountryId}
            defaultCountryCode={'US'}
            defaultCountryCurrency={'USD'}
            eraseOnFocus={eraseOnFocus}
          />
        </Grid>
      ) : (
        <Grid item>
          <ExchangeInput
            currency
            inputId="input2"
            label={t('I_want')}
            authUser={false}
            value={receivingValue}
            selectable={true}
            editable={true}
            countriesList={transferRates}
            onValueChange={onReceivingValueChange}
            onSelectChange={onChangeReceivingCountryId}
            showSpotRate={showSpotRate}
            selected={convertedCountryId}
            defaultCountryCode={'US'}
            defaultCountryCurrency={UNITED_STATES_CURRENCY_CODE}
            eraseOnFocus={eraseOnFocus}
          />
        </Grid>
      )) : (
        <Grid item lg={4}>
          <Skeleton
            variant="rectangular"
            height={horizontal ? 60 : 70}
            className={horizontal ? classes.skeletonStyle1 : classes.skeletonStyle2}
          />
        </Grid>
      )}

      {horizontal ? (
        <>
          <Grid item xs={12} sm={3}>
            <div className={classes.buttonContainerHorizontal}>{exchangeButton}</div>
          </Grid>
          {!loading ? <>
          <Grid item xs={12} sm={6}>
            <div className={classes.rateContainer}>
              <div className={classes.topRate}>
                {`1 ${originalCurrency} = ${exchangeRate} ${convertedCurrency}  `}
                <FiberManualRecordIcon className={classes.dotIcon} />
              </div>
              <div className={classes.bottomRate}>
                {`1 ${convertedCurrency} = ${reverseRate} ${originalCurrency}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={horizontal ? 6 : 12}>
            <div className={classes.feesContainer}>
              <Typography align={horizontal ? 'right' : 'center'} className={classes.feesLabel}>
                Our exchange fee: <span className={classes.feesAmount}>0.00 CAD</span>
              </Typography>
            </div>
          </Grid>
        </> : <Grid item className={classes.loadingSplash}></Grid>}
        </>
      ) : (
        <Grid item>{exchangeDetails}</Grid>
      )}
    </Grid>
  </>
}

const mapStateToProps = ({ exchange }) => ({
  originalCountryId: exchange?.originalCountryId,
  convertedCountryId: exchange?.convertedCountryId,
  exchangeAmount: exchange?.exchangeAmount,
  convertedAmount: exchange?.convertedAmount,
  exchangeRate: exchange?.exchangeRate,
});

const HorizontalExchangeConverterWithRedux = connect(mapStateToProps, {
  setExchangeAmount,
  setConvertedAmount,
  setOriginalCountryId,
  setConvertedCountryId,
  setExchangeRate,
})(HorizontalExchangeConverter);

export default HorizontalExchangeConverterWithRedux;
