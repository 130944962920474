import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(5)
  },
  box: {
    background: "#fff",
    borderRadius: "6px",
    border: '1px solid #CACCD5',
    height: '100%',
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'left',
    color: '#31517A',
    fontSize: '18px',
    lineHeight: '26px',
    fontFamily: 'Poppins',
    fontWeight: 600
  },
  content: {
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#626680',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: theme.spacing(2)
  },
  tag: {
    backgroundColor: '#E5EEFF',
    width: 'fit-content',
    textAlign: 'right',
    padding: theme.spacing(1, 2),
    color: '#1160F7',
    fontSize: '12px',
    fontFamily: 'Poppins',
    lineHeight: '18px',
    borderRadius: '4px',
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    position: 'relative',
    marginBottom: '-18px'
  }
});

const TagCards = ({ list, title, classes }) => {
  if (list) {
    return (
      <Grid container className={title? classes.container : null} spacing={3} justifyContent='center' direction="row" alignItems="stretch">
        {list.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs key={index}>
              {item.tag && <Box className={classes.tag}>{item.tag}</Box>}
              <div className={classes.box}>
                <Typography variant='h2' className={classes.title}>{item.title}</Typography>
                <Typography className={classes.content}>
                  <Markdown children={item.text} />
                </Typography>
              </div>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    )
  }
  else return null;
};

export default withStyles(styles)(TagCards);
