import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { body1, container, mediumTitle } from '../globalStyles';
import { parseArrFromString } from '../../../shared/utility';

const styles = theme => ({
    container,
    mediumTitle,
    body1,
    root: {
        display: 'flex',
        textAlign: 'center',
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundColor: '#F3F8FF!important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '60px',
            paddingBottom: '60px',
        },
    },
    bankLogo: {
        maxWidth: '150px',
        maxHeight: '65px',
        marginBottom: '20px'
    },
    bankLogoContainer: {
        alignItems: 'center',
        marginTop: '40px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
    },
    bankLogoContainer2: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            marginLeft: '24px',
            marginRight: '24px',
            gap: '8px',
        },
    },
    allCanadianBanks: {
        maxWidth: '100%',
        minHeight: '100px',
        overflow: 'hidden',

    },
    allCanadianBanksNew: {
        maxWidth: '100%',
        maxHeight: '96px',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            maxHeight: '64px',
            maxWidth: '64px',
        },
    },
});

function CanadianBanksSection(props) {
    const { classes, canadianBankList } = props;
    const banklist =  canadianBankList ? typeof  canadianBankList==='string' ? parseArrFromString( canadianBankList) :  canadianBankList : null;

    return (
        // <section className={classes.root}>
        //     <Grid container spacing={3} className={classes.container}>
        //         <Grid item xs={12}>
        //             <Typography variant="h2" marked="center" className={classes.mediumTitle} component="h2">
        //                 We are working with all major Canadian banks
        //             </Typography>
        //             <Typography variant="h4" marked="center" className={classes.body1} component="h4">
        //                 To provide you with the best money transfer service in Canada
        //             </Typography>
        //         </Grid>
        //         <Grid container spacing={3} className={classes.bankLogoContainer}>
        //             <Hidden mdUp>
        //                 <Grid item xs={12}>
        //                     <img
        //                         className={classes.allCanadianBanks}
        //                         src='/img/landing/small_screen_banks_1.svg'
        //                         alt="Canadian banks logos"
        //                         loading="lazy"
        //                     />
        //                 </Grid>
        //             </Hidden>
        //             <Hidden smDown>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/bmo_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="BMO bank logo"
        //                         loading="lazy"
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/rbc_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="RBC bank logo"
        //                         loading="lazy"
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/tangerine_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="Tangerine bank logo"
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/cibc_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="CIBC bank logo"
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/scotiabank_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="Scotiabank logo"
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={4} md={2}>
        //                     <img
        //                         src='/img/landing/td_logo.png'
        //                         className={classes.bankLogo}
        //                         alt="TD bank logo"
        //                     />
        //                 </Grid>
        //             </Hidden>
        //         </Grid>
        //     </Grid>
        // </section>
       <>
     {  banklist ?<Grid container spacing={3} className={classes.bankLogoContainer2}>
        {
            banklist?.map((bank) => {
                return (
                    <img
                    className={classes.allCanadianBanksNew}
                    src={bank.link}
                    alt="Canadian banks logos"
                    loading="lazy"
                />
                )
            })
        }
       </Grid>
        : <Grid container spacing={3} className={classes.bankLogoContainer}>
            <Hidden mdUp>
                <Grid item xs={12}>
                    <img
                        className={classes.allCanadianBanks}
                        src='/img/landing/canadian-banks-collage.png'
                        alt="Canadian banks logos"
                        loading="lazy"
                    />
                </Grid>
            </Hidden>
            <Hidden smDown>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/bmo_logo.png'
                        className={classes.bankLogo}
                        alt="BMO bank logo"
                        loading="lazy"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/national-bank-of-canada.png'
                        className={classes.bankLogo}
                        alt="RBC bank logo"
                        loading="lazy"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/tangerine_logo.png'
                        className={classes.bankLogo}
                        alt="Tangerine bank logo"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/cibc_logo.png'
                        className={classes.bankLogo}
                        alt="CIBC bank logo"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/scotiabank_logo.png'
                        className={classes.bankLogo}
                        alt="Scotiabank logo"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <img
                        src='/img/landing/td_logo.png'
                        className={classes.bankLogo}
                        alt="TD bank logo"
                    />
                </Grid>
            </Hidden>
        </Grid>
}
       </>
    );
}

CanadianBanksSection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CanadianBanksSection);