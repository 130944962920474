import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import { rewardsPageInfoQuery, getCodeValueQuery, competitionRakingQuery, competitionPositionQuery, sendInvitationMutation, redeemPromocodeMutation, redeemPromocodeV2Mutation} from './graphql.graphql'

export const getRewardsPageInfo = async (params) => await apiMainQuery(rewardsPageInfoQuery, params);
export const getCodeValue = async (params) => await apiMainQuery(getCodeValueQuery, params);
export const sendInvitation = async (params) => await apiMainMutation(sendInvitationMutation, params);
export const redeemPromocode = async (params) => await apiMainMutation(redeemPromocodeMutation, params);
export const getCompetitionRanking = async (params) => await apiMainMutation(competitionRakingQuery, params);
export const getCompetitionCurrentPosition = async (params) => await apiMainMutation(competitionPositionQuery, params);
export const redeemPromocodeV2 = async (params) => await apiMainMutation(redeemPromocodeV2Mutation, params);