import React from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Typography from '../../../../remitbee/components/typography/Typography';
import Markdown from 'markdown-to-jsx';
import clsx from 'clsx'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(5)
  },
  box: {
    background: "#fff",
    borderRadius: "6px",
    border: '1px solid #CACCD5',
    padding: theme.spacing(3),
    height: '100%'
  },
  title: {
    textAlign: 'center',
    color: '#31517A',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 600
  },
  content: {
    textAlign: 'center',
    color: '#626680',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: theme.spacing(2)
  },
  index: {
    marginBottom: theme.spacing(2)
  },
  textAlignLeft:{
    textAlign:'start'
  },
  customBorderRadius:{
    borderRadius:'16px'
  }
});

const NumberedCards = ({ list, classes,contentType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (list)
    return (
      <Grid container className={classes.container}>
        <Grid container item spacing={isMobile ? 3 : 5} justifyContent='center'>
          {list.map((item, index) => (
            <Grid item xs={12} md={4} sm={6} key={index}>
              <div className={clsx(classes.box,contentType === 'PerksStep' && classes.customBorderRadius)}>
                <div className={clsx(classes.index,contentType === 'PerksStep' && classes.textAlignLeft)}>
                  <img src={`/new/landing/${contentType === "PerksStep" ? "perks-steps" : "rounded-steps"}/${index + 1}.svg`} alt={`Number ${index + 1}`} />
                </div>
                <Typography variant='h3' component="div" className={clsx(classes.title,contentType === 'PerksStep' && classes.textAlignLeft)}>{item.title}</Typography>
                <Typography component="div" className={clsx(classes.content,contentType === 'PerksStep' && classes.textAlignLeft)}>
                  <Markdown children={item.text} />
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  else return null;
};

export default withStyles(styles)(NumberedCards);
