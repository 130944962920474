import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    content: { 
        position: 'relative', 
        marginBottom: '80px'
    }, 
    body: {
        zIndex: '100',
        marginRight: '100px',
        '@media (max-width: 959px)': {
            marginRight: '0px !important',
        },
    },
    indexBG: {
        zIndex: '99', 
        textAlign: 'right',
        fontSize: '234px', 
        fontWeight: 'Bold', 
        color: '#1160F7',
        opacity: '0.2',
        overflow: 'hidden',
        position: "absolute", 
        top: '0',
        right: '0'
    },
    title: {
        fontSize: '20px', 
        fontWeight: '700', 
        color:'#31517A', 
        marginBottom:'0px'
    }, 
    text: {
        fontSize: '16px', 
        lineHeight: '1.7', 
        color: '#626680', 
        whiteSpace: 'pre-line'
    }
}))

const CustomSectionItem = ({title, text, number}) => {
    const classes = useStyles();
    return (
        <Grid className={classes.content}>
            <Grid item xs={12} className={classes.body}>
            <h2 className={classes.title}>{title}</h2>               
            <div className={classes.text}>{text}</div>
            </Grid>
            <div className={classes.indexBG}>{number}</div>
        </Grid>
    )
}

export default CustomSectionItem;