import { Box, Button, ButtonGroup, Grid, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import FlagIcon from '../../../common/FlagIcon';
import { connect } from 'react-redux';
import { getTransferRateHistory } from '../../../../redux/rates/actions';
import { isInteger } from '../../../../shared/math';
import dynamic from 'next/dynamic';

const RatesGraphV2 = dynamic(
  () => import('../../../rates/ratesComponents/RatesGraphV2'),
  { ssr: false }
);

const DAY = '1D', WEEK = '1W', MONTH = '1M', TRIMESTER = '3M', SEMESTER = '6M', YEAR = '1Y';
const chartRangeOptions = [DAY, WEEK, MONTH, TRIMESTER, SEMESTER, YEAR];

let mapRangeOptions = {
    '1D': {
        from: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "hour",
        unitStepSize: 2,
    },
    "1W": {
        from: dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 1
    },
    "1M": {
        from: dayjs().subtract(1, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 7
    },
    "3M": {
        from: dayjs().subtract(3, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 10
    },
    "6M": {
        from: dayjs().subtract(6, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 1
    },
    "1Y": {
        from: dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 2
    },
}

const selectedFilterStyle = { variant: "contained", color: "primary" };
const styles = () => ({
    filterButtonStyle: { 
        border: 'none',
        marginRight: '40px',
        marginTop: '50px',
        "@media (max-width: 959px)": {
            marginRight: '10px',
            marginTop: '10px'
        }
    },
    swapIconStyle: {
        color: '#1160F7',
        // cursor: 'pointer',
        marginBottom: '5px',
        fontSize: '32px',
        "@media (max-width: 959px)": {
            fontSize: '48px',
            marginBottom: '0px'
        }
    },
    swapIconContainerStyle: {
        display: 'inline',
    },
    flagIconStyle: {
        marginLeft: '1em',
        marginRight: '1em', 
        display: 'inline',
        boxShadow: 'none'
    },
    dateTextStyle: {
        textAlign: 'right',
        marginTop: '10px',
        fontSize: '16px',
        color: '#37517A',
        "@media (max-width:959px)": {
            textAlign: 'center',
            fontSize: '12px'
        }
    },
    rateTextStyle: {
        fontSize: '30px',
        fontWeight: '500',
        color: '#37517A',
        "@media (max-width:959px)": {
            textAlign: 'center',
            fontSize: '16px',
            marginTop: '10px'
        }
    },
    paperStyle: {
        borderRadius: '0',
        boxShadow: '5px 6px 20px rgba(11, 46, 100, 0.25)',
        padding: '50px', 
        height: '100%',
        "@media (max-width:959px)": {
            paddingLeft: '0px',
            paddingRight: '0px',
            width: '100%',
            boxShadow: 'none',
        }
    },
    chartContainer: {
        paddingTop: '50px',
        "@media (max-width:959px)": {
            paddingTop: '0px'
        }
    },
    chartInfoContainer: {
        flexDirection: 'row',
        "@media (max-width:959px)": {
            flexDirection: 'column'
        }
    },
});

const ExchangeRateHistory = (props) => {
    // const [tranferRates, setTranferRates] = useState([]);
    const [ratesHistory, setRatesHistory] = useState([]);
    const [dateFilter, setDateFilter] = useState(MONTH);
    const [dateData, setDateData] = useState('2020-12-23');
    // const [dateText, setDateText] = useState('');
    const [selectedRate, setSelectedRate] = useState({});
    // const [range, setRange] = useState({});
    const [item, setItem] = useState(null);
    const { classes } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        //loadTransferRates();
        loadCurrencyRateHistory();
        getDate();
    }, []);

    useEffect(() => {
        getDate();
    }, [dateData])

    const getDate = () => {
        // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        
        // const today = new Date();
        // const totalHours = today.getHours();
        // const daytime = totalHours > 12 ? 'p.m.' : 'a.m.';
        // const hours = daytime == 'p.m.' ? totalHours - 12 : totalHours;
        // const minutes = today.getMinutes();
        // const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
        // const currentDate = months[today.getMonth()] + '. ' + today.getDate().toString() + ', ' + hours + ':' + minutesText + ' ' + daytime + ' UTC';
        
        //const currentDate = months[parseInt(dateData.substring(5, 7)) - 1] + '. ' + dateData.substring(8, 10) + ', ' + dateData.substring(0, 4);
        //setDateText(currentDate);
    }


    // const loadTransferRates = async () => {
    //     const { success, data, error } = await getTransferRates();
    //     if (success) {
    //        setTranferRates(data.TransferRatesOnline);
    //         const obj = []
    //        const filteredObj = obj && obj.filter((country) => {
    //             return country.currency_code === "USD";
    //         })
    //         if(filteredObj && filteredObj.length){
    //             setSelectedRate(filteredObj[0]);
    //             setItem(filteredObj[0])
    //         }
    //     }
    // }

    const loadCurrencyRateHistory =  async () => {
        let to = dayjs().format('YYYY-MM-DD H:mm:ss');
        let options = mapRangeOptions[dateFilter];
        let rateId = selectedRate && +selectedRate.id;
        if (isInteger(rateId)) {
            const { success, data } = await getTransferRateHistory(
                {
                    rateId,
                    dateFrom: options.from,
                    dateTo: to,
                });
            if (success) {
                setRatesHistory(data.transferRateHistory);
                // setRange({ to, ...options });
            }
        }
    }

    const onClickButtonDateFilter = (item) => {
        setDateFilter(item);
        loadCurrencyRateHistory();
    }

    const onCountrySelectedChange = (item) => {
        setItem(item);
        setSelectedRate(item);
        loadCurrencyRateHistory();
    }

    const onHoverCurrencyRateChange = (item) => {
        setSelectedRate({ ...selectedRate, ...item  });
    }

    const handleRatesLineChartMouseOut = async () => {
        if( item && item.id ) onCountrySelectedChange(item);
    }

    return (
        <Box mx={2}>
            <Paper className={classes.paperStyle}>
                <div>
                {/* https://codepen.io/italoayres/pen/bGGVqjm?editors=0110 */}
                <Grid container justifyContent="space-between" className={classes.chartInfoContainer}>
                    <div>
                        <FlagIcon
                            // code={(countrySelected || {}).iso2 || defaultCountryCode}
                            code={'CA'}
                            className={classes.flagIconStyle}
                            size={48}
                        />
                        <div className={classes.swapIconContainerStyle}>
                            <SyncAltIcon className={classes.swapIconStyle} 
                            // onClick={swapCurrencies} 
                            />
                        </div>
                        <FlagIcon
                            // code={(countrySelected || {}).iso2 || defaultCountryCode}
                            code={'US'}
                            className={classes.flagIconStyle}
                            size={48}
                        />
                    </div>
                    <div>
                        {selectedRate.rate && <Typography className={classes.rateTextStyle} variant="h1">
                            1 CAD = {parseFloat(selectedRate.rate).toFixed(2)} USD
                        </Typography>}
                        <Typography variant="subtitle1" className={classes.dateTextStyle}>{!isMobile ? `As of ${dateData}` : ''}</Typography>
                    </div>
                </Grid>
                
                <Grid>
                    <div onMouseOut={handleRatesLineChartMouseOut} className={classes.chartContainer}>
                        <RatesGraphV2 ratesHistory={ratesHistory} currencyCode={selectedRate.currency_code} onMouseHover={onHoverCurrencyRateChange} setDateData={setDateData}/>
                    </div>
                </Grid>

                <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small contained button group">
                    {
                        chartRangeOptions.map((item, index) => (
                            <Button
                                key={index}
                                onClick={() => onClickButtonDateFilter(item)}
                                className={classes.filterButtonStyle}
                                // if item is selected apply style
                                {...((item == dateFilter) && selectedFilterStyle)}
                            >
                                {item}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            </div>
        </Paper>
    </Box>
    )
}

export default connect()(withStyles(styles)((ExchangeRateHistory)));