import React, { useEffect, useState } from 'react'
import classes from './Forecast.module.scss'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Button from '../../../../../remitbee/components/button/Button'

const ForecastSection = ({
    backgroundColor,
    currency
}) => {
  const [forexInfo, setForexInfo] = useState(null);
  const [predictedCost, setPredictedCost] = useState(null);
  const { forexRates } = useSelector((state) => state.system);
  const [viewMore, setViewMore] = useState(6)

  useEffect(() => {
     if(forexRates) {
        setForexInfo({
            high: Math.max(...forexRates.High),
            low: Math.min(...forexRates.Low),
            open: Math.max(...forexRates.Open),
            currency_name: forexRates?.currency
        })
     }
  }, [forexRates]);

  useEffect(() => {
     if(forexInfo?.open) {
        setPredictedCost(predictForecastRate(forexInfo?.open))
     }
  }, [forexInfo?.open])

  const getNextSixMonths = () => {
    const nextSixMonths = [];
    const currentDate = dayjs();

    for (let i = 1; i <= viewMore; i++) {
        nextSixMonths.push(currentDate.add(i, 'month').format("MMM'YY"));
    }

    return nextSixMonths;
  }

  const predictForecastRate = (rate) => {
    const currentRate = rate;
    const inflationRate1 = 0.02;
    const inflationRate2 = 0.01;
    const forecastPeriod = 1;

    const result = currentRate * (Math.pow(((1 + inflationRate1)/(1 + inflationRate2)), forecastPeriod));

    return result.toFixed(4)

  }

  const nextSixMonths = getNextSixMonths();

  if(!forexInfo || !predictedCost) return null;

  return (
    <section style={{ backgroundColor: backgroundColor  }}>
       <Box
            flexDirection={'column'}
            className={classes['rb-root']}
        >
          <Box display='flex' flexDirection='column' gap={32}>
            <Typography variant='displayLg' weight='bold' color={theme.palette.primary.navy}>
              Forecast in detail
            </Typography>
            <Box className={classes['rb-forecast-container']}>
            {nextSixMonths.map((month,i) => (<Box key={i} className={classes['rb-forecast-card']}>
              <Typography variant='textXl' color={theme.palette.primary.navy} weight='semibold'>Forecast for {month}</Typography>
              <Typography variant='textMd'>
              {`Expected high and low CAD to ${currency} forecast is ${forexInfo?.high.toFixed(4)} - ${forexInfo?.low.toFixed(4)} ${forexInfo?.currency_name} respectively. Expected rate for ${month} is ${predictedCost} ${forexInfo?.currency_name}.`}
              </Typography>
            </Box>))}
            </Box>
            <Box className={classes['rb-forecast-button']} >
              <Button variant='text' id='view-more' onClick={() => setViewMore(viewMore === 6 ? 12 : 6)}>{viewMore === 6 ? `View more` : 'View less'}</Button>
            </Box>
          </Box>
        </Box>
    </section>
  )
}

export default ForecastSection;