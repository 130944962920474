import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import FlagIcon from './FlagIcon';
import { exchangeRatesOnline } from '../../redux/exchange/actions';
import { withStyles } from '@mui/styles';
import CountryList from '../../components/common/countrySelectionDropbox/countryList/CountryList';
import { Menu } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import ArrowIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { connect } from 'react-redux';
import { fetchCountriesRest } from '../../redux/system/actions';

const styles = () => ({
    flagIcon: { marginRight: 10 },
    input: {
        height: '52px',
        border: '1px solid #CACCD5 !important'
    },
    selectIcon: {
        color: '#626680'
    },
    menulist: {
        padding: '0'
    },
    countryCode: {
        fontFamily: 'Inter',
        color: '#626680',
        fontSize: '16px',
        lineHeight: '24px'
    },
    paper: {
        borderRadius: 5,
        border: '1px solid #CACCD5'
    }
});

const CurrencyListInput = ({ classes, onChange, isCurrencyExchange, source, listTextOptions, value, countries, fetchCountries }) => {
    const [selected, setSelected] = useState(typeof value === 'object' ? value : null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [countriesCurrencyList, setCountriesCurrencyList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [defaultFlagIcon, setDefaultFlagIcon] = useState(null);
    const [showText, setShowText] = useState(null);


    useEffect(() => {
        if (!isCurrencyExchange) loadTransferRates();
        else loadExchangeRates();
    }, [countries])

    useEffect(() => {
        if (typeof value === 'object') setSelected(value);
    }, [value]);

    useEffect(() => {
        setFilteredItems(countriesCurrencyList);
    }, [countriesCurrencyList]);

    useEffect(() => {
        if (selected) {
            setShowText(() => (
                <><b>{selected.currency_code}</b> - {selected.currency_name}</>
            ));
            setDefaultFlagIcon(() => (
                <FlagIcon
                    code={selected.iso2 || 'CA'}
                    className={classes.flagIcon}
                />
            ));
        }
    }, [selected]);

    const loadTransferRates = async () => {
        if (countries) {
            setCountriesCurrencyList(countries);
            if (typeof value === 'string') onItemClick(countries.find(item => (item.country_to_id === +value || item.country_id === +value)));
        }
        else await fetchCountries();
    }

    const loadExchangeRates = async () => {
        const { success, data } = await exchangeRatesOnline();
        if (success) {
            setCountriesCurrencyList(data.ExchangeRatesOnline);
            if (typeof value === 'string') onItemClick(data.ExchangeRatesOnline.find(item => item.country_from_id === +value));
        }
    }

    const handleMenu = (event) => {
        const target = event.currentTarget;
        setTimeout(() => {
          setAnchorEl(anchorEl ? null : target);
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        onChange(item);
        setSelected(item);
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={handleMenu}
                disableRipple
                disabled={countriesCurrencyList && countriesCurrencyList.length === 0}
                classes={{ root: classes.input, disabled: classes.input }}
                fullWidth>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={11} container direction="row" justifyContent="flex-start" alignItems="center">
                        {defaultFlagIcon}
                        <Typography variant="h3" className={classes.countryCode}>
                            {showText}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end" alignItems="center">
                        {!isCurrencyExchange && !source && <ArrowIcon className={classes.selectIcon} />}
                    </Grid>
                </Grid>

            </Button>

            {countriesCurrencyList && countriesCurrencyList.length > 0 && <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                MenuListProps={{
                    className: classes.menulist
                }}
                value={selected}
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ elevation: 4 }}
                classes={{ paper: classes.paper }}
                onClose={handleClose}>
                <CountryList
                    countriesList={countriesCurrencyList}
                    filteredItems={filteredItems}
                    setFilteredItems={setFilteredItems}
                    onItemClick={onItemClick}
                    hasFlagIcon={true}
                    options={listTextOptions}
                    searchTextOptions={[
                        'currency_code',
                        'currency_name',
                        'country_to',
                    ]}
                />
            </Menu>}
        </>
    )
}

CurrencyListInput.defaultProps = {
    listTextOptions: ['currency_code', 'currency_name'],
}

const mapStateToProps = ({ system }) => ({
    countries: system.countries,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCountries: () => dispatch(fetchCountriesRest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CurrencyListInput));
