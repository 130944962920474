import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
// import https from 'https';
import { bool, func, number, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { stringToFloat } from '../../../shared/math';
// import { formatNumber, MathOperation } from '../../../shared/math';
import CircleIcon from '../Icons/IncompleteStepIcon';
import CurrencyInput from './CurrencyInput';
import { getTransferRatesMulticurrency } from '../../../redux/rates/actions';
import { fetchCountriesMulticurrencyRest } from '../../../redux/system/actions';
import { calcMoneyTransferAmountOnlineSession } from '../../../redux/rates/session/actions';
import { CustomSnackbarContent } from '../Alerts';
import { getClientHasAccountCookie, saveSelectedCountry, getSelectedCountry, getSelectedCurrency } from '../../../shared/cookie-handler';
import browserLogger from '../../../settings/browser-logger';

const DEFAULT_COUNTRY = 103;
const DEFAULT_CURRENCY = 'INR';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'inline',
    textAlign: 'left!important'
  },
  skeleton: {
    width: '100%',
    display: 'inline'
  },
  dottedLine: {
    position: 'relative',
    top: '0px',
    left: '40px',
    width: '0px',
    height: '120px',
    border: '#105CED',
    borderStyle: 'dashed',
    borderWidth: '0 2px 0 0',
    opacity: '1',
  },
  rate: {
    position: 'absolute',
    marginTop: '-70px',
    marginLeft: '60px',
    textAlign: 'left',
    display: 'inline',
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
  rateLabel: {
    display: 'inline'
  },
  rateAmount: {
    fontSize: '16px',
    color: '#626680',
    display: 'inline',
  },
  rateAmountSpecial: {
    color: '#008000',
    fontWeight: 700,
  },
  circleIcon: {
    position: 'absolute',
    marginTop: '-67px',
    marginLeft: '29px',
    height: '18px',
  },
  dotIcon: {
    color: theme.palette.secondary.main,
    fontSize: 'small',
    textAlign: 'right',
    alignSelf: 'flex-start',
    marginLeft: theme.spacing(1)
  },
}));

const fields = {
  SENDING: 'SENDING',
  RECEIVING: 'RECEIVING',
};

export function ExchangeInput({
  value,
  onChangeTransferAmount,
  onChangeTransferRate,
  onChangeReceivingAmount,
  onChangeDestinationCountryCurrencyCode,
  countryIdSelected,
  selectedCurrencyId,
  eraseOnFocus,
  hasInstantSpeed,
  onChangeRateId,
  // roundingPattern,
  activeCountries,
  autoFocus,
  precision = 2,
  selectable = true,
  defaultCurrency,
  showSpotRate,
  ratesPage,
  onChangePaymentTypes,
  onChangeAdditionalInfo,
  beneficiaryPaymentInfoId, // Required for rate calculations
  unavailable,
  setFlowLoading,
  business_unique_id,
  isPromoRate,
  setExchangeRates,
  setSpecialRateType,
  setSpecialRateUpdate,
  authUser,
  list,
  isSecure,
  onChangeReceivingCountry
}) {
  const classes = useStyles();
  const [transferRates, setTransferRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentRateText, setCurrentRateText] = useState('');
  const [specialRateText, setSpecialRateText] = useState(null);
  const [sendingValue, setSendingValue] = useState('');
  const [receivingValue, setReceivingValue] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState(countryIdSelected || selectedCurrencyId);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency || DEFAULT_CURRENCY);
  const [lastFieldChanged, setLastFieldChanged] = useState(fields.SENDING);
  const [sendingAmountLoading, setSendingAmountLoading] = useState(false);
  const [receivingAmountLoading, setReceivingAmountLoading] = useState(false);
  const [specialRate, setSpecialRate] = useState(null);
  const [initialValue] = useState(value !== '' ? value : null);
  const [newBeneficiaryPaymentInfoId, setNewBeneficiaryPaymentInfoId] = useState();
  // const { countries } = useSelector((state) => state.system);
  //const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (value && countryIdSelected === DEFAULT_COUNTRY) {
      setSelectCountry()
    }
    if (isSecure) loadTransferRates();
    else if (list) {
      setTransferRates(list);
    } else {
      loadTransferRatesRest();
    }
  }, []);

  // Required for Beneficiary payment method changes
  useEffect(() => {
    if (beneficiaryPaymentInfoId !== newBeneficiaryPaymentInfoId) {
      setNewBeneficiaryPaymentInfoId(beneficiaryPaymentInfoId);
    }
  }, [beneficiaryPaymentInfoId])

  useEffect(() => {
    setSelectedCurrency(defaultCurrency);
  }, [defaultCurrency]);

  useEffect(() => {
    if (value) onSendingValueChange(value, true);
  }, [value]);

  useEffect(() => {
    if (lastFieldChanged === fields.SENDING) setReceivingAmountLoading(true);
    if (lastFieldChanged === fields.RECEIVING) setSendingAmountLoading(true);

    return () => {
      setSendingAmountLoading(false);
      setReceivingAmountLoading(false);
    }
  }, [lastFieldChanged, value])

  useEffect(() => {
    if (selectedCountryId) onChangeReceivingCountry && onChangeReceivingCountry(+selectedCountryId);
    const isSpecialRateChanged = () => {
      if (!specialRateText && specialRate) return true

      if (specialRateText && specialRateText.specialRate != specialRate) return true

      return false;
    }
    // make a request after 1 second since there's no typing 
    const requestTimerId = setTimeout(() => {
      if (isSpecialRateChanged() || sendingValue !== initialValue || receivingValue === '' || newBeneficiaryPaymentInfoId) calculateConversion();
    }, 1000);
    return () => clearTimeout(requestTimerId);
  }, [sendingValue, receivingValue, selectedCountryId, selectedCurrency, transferRates, hasInstantSpeed, isPromoRate, specialRate, newBeneficiaryPaymentInfoId]);

  const loadTransferRatesRest = async () => {
    try {
      const rates = await fetchCountriesMulticurrencyRest();
      if (rates) {
        setTransferRates(rates);
        setLoading(false);

        const selectedCountryRate = rates.find(r => r.country_id === countryIdSelected);

        // if ((!defaultCurrency || defaultCurrency === '') && selectedCountryRate) setSelectedCurrency(selectedCountryRate.currency_code);

        if (selectedCountryRate && +selectedCountryRate.special_rate) {
          setSpecialRate({
            special_rate: selectedCountryRate.special_rate,
            special_rate_adjustment: selectedCountryRate.special_rate_adjustment,
            special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit,
          });
        }
      }
      setFlowLoading && setFlowLoading(false);
    } catch (err) {
      browserLogger.error(err & err.message);
    }
  }

  const loadTransferRates = async () => {
    try {
      setFlowLoading && setFlowLoading(true);
      let rates = [];
      const { data } = await getTransferRatesMulticurrency();
      if (data) rates = data.TransferRatesOnlineV2;
      setLoading(false);
      if (Array.isArray(rates) && rates.length) {
        if (activeCountries) {
          rates = rates.filter(country => activeCountries.includes(country.country_id || country.country_to_id))
        }
        setTransferRates(rates);

        const selectedCountryRate = rates.find(r => r.country_id === countryIdSelected);

        if (selectedCountryRate && +selectedCountryRate.special_rate) {
          setSpecialRate({
            special_rate: selectedCountryRate.special_rate,
            special_rate_adjustment: selectedCountryRate.special_rate_adjustment,
            special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit,
          });
        }
      }
      setFlowLoading && setFlowLoading(false);
    } catch (err) {
      browserLogger.error(err & err.message);
    }
  };

  const setSelectCountry = async () => {
    try {
      setFlowLoading && setFlowLoading(true);
      const selectedCountryIdFromCookie = parseInt(await getSelectedCountry());
      const selectedCurrencyCodeFromCookie = await getSelectedCurrency();
      if (selectedCountryIdFromCookie && selectedCurrencyCodeFromCookie && selectedCurrencyCodeFromCookie.toString() !== 'undefined') {
        setSelectedCountryId(selectedCountryIdFromCookie);
        setSelectedCurrency(selectedCurrencyCodeFromCookie);
      }
    } catch (err) {
      browserLogger.error(err & err.message);
    }
  };


  const calculateConversion = async () => {
    try {
      setFlowLoading && setFlowLoading(true);
      const transferAmount = lastFieldChanged === fields.SENDING ? sendingValue : null;
      const receivingAmount = lastFieldChanged !== fields.SENDING ? receivingValue : null;
      const _args = {};
      if (transferAmount) _args.transfer_amount = String(transferAmount);
      if (receivingAmount) _args.receiving_amount = String(receivingAmount);
      if (selectedCurrency) _args.currency_code = String(selectedCurrency);
      if (hasInstantSpeed) _args.is_instant_rate = hasInstantSpeed;
      if (showSpotRate) _args.is_spot_rate = Boolean(showSpotRate);
      if (beneficiaryPaymentInfoId) _args.beneficiary_payment_info_id = beneficiaryPaymentInfoId;

      if (specialRate) _args.is_special_rate = true;
      if (isPromoRate) _args.is_promo_rate = isPromoRate;
      _args.business_unique_id = business_unique_id;
      _args.is_existing_user = Boolean(await getClientHasAccountCookie());
      _args.country_id = selectedCountryId && typeof selectedCountryId !== 'object' ? +selectedCountryId : DEFAULT_COUNTRY;
      if (value && countryIdSelected === DEFAULT_COUNTRY) {
        saveSelectedCountry('selectedCountryId', _args.country_id)
        saveSelectedCountry('selectedCurrencyCode', _args.currency_code)
      }
      if (!authUser) _args.include_timeline = true;
      const { success, data } = await calcMoneyTransferAmountOnlineSession(_args);
      if (success) {
        const { CalcMoneyTransferAmountOnlineV2: { special_rate_type, currency_code, rate, receiving_amount, transfer_amount, payment_types, payment_additional_info, special_rate, special_rate_adjustment, special_rate_transfer_amount_limit, spot_rate } } = data;
        if (payment_types && onChangePaymentTypes) onChangePaymentTypes(payment_types);
        setSpecialRateType && setSpecialRateType(special_rate_type)
        isPromoRate && setExchangeRates({
          promoRate: special_rate,
          normalRate: rate,
        })
        setSpecialRateUpdate && setSpecialRateUpdate(
          +special_rate
            ? {
              special_rate: special_rate,
              special_rate_adjustment: special_rate_adjustment,
              special_rate_transfer_amount_limit: special_rate_transfer_amount_limit,
            }
            : null
        );
        if (payment_additional_info && onChangeAdditionalInfo) onChangeAdditionalInfo(payment_additional_info);
        if (special_rate && special_rate_type) {
          let normalText = `Exchange rate: 1.00 CAD → `;
          let strikeThroughText = rate;
          let boldText = `${special_rate} ${currency_code}`;
          setSpecialRateText({
            special_rate,
            normalText,
            strikeThroughText,
            boldText,
          })
          setCurrentRateText('');
        } else {
          let rateText = `Exchange rate: 1.00 CAD → ${rate} ${currency_code}`;
          if (ratesPage) rateText = `1.00 CAD → ${rate} ${currency_code}`
          if (showSpotRate) rateText = `Spot rate: 1.00 CAD → ${parseFloat(spot_rate).toFixed(4)} ${currency_code}`;
          setCurrentRateText(rateText);
          setSpecialRateText(null);
        }

        if (stringToFloat(transfer_amount)) {
          if (lastFieldChanged === fields.SENDING && stringToFloat(transferAmount)) {
            setReceivingValue(receiving_amount || '0.00');
            onChangeReceivingAmount && onChangeReceivingAmount(receiving_amount);
          } else if (stringToFloat(receivingAmount)) {
            setSendingValue(transfer_amount || '0.00');
            onChangeTransferAmount && onChangeTransferAmount(transfer_amount)
          } else {
            setSendingValue('');
            setReceivingValue('');
          }
        }

        onChangeTransferRate && onChangeTransferRate(rate, { special_rate, special_rate_transfer_amount_limit });
        onChangeRateId && onChangeRateId(_args.ben_currency);
        onChangeDestinationCountryCurrencyCode &&
          onChangeDestinationCountryCurrencyCode(currency_code);
        if (!selectedCountryId) setSelectedCountryId(_args.ben_currency);
      } else {
        // This resets the sending and receving amount if the API call fails
        setSendingValue('');
        setReceivingValue('');
        onChangeReceivingAmount && onChangeReceivingAmount('');
        onChangeTransferAmount && onChangeTransferAmount('')
      }

      setSendingAmountLoading(false);
      setReceivingAmountLoading(false);

      setFlowLoading && setFlowLoading(false);
    } catch (err) {
      browserLogger.error(err & err.message);
    }
  };

  const onSendingValueChange = (value, ignoreLastField) => {
    setReceivingAmountLoading(true)

    // loader is set immediately after input changes, so that the continue button is disabled instantly.
    setFlowLoading && setFlowLoading(true);

    if (!ignoreLastField) setLastFieldChanged(fields.SENDING);
    setSendingValue(value);
    onChangeTransferAmount && onChangeTransferAmount(value);
    setReceivingAmountLoading(false)
  };

  const onReceivingValueChange = (value) => {
    setSendingAmountLoading(true)

    // loader is set immediately after input changes, so that the continue button is disabled instantly.
    setFlowLoading && setFlowLoading(true);

    setLastFieldChanged(fields.RECEIVING);
    setSendingValue('');
    setSendingAmountLoading(true);
    setReceivingValue(value);
    onChangeReceivingAmount && onChangeReceivingAmount(value);
    setSendingAmountLoading(false)
  };

  const onChangeCountry = (country) => {
    setSelectedCurrency(country.currency_code);
    if (lastFieldChanged === fields.SENDING) {
      setReceivingValue('');
      setReceivingAmountLoading(true);
    } else {
      setSendingValue('');
      setSendingAmountLoading(true);
    }
    setSelectedCountryId(country.country_id || country.country_to_id);
    setSpecialRate(
      +country.special_rate
        ? {
          special_rate: country.special_rate,
          special_rate_adjustment: country.special_rate_adjustment,
          special_rate_transfer_amount_limit: country.special_rate_transfer_amount_limit,
        }
        : null
    );
  };

  // const roundTransferRate = (e, newTransferRates) => {
  //   const rates = newTransferRates || transferRates;
  //   if (roundingPattern && rates.length) {
  //     let selectedCountry = null;

  //     if (selectedCountryId) {
  //       selectedCountry = rates.find((rate) => rate.country_id == selectedCountryId || rate.country_to_id == selectedCountryId);
  //     } else selectedCountry = rates[0];
  //     let rate = selectedCountry.rate;
  //     if (
  //       hasInstantSpeed &&
  //       selectedCountry.manual_instant_rate &&
  //       selectedCountry.manual_instant_rate.length > 0
  //     ) {
  //       rate = selectedCountry.manual_instant_rate;
  //     }
  //     let converted = MathOperation.divide(receivingValue, rate, precision === 2 ? 4 : precision) || 0;
  //     converted = formatNumber(converted, { useGrouping: true });
  //     setSendingValue(converted || '0.00');
  //     onChangeTransferAmount && onChangeTransferAmount(converted);
  //   }
  // }

  const { t } = useTranslation(authUser ? 'send-money' : 'landing');

  return !loading ? (
    <div className={classes.root}>
      {unavailable && (
        <>
          <CustomSnackbarContent
            variant='error'
            closeable={false}
            message={`We’ve temporarily stopped all transfers to ${unavailable}`} />
          <Box pb={3} />
        </>
      )}
      <CurrencyInput
        label={t('transfer_details.you_send')}
        value={sendingValue}
        selectable={false}
        editable={true}
        onValueChange={onSendingValueChange}
        defaultCountryCode={'CA'}
        defaultCountryCurrency={'CAD'}
        eraseOnFocus={eraseOnFocus}
        // onBlur={roundTransferRate}
        inputId={"input1"}
        autoFocus={autoFocus}
        // precision={precision}
        loading={sendingAmountLoading}
      />
      <div className={classes.dottedLine} />
      <div style={{ position: 'relative' }}>
        <CircleIcon className={classes.circleIcon} />
        <div className={classes.rate}>
          {specialRateText ? (
            <div className={classes.rateAmount}>
              <span>{specialRateText.normalText}</span>
              <strike> {specialRateText.strikeThroughText}</strike>
              <span className={classes.rateAmountSpecial}> {specialRateText.boldText}</span>
            </div>
          ) : (
            <div className={classes.rateAmount}>{currentRateText}</div>
          )}
        </div>
      </div>

      <CurrencyInput
        label={t('transfer_details.they_receive')}
        value={receivingValue}
        editable={true}
        countriesList={transferRates}
        selectable={selectable}
        selectedCurrency={selectedCurrency}
        onValueChange={onReceivingValueChange}
        defaultCountryCode={'IN'}
        defaultCountryCurrency={'INR'}
        selected={selectedCountryId}
        onSelectChange={onChangeCountry}
        eraseOnFocus={eraseOnFocus}
        inputId={"input2"}
        autoFocus={autoFocus}
        precision={precision}
        loading={receivingAmountLoading}
      />
    </div>
  ) : (
    <div className={classes.skeleton}>
      <Skeleton variant="rectangular" width="100%" height={69} />
      <div className={classes.dottedLine} />
      <div style={{ position: 'relative' }}>
        <CircleIcon className={classes.circleIcon} />
        <div className={classes.rate}>
          <Skeleton variant="rectangular" width={140} height={23} />
        </div>
      </div>
      <Skeleton variant="rectangular" width="100%" height={69} />
    </div>
  );
}

ExchangeInput.propTypes = {
  onChangeTransferAmount: func,
  onChangeTransferRate: func,
  onChangeReceivingAmount: func,
  onChangeDestinationCountryCurrencyCode: func,
  value: oneOfType([string, number]),
  sendingCountrySelectable: bool,
  receivingCountrySelectable: bool,
  authUser: bool,
  countryIdSelected: oneOfType([string, number]),
  selectedCurrencyId: number,
  setFlowLoading: func
};

ExchangeInput.defaultProps = {
  value: '0',
  authUser: 'true',
  receivingCountrySelectable: true,
  countryIdSelected: DEFAULT_COUNTRY
};

export default ExchangeInput;
