import { ClickAwayListener, Fade, FormControl, InputLabel, Popper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import FlagIcon from '../../../../common/FlagIcon';
import CountrySelectionDropdown from '../../../../common/countrySelectionDropbox/CountrySelectionDropdown';
import NumberFormat from '../../../../common/NumberFormat';
import theme from '../../../../../remitbee/theme/Theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #CACCD5',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '70px',
    boxShadow: 'none',
  },
  formControl: {
    marginLeft: theme.spacing(2),
    flex: 3,
  },
  input: {
    maxHeight: '70px',
    minHeight: '55px',
    display: 'flex',
    flex: 1,
    color: theme.palette.input.heading,
    '& input': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      fontSize: '22px',
      lineHeight: '120%',
      fontWeight: 400,
      height: '26px',
      padding: 0,
      letterSpacing: '-0.44px'
    },
    borderColor: '#CACCD5'
  },
  inputLabelShrink: {
    fontSize: '15px',
    marginTop: '6px',
  },
  currency: {
    fontSize: '16px',
    color: '#fff'
  },
  iconButton: {
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: '#1160F7',
    color: 'white',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    minWidth: '120px',
    '&:hover': {
      backgroundColor: '#1160F7',
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconContentStyle: {
    marginRight: '0.4em',
  },
  labelContentStyle: {
    marginTop: '-0.45em',
    marginRight: '0.6em',
  },
  paper: {
    borderRadius: 5,
    border: '1px solid #CACCD5'
  }
}));

export function CurrencyInput({
  countriesList,
  defaultCountryCurrency,
  defaultCountryCode,
  selectable,
  selected,
  onSelectChange,
  editable,
  value,
  onValueChange,
  inputId,
  label,
  eraseOnFocus,
  onBlur,
  currency,
  autoFocus,
}) {
  const classes = useStyles();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [touched, setTouched] = useState(false);

  const handleMenuItemClick = (countryId) => {
    onSelectChange(countryId);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleChange = (changedValue) => {
    if (!touched) setTouched(true);
    onValueChange(changedValue);
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const countrySelected = selected ? countriesList.filter((country) => country.country_from_id == selected)[0] : null;

  return (
    <div>
      <Paper className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={inputId} classes={{ shrink: classes.inputLabelShrink }}>
            {label}
          </InputLabel>
          <InputBase
            aria-label="Amount"
            id={inputId}
            className={classes.input}
            value={value}
            readOnly={editable ? false : true}
            inputProps={{ decimalScale: 2, thousandSeparator: true }}
            inputComponent={NumberFormat}
            onFocus={() => !touched && eraseOnFocus && handleChange('')}
            onChange={(e) => (editable ? handleChange(e.target.value) : null)}
            onBlur={onBlur}
            autoFocus={autoFocus}
          ></InputBase>
        </FormControl>
        
        <Button
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={selectable ? handleClick : null}
          disableRipple={true}
        >
          <FlagIcon
            code={(countrySelected || {}).iso2 || defaultCountryCode}
            className={classes.iconContentStyle}
          />

          <Typography className={classes.currency}>
            {(countrySelected || {}).currency_code || defaultCountryCurrency}
          </Typography>
          {selectable && Boolean(anchorEl) !== true && <ExpandMore />}
          {Boolean(anchorEl) && <ExpandLess />}
        </Button>
      </Paper>
      {selectable && (
        <Popper
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
          }}
          disablePortal={true}
          placement="bottom-end"
          style={{ zIndex: 1300 }}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={200}>
              <Paper elevation={4} className={classes.paper}>
                {countriesList && (
                  <CountrySelectionDropdown
                    currency={currency}
                    onChange={({ country_from_id }) => handleMenuItemClick(country_from_id)}
                    isDropdown={false}
                    countriesList={countriesList}
                    listTextOptions={['country_from', 'currency_code']}
                    searchTextOptions={[
                      'currency_code',
                      'currency_name',
                      'country_from',
                      'country_name',
                    ]}
                  />
                )}
              </Paper>
            </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </div>
  );
}

export default CurrencyInput;
