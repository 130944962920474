import gql from "graphql-tag";

export const SEARCH_HELP_QUERY = gql`  
query GetHelpCentreArticles(
  $search: String!
) {
  search(query: $search) {
    helpCentreArticles(
      filters: {
        publishedAt: { notNull: true },
        help_centre_group: { name: {notNull: true} ,help_centre_category: { slug: {notNull: true}}}
      }
      pagination: {
        start: 0
        limit: 200
      }
    ) {
      data {
        id
        attributes {
          title
          slug
          meta_description
          help_centre_group {
              data {
                attributes {
                  name
                  help_centre_category {
                    data {
                      attributes {
                        title
                        slug
                      }
                    }
                  }
                }
              }
          }
        }
      }
    }
  }
  }

`;

export const SEARCH_HELP_QUERY_WITH_GROUP = gql`
  query helpArticles($search: String!){
    helpCentreArticles(
      filters: {
        publishedAt: { notNull: true },
        help_centre_group: { 
          and: [
            { publishedAt: { notNull: true } }, 
            { help_centre_category: { publishedAt: { notNull: true } } }
          ]
        },
        or: [
          { title: { contains: $search } },
          { content: { contains: $search } }
        ],
      },
      pagination: {
          start: 0,
          limit: 200
      },
      sort: ["publishedAt:desc"]
  ) {
        data {
          attributes {
            title
            content
            slug
            meta_description
            createdAt
            updatedAt
            help_centre_group {
              data {
                attributes {
                  name
                  help_centre_category {
                    data {
                      attributes {
                        title
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
  }
`; 

// Non-Fuzzy Search Query

// export const SEARCH_HELP_QUERY = gql`  
//   query helpArticles($search: String!){
//     helpCentreArticles(
//       filters: {
//         publishedAt: { notNull: true },
//         help_centre_group: { name: { notNull: true }, help_centre_category: { title: { notNull: true }} },
//         or: [
//           { title: { contains: $search } },
//           { content: { contains: $search } }
//         ],
//       },
//       pagination: {
//           start: 0,
//           limit: 200
//       }
//   ) {
//         data {
//           attributes {
//             title
//             content
//             slug
//             meta_description
//             help_centre_group {
//               data {
//                 attributes {
//                   help_centre_category {
//                     data {
//                       attributes {
//                         slug
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//       }
//     }
//   }
// `;
