import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { container } from '../globalStyles';

const styles = () => ({
    container,
    root: {
        // flexGrow: 1,
        width: '100%'
    },
    reasonBox: {
        maxWidth: '300px',
        height: '380px',
        borderRadius: "10px",
        padding: "50px 30px !important",
        boxShadow: "5px 6px 20px rgba(11, 46, 100, 0.25)",
        margin: 'auto',
        "@media (max-width:959px)": {
            marginTop: '50px'
        }
    },
    image: {
        maxWidth: '200px',
        maxHeight: '100px',
    },
    subtitle: {
        marginTop: '30px',
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '35px',
        color: '#31517A'
    },
    text: {
        marginTop: '30px',
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '23px',
        color: '#626680'
    }
});

function CurrencyExchange(props) {
    const { classes } = props;
    return (
        <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/person.svg'
                    className={classes.image}
                    alt="person"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>Sign up</Typography>
                <Typography className={classes.text}>Create your free account on our app or by clicking here.</Typography>        
            </Grid>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/shield_check.svg'
                    className={classes.image}
                    alt="shield check"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>Verify your details</Typography>
                <Typography className={classes.text}>Tell us a bit about yourself for identity protection</Typography>
            </Grid>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/money_arrows.svg'
                    className={classes.image}
                    alt="money arrows"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>Begin Exchanging money</Typography>
                <Typography className={classes.text}>Start exchanging money fast for cheap!</Typography>
            </Grid>
        </Grid>
    );
}

CurrencyExchange.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrencyExchange);