import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';
import ProsCons from './ProsCons.js';

const useStyles = makeStyles(theme => ({
    container,
    mediumTitle,
    body1,
    bigTitle: {
        ...bigTitle,
        textAlign: 'left'
    },
    root: {
        display: 'flex',
        paddingTop: '120px',
        paddingBottom: '135px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '80px',
            paddingBottom: '60px',
            clipPath: 'none',
        },
    },
    stacked: {
        display: 'flex',
        paddingTop: '40px',
        paddingBottom: '50px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
            paddingBottom: '20px',
            clipPath: 'none',
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    title_h2: {
        fontSize: '30px !important'
    },
    title_h1: {
        fontSize: '40px !important',
        "@media (max-width: 959px)": {
            fontSize: '25px !important'
            
        },
    },
    image: {
        maxWidth: '100%',
    },
    widthset: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
          },
    },
    text: {
        fontSize: '16px',
        opacity: '0.85',
        lineHeight: '1.6',
    },
    grey: {
        color: '#31517A !important'
    },
    white: {
        color: '#FFFFFF !important'
    },
}));


const ProConList = ({title, pro, con, proList, conList}) => {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Grid item xs={12} className={classes.widthset}>
                <Typography variant="h2" className={classnames(classes.bigTitle, classes[`title_h2`]/* , classes[color] */, /* !alignLeft && classes.textCenter */)}>
                    {title}
                </Typography>
                {pro && <ProsCons 
                    listItems={proList}
                    type={'pros'}>
                </ProsCons>}
                {con && <ProsCons 
                    listItems={conList}
                    type={'cons'}>
                </ProsCons>}
            </Grid>
        </Container>
        
    )
}

ProConList.propTypes = {
    title: PropTypes.string,
    pro: PropTypes.bool,
    con: PropTypes.bool,
};
export default ProConList;