import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Accordion from '../../../common/Accordion';
import ExchangeInputBox from '../ExchangeInputBox/ExchangeInputBox';
import HorizontalCurrencyConverter from '../horizontalCurrencyConverter/HorizontalCurrencyConverter';
import styles from './styles';
import { activeCountries, exchangeRates, feeRates, getTableBodyStyle, getTableBottomStyle, labelRows, mobileRows, spotRateMapping, tableRows, transferMobileRows, transferRates } from './table-data';


const useStyles = makeStyles(styles);

const ExchangeRateCompare = ({hideConverter, countrySending, compareTransferRates, data_input}) => {
    const [sendingValue, setSendingValue] = useState();
    const [transferRateDraft, setTransferRate] = useState(); // eslint-disable-line no-unused-vars
    const [recievingCountry, setReceivingCountry] = useState();
    const [inputValue, setInputValue] = useState(1000.00);
    
    useEffect(() => {
        setInputValue(parseFloat(data_input && data_input.replace(/,/g, '')));
    },[data_input]);

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

    const renderTableCell = (type, label, cellStyle) => {
        return (
            type === 'icon' ?
                <img
                    className={classes.cellContent}
                    width={label === 'blank' ? '10px' : '25px'}
                    src={`/icons/other/${label}.svg`}
                    alt={`${label} icon`}
                /> :
                <span className={classes.cellContent} style={cellStyle}>{label}</span>
        )
    }

    const renderTrueValueCaption = () => {
        return (
            compareTransferRates ? 
            (<Typography style={{ fontWeight: '500' }} className={classes.captionStyle} >
            <Tooltip title={`True Cost is the amount it will actually cost you in CAD to send the amount of money you wish to send. This includes all fee's, and delivery prices`}><span className={classes.valueLink}>True cost</span></Tooltip> of transfer
            </Typography>) 
            :
            (<div>
                <Typography style={{ fontWeight: '500'}} className={classes.captionStyle} >
                    <Tooltip title={`True Cost is all of the expenses involved in your currency exchange. Most providers charge a margin on the exchange rate, True Cost includes this charge.`}><span className={classes.valueLink}>True cost</span></Tooltip> {`of currency exchange for ${inputValue}`}{countrySending == 40 ? ' CAD' : ' USD' }
                </Typography>
                <Typography style={{fontSize:'12px', fontWeight:'400'}}>
                    *Per data collected on Jan 15th 2021*
                </Typography>
            </div>)
            
        )
    }


    const trueCost = (provider) => {
        const sendingValueInt = data_input ? inputValue : parseFloat(sendingValue && sendingValue.replace(/,/g, ''));
        let exchangeRate = exchangeRates.find(exchangeRate => exchangeRate.provider === provider).sellRate;
        const spotRate = spotRateMapping[countrySending];

        if(countrySending === 236){
            exchangeRate = exchangeRates.find(exchangeRate => exchangeRate.provider === provider).buyRate;
            if(exchangeRate && sendingValueInt){
                const trueCost = (spotRate*sendingValueInt) - (exchangeRate*sendingValueInt);
                return `$ ${trueCost.toFixed(2)}`;
            }
        }
        if(exchangeRate && sendingValueInt){
            const trueCost = (exchangeRate*sendingValueInt) - (spotRate*sendingValueInt);
            return `$ ${trueCost.toFixed(2)}`;
        }
        return '-';
    }

    const calculateTrueCost = (provider) => {
        const sendingValueInt = parseFloat(sendingValue && sendingValue.replace(/,/g, ''));
        const transferRate = transferRates[recievingCountry] && transferRates[recievingCountry].find(transferRate => transferRate.provider === provider).value;
        const spotRate = spotRateMapping[recievingCountry];

        if (transferRate && sendingValueInt) {
            const fxSpread = Math.abs(spotRate - transferRate);
            const offset = (fxSpread / spotRate) * sendingValueInt;
            let feeRate = feeRates[recievingCountry][provider].find(feeRate => sendingValueInt <= feeRate.rate);
            if (!feeRate) {
                const length = feeRates[recievingCountry][provider].length;
                feeRate = feeRates[recievingCountry][provider][length - 1];
            }
            const trueCost = offset + feeRate.fee;
            return `$ ${trueCost.toFixed(2)}`;
        }
        return '-'
    }

    return (
        <>
            {isMobile ?
                !hideConverter && <ExchangeInputBox
                    activeCountries ={activeCountries}
                    onChangeTransferAmount={setSendingValue}
                    onChangeTransferRate={setTransferRate}
                    onChangeReceivingCountry={setReceivingCountry}
                />
                : !hideConverter && <HorizontalCurrencyConverter
                    activeCountries ={activeCountries}
                    value={'1000'}
                    onChangeTransferAmount={setSendingValue}
                    onChangeTransferRate={setTransferRate}
                    onChangeReceivingCountry={setReceivingCountry}
                />
            }

            {isMobile ? (compareTransferRates ? 
            ( <div className={classes.accordion}>
                {transferMobileRows.map((row, index) => <Accordion
                    className={classes.listBoxes}
                    key={`table-mobile-row-${index}`}
                    toogle={true}
                    heading={
                        <>
                            <img src={row.labelPath} height="25px" width="90px" alt="label path icon" />
                            <Grid container direction={"column"} >
                                <Grid item container justifyContent="space-between">
                                    {renderTrueValueCaption()}
                                    <Typography className={classes.valueStyle} >
                                        {calculateTrueCost(row.provider)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                    content={
                        <Grid container direction={"column"} >
                            {row.content.map((col, i) => <Grid
                                key={`row-${index}-mobile-col-${i}`}
                                style={{ marginBottom: '10px' }}
                                item container justifyContent="space-between">
                                <Typography className={classes.captionStyle} >
                                    {labelRows[i]}
                                </Typography>
                                <Typography className={classes.valueStyle} >
                                    {renderTableCell(col.type, col.label, {})}
                                </Typography>
                            </Grid>)}
                        </Grid>
                    }
                />)}
            </div>)
            :
            ( <div className={classes.accordion}>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {renderTrueValueCaption()}
                                </TableCell>
                            </TableRow>
                            {
                                mobileRows.map((row, index) => (
                                    <TableRow>
                                        {
                                            <TableCell>
                                                <Grid container spacing={2} >
                                                    <Grid item>
                                                        <img width="85px" height="25px" src={row.labelPath} alt={`label path icon ${index + 1}`} />
                                                    </Grid>
                                                    <Grid item xs container direction="column" spacing={2}>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.valueStyle} style={index === 0 ? {color:'#008000', fontWeight:'500'}:{}}>
                                                            {trueCost(row.provider)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>))
            : <div className={classes.card}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: '#31517A' }}>Provider</TableCell>
                                    {compareTransferRates ? (transferMobileRows.map((row, index) => <TableCell style={index === 0 ? { backgroundColor: '#F3F8FF' } : {}} align="center"><img width="85px" height="25px" src={row.labelPath} /></TableCell>))
                                    :
                                    (mobileRows.map((row, index) => <TableCell style={index === 0 ? { backgroundColor: '#F3F8FF' } : {}} align="center"><img width="85px" height="25px" src={row.labelPath} /></TableCell>))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {compareTransferRates ? (tableRows.map((row, i) => (
                                    <TableRow key={`compare-table-row-${i}`}>
                                        {row.map((col, index) => {
                                            const cellStyle = index === 0 ? {
                                                fontWeight: '500',
                                                textAlign: 'left',
                                                color: '#31517A'
                                            } : { textAlign: 'center', color: '#626680' };
                                            return (
                                                <TableCell style={getTableBodyStyle(i, index)} key={`compare-${i}-col-${index}`} scope="row">
                                                    {renderTableCell(col.type, col.label, cellStyle)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))) : null}
                                <TableRow>
                                    <TableCell style={{ borderBottom: 'none' }} scope="row">{renderTrueValueCaption()}</TableCell>
                                    {compareTransferRates ? transferMobileRows.map((row, index) => (
                                        <TableCell style={getTableBottomStyle(index)}
                                            key={`true-cost-instant-row-${index}`} scope="row"> {calculateTrueCost(row.provider)}</TableCell>))
                                    :
                                    mobileRows.map((row, index) => (
                                        <TableCell style={getTableBottomStyle(index)}
                                            key={`true-cost-instant-row-${index}`} scope="row"> {trueCost(row.provider)}</TableCell>))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}
        </>
    )
}

const mapStateToProps = state => ({
    data_input: state.exchange.exchangeAmount
})

export default connect(mapStateToProps)(ExchangeRateCompare);