import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { parseArrFromString } from '../../../../shared/utility';

const useStyles = makeStyles(theme => ({
    whiteBox: {
        backgroundColor:'white', 
        display: 'inline-block', 
        boxShadow: '0px 3px 9px 0px rgba(11,65,153,0.2)', 
        justifyContent: 'center',
        marginTop: '50px',
        width: "100%",
        "&:hover,&:focus": {
            boxShadow: "12px 15px 20px 6px rgba(11,65,153,0.1)"
        }, 
        padding: '15px 0px',
        borderRadius: '15px'
    },
    tierIndex: {
        color: '#1160F7',
        fontSize: "20px",
        fontWeight: "700"
    },
    tierText: {
        color: '#31517A',
        fontSize: '16px'
    }, 
    tierTitle: {
        color: '#31517A',
        fontSize: '18px',
        fontWeight: '700'
    }, 
    listBullet: {
        color: '#1160F7', 
        fontSize: '10px', 
        margin:'10px'
    },
    listItem: {
        color: '#31517A', 
        fontSize:'18px'
    }, 
    tierGrid: {
        padding: '24px 20px'
    },
    helpListItem: {
        width: '100%',
        display: 'inline-flex',
        // position: 'relative',
        // textAlign: 'left',
        // alignItems: 'left',
        paddingTop: '20px',
        paddingBottom: '20px',
        //justifyContent: 'flex-start',
        textDecoration: 'none',
    },
    helpListSubItem: {
        //display: 'inline-flex',
        //flexShrink: '0',
        fontSize: '20px !important',
        fontWeight: '500',
        paddingRight: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px !important',
        },
    },
    helpListLink: {
        textDecoration: 'none',
        color: '#31517A',
    }, 
    image: {
        maxWidth: '60px',
        maxHeight: '58px',
    },
    basicItem: {
        fontSize: "16px",
        lineHeight: "1.7",
        color: "#626680", 
        fontWeight: "300", 
        marginBottom: '30px'
    }, 
    helpList : {
        padding: '0px', 
        listStyle: 'none',
    },
    basicList: {
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
    }
}))


const CustomList = ({listItems, type}) => {
    const classes = useStyles();
    const list = parseArrFromString(listItems)
    const unorderedList = (type === "help" || type === "basic")
    const makeListItem = (type) => {
        switch(type) {
            case "blue":
                return renderItem; 
            case "tier": 
                return renderTier; 
            case "help": 
                return renderHelpItem;
            case "basic": 
                return renderBasicItem;
            case "step":
                return renderStep; 
            default: 
                return renderBasicItem;
        }
    }

    const renderItem = (item) => { 
        let { text } = item
        const bullet = <span className={classes.listBullet}>&#11044;</span>
        return <ListItem className={classes.listItem}>{bullet} {text}</ListItem>
    }
    
    const renderTier = (item, index) => {
        let { text } = item
        const tierNum = <span className={classes.tierIndex}>{`Tier ${index + 1}. `}</span>
        return (
            <div className={classes.whiteBox}>
                <Grid item xs={12} justify = 'left' alignContent='flex-start' className={classes.tierGrid}>
                    <Typography component="div" className={classes.tierText}>{tierNum} {text}</Typography>
                </Grid>
            </div>
        )
    }

    const renderStep = (item, index) => {
        let { text , title} = item
        const stepNum = <span className={classes.tierIndex}>{`Step ${index + 1}. `}</span>
        return (
            <div className={classes.whiteBox}>
                <Grid item xs={12} justify = 'left' alignContent='flex-start' className={classes.tierGrid}>
                    <Typography component="div" className={classes.tierTitle}>{stepNum} {title}</Typography>
                    <Typography component="div" className={classes.tierText}>{text}</Typography>
                </Grid>
            </div>
        )
    }

    const renderHelpItem = (item) => {
        let { image, label, imageAlt, link } = item
        return (
            <li className={classes.helpListItem}>
                <img
                    src={image}
                    className={classes.image + ' ' + classes.helpListSubItem}
                    alt={imageAlt}
                    loading="lazy"
                />
                <a href={link} className={classes.helpListLink}>
                    <Typography
                    variant="h6"
                    component="h6"
                    >
                    {label}
                    </Typography>
                </a>
            </li>
        )
    }

    const renderBasicItem = (item) => {
        let { text } = item;
        return (
            <li className={classes.basicItem}>{text}</li>
        )
    }

    return (
        <Grid>
            {unorderedList ? 
            <ul className={type === "help" ? classes.helpList : classes.basicList}>
                {list.map(makeListItem(type))}
            </ul>
            :
            <Grid>
                {list.map(makeListItem(type))}
            </Grid>
            }
        </Grid>
    )
}
CustomList.propTypes = {
    listItems: PropTypes.array
}
export default CustomList;