import React, { useState } from 'react'
import classes from './TabListSection.module.scss'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import { useRouter } from 'next/router'
import Button from '../../../../../remitbee/components/button/Button'
import { parseArrFromString } from '../../../../../shared/utility'

interface TabListSectionProps {
        backgroundColor?: string,
        border?: string,
        background?: string,
        backgroundImage?: string,
        titleVariant?: string,
        titleAlignment?: string,
        titleColor?: string,
        title: string,
        tagsList: any,
        actionText?: string,
        actionVariant?: string,
        actionUrl?: string
}

const TabListSection:React.FC<TabListSectionProps> = ({
    backgroundColor,
    border,
    background,
    backgroundImage,
    title,
    titleVariant,
    titleAlignment,
    titleColor,
    tagsList,
    actionText,
    actionVariant,
    actionUrl
    }) => {
        const tagList = typeof tagsList === 'string' ? parseArrFromString(tagsList) : tagsList
        const [tempTagsList, setTempTagsList] = useState<any>(tagList.slice(0, 12));
        const [showAll, setShowAll] = useState<boolean>(false);

        const handleSeeMore = () => {
            setTempTagsList(showAll ? tagList.slice(0, 12) : tagList);
            setShowAll(!showAll);
        }

  return (
    <div style={{
        backgroundColor: backgroundColor || null, border: border || null, background: background || null, backgroundImage: backgroundImage && `url(${backgroundImage})`, backgroundSize: "cover",
        backgroundPosition: "center"
    }}>
      <Box className={classes['rb-root']}>
        <Box>
            {title && <Typography weight='bold' variant={titleVariant} align={titleAlignment} color={titleColor}>
               {title}
            </Typography>}
        </Box>
        <Box mt={10} mb={10} className={classes['rb-cards-container']}>
          {tempTagsList.map((tag: any, index: number) => (
            <Box onClick={tag?.onClick ? () => window.open(tag?.onClick) : null} key={index} className={classes['rb-card-container']}>
                <Typography variant={tag?.textVariant} color={tag?.textColor}>
                    {tag?.text}
                </Typography>
            </Box>))}
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Button className={classes['rb-button']} id='action-text' variant={actionVariant || 'outlined'} onClick={actionUrl ? window.open(actionUrl) : () => handleSeeMore()}>
            {actionText || (showAll ? 'See less' : 'See all')}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default TabListSection