import { ClickAwayListener, List, ListItem, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import SearchInput from '../../../common/searchInput/SearchInput';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
    listbox: {
        margin: 0,
        padding: 0,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        borderRadius: '4px',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    }
}));

function AutocompleteSearch({ getResults, getItem, ...props }) {
    const classes = useStyles();
    const [results, setResults] = useState([]);
    const [openResultsBox, setOpenResultsBox] = useState(false);
    const [width, setWidth] = useState('0px');
    const input = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setWidth(`${input.current.getBoundingClientRect().width}px`);
    }, [input])

    const search = async input => {
        try {
            setLoading(true);
            setOpenResultsBox(true);
        if(input === '') {    
            setResults(null);
            setOpenResultsBox(false);
            return;
        }
            const { data } = await getResults(input);
            if (data && data.length) {
              setResults(data);
              setOpenResultsBox(true);
            }else{
                setResults([]);
            }
          } catch (error) {
            setLoading(false);
          } finally {
            setLoading(false);
          }

        
    }

    return (
        <div {...props} ref={input}>
            <SearchInput InputProps={{}} onSearchInputChange={search} fullWidth={true} />
            {openResultsBox && results && results.length > 0 ? (
                <ClickAwayListener onClickAway={()=>setOpenResultsBox(false)}>
                    <Paper square>          
                        {!loading &&<List className={classes.listbox} style={{ width: width }}>
                            {results.map(({ attributes: { slug, title, help_category: { data: { attributes: { slug: categorySlug } }}}}, index) => (
                                <ListItem key={index}>{getItem({ slug, title, categorySlug })}</ListItem>
                            ))}
                        </List>}
                        {loading &&                      
                              <CircularProgress />                     
                        }
                    </Paper>
                </ClickAwayListener>
            ) : openResultsBox ? (
                <ClickAwayListener onClickAway={()=>setOpenResultsBox(false)}>
                    <Paper className={classes.resultsBox}>
                        {!loading &&
                        <List className={classes.listbox}>
                            <ListItem>Currently there are no articles matching this search</ListItem>
                        </List>
}
                        {loading &&                      
                    <CircularProgress />                     
              }
                    </Paper>
                </ClickAwayListener>
               
            ) : null}
        </div>
    )
}

AutocompleteSearch.propTypes = {
    getResults: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
}

export default AutocompleteSearch

