import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { body1, container, mediumTitle } from '../globalStyles';

const styles = theme => ({
  container,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    textAlign: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    background: '#F3F8FF',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '20px',
    },
  },
  reviewBox: {
    transition: "ease-in-out 0.3s",
    borderRadius: "10px",
    padding: "50px 30px !important",
    "&:hover,&:focus": {
      boxShadow: "12px 15px 20px 6px rgba(11,65,153,0.1)",
    },
    [theme.breakpoints.down('xs')]: {
      boxShadow: "2px 5px 10px 4px rgba(11,65,153,0.1)",
      marginBottom: '40px'
    },
  },
  grid: {
    display: 'grid',
    gap: '40px',
  },
  image: {
    maxWidth: '200px',
    maxHeight: '100px',
  },
  image2: {
    width: '150px',
    height: '300px',
    maxWidth: '200px',
    maxHeight: '100px',
  },
  reviewsSubheading: {
    margin: ' 0 auto 40px auto',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      margin: ' 0 auto 30px auto',
      maxWidth: '100%',
    },
  },
});

function ReviewsGoogleTruspilotSection(props) {
  const { classes, newReview } = props;
  return (
    <>
     {
      newReview === true ? <>
      <Grid item xs={12} sm={6} md={4} className={classes.reviewBox}>
      <Typography component="div" variant="body1" marked="center">4.6 rating on Google</Typography>
        <a rel="noreferrer nofollow" target="_blank" href='https://www.google.com/search?q=remitbee&rlz=1C1CHBF_enCA850CA850&oq=remitbee&aqs=chrome..69i57j69i59j69i60l4.4486j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b46fd01475905:0x546334d0f0b5f73b,1,,,'>
          <img
            src='/design-system/landing/Review/Google-Logo.wine 1.svg'
            className={classes.image2}
            alt="Google logo Remitbee reviews"
            loading="lazy"
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.reviewBox}>
        <Typography component="div" variant="body1" marked="center">Read reviews on</Typography>
        <a rel="noreferrer nofollow" target="_blank" href='https://www.trustpilot.com/review/remitbee.com'>
          <img
            src='/design-system/landing/Review/OIP (2).jpeg'
            className={classes.image}
            alt="Trustpilot logo Remitbee reviews"
            loading="lazy"
          />
        </a>
      </Grid>
      </>:<>
       <Grid item xs={12} sm={6} md={4} className={classes.reviewBox}>
        <a rel="noreferrer nofollow" target="_blank" href='https://www.google.com/search?q=remitbee&rlz=1C1CHBF_enCA850CA850&oq=remitbee&aqs=chrome..69i57j69i59j69i60l4.4486j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b46fd01475905:0x546334d0f0b5f73b,1,,,'>
        <Typography component="div" variant="body1" marked="center">Read reviews on</Typography>
          <img
            src='/img/landing/google_logo_review.png'
            className={classes.image}
            alt="Google logo Remitbee reviews"
            loading="lazy"
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.reviewBox}>
        <a rel="noreferrer nofollow" target="_blank" href='https://www.trustpilot.com/review/remitbee.com'>
          <img
            src='/img/landing/trustpilot_logo_review.png'
            className={classes.image}
            alt="Trustpilot logo Remitbee reviews"
            loading="lazy"
          />
        </a>
      </Grid>
      <Grid item xs={12} md={4} className={classes.reviewBox}>
        <a rel="noreferrer nofollow" target="_blank" href='https://www.facebook.com/pg/Remitbee/reviews/?ref=page_internal'>
          <img
            src='/img/landing/facebook_logo_review.png'
            className={classes.image}
            alt="Facebook logo Remitbee reviews"
            loading="lazy"
          />
        </a>
      </Grid></>
     }
    </>
  );
}

ReviewsGoogleTruspilotSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReviewsGoogleTruspilotSection);