import client from "./client";

const isDevEnv = process.env.REACT_APP_ENV === 'dev';

export const strapiQuery = (query, variables) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                isDevEnv && console.log(`${err}`);
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};

export default strapiQuery;
