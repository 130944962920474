const styles = theme => ({
  exchangeInputBox: {
    width: '100%',
    maxWidth: '480px',
    color: 'white',
    fontSize: '1rem',
    fontWeight: '400',
    zIndex: '20',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      float: 'right',
    },
  },
  button: {
    height: '48px'
  },
  altBox: {
    maxWidth: '400px',
    "@media (min-width: 1100px)": {
      left: '50px !important',
    },
    "@media (min-width: 1280px)": {
      left: '180px !important',
    },
  },
  leftSide: {
    width: '100%'
  },

  rightSide: {
    width: '100%'
  },
  exchangeInputCard: {
    width: '100%',
    zIndex: '20',
    boxShadow: 0,
    border: '1px solid #CACCD5',
    [theme.breakpoints.down('sm')]: {
      padding: "24px 16px 16px",
    },
    [theme.breakpoints.up('sm')]: {
      padding: "40px 24px 32px",
    }
  },
  grid: {
    display: 'grid',
    gap: '32px'
  },
  exchangeCardSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#6B708F'
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #CACCD5'
    }
  },
  exchangeCardText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '23px',
    color: '#37517A',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1),
      fontSize: '18px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    },
    marginRight: '6px'
  },
  type: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23.5px',
    color: '#37517A'
  },
  fees: {
    color: '#626680',
    fontSize: '16px',
    lineHeight: '23.5px'
  },
  noFees: {
    color: '#008000'
  },
  subLabel: {
    color: '#2EBC31'
  },
  paper: {
    minWidth: '340px',
    border: '1px solid #CACCD5'
  },
  menuItem: {
    padding: theme.spacing(2, 3),
    fontSize: '16px',
    '&:hover': {
      background: '#E9F5FF'
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #CACCD5'
    }
  },
  helpTooltipImg: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    cursor: 'pointer'
  },
  helpTooltip: {
    opacity: 1,
    boxShadow: '0 5px 6px rgba(0, 0, 0, 0.1)',
    minWidth: '200px'
  },
  tooltipWrap: {
    textAlign: 'left',
    color: '#37517A'
  },
  tooltipTitle: {
    fontSize: '12px',
    fontWeight: 500
  },
  tooltipItem: {
    fontSize: '10px'
  },
  tooltip: {
    background: '#fff',
    color: '#626680',
    fontSize: '12px',
    lineHeight: '14px',
    boxShadow: '0px 0px 8px #00000040',
    maxWidth: '250px'
  },
  tooltipArrow: {
    color: '#fff'
  },
  deliveryETAHolder: {
    display: 'flex',
    height: '100%',
    width: 'fit-content',
    justifyContent: 'flex-end',
  },
  imgPadding: {
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    }
  }
})

export default styles;