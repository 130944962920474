import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getImageAlt } from '../../../shared/utility';
import { body1, container, mediumTitle, whiteColor } from '../globalStyles';
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';
import { makeStyles } from '@mui/styles';

import ExchangeInputBox from '../components/ExchangeInputBox/ExchangeInputBox';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import CustomUnderlineMediumHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineMediumHeding';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import Image from '../../../remitbee/components/image/Image';

const useStyles = makeStyles((theme) => ({
  container,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    //backgroundColor: whiteColor,
    textAlign: 'left',
    paddingTop: '60px',
    paddingBottom: '60px',
    backgroundColor: '#FFFFFF',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingTop: '50px',
      paddingBottom: '50px',
      clipPath: 'none',
    },
  },
  imageLogoutWorld: {
    maxWidth: '100%',
    marginLeft: '-90px'
  },
  textAligner: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  textInfoNoMargin: {
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  actionButton: {
    marginTop: '30px',
  },
  mobileActionButton: {
    display: 'none',
    '@media (max-width: 500px)': {
      display: 'flex',
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '12px'
    },
  },
  image: {
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '350px',
    },
  },
  textArea: {
    '@media (min-width: 700px) and (max-width: 959px)': {
      marginBottom: '40px',
    },
    '@media (max-width: 700px)': {
      marginBottom: '40px',
    },
  },
  textInfo: {
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  alignContainer: {
    alignItems: 'center',
  },
  colored: {
    backgroundColor: whiteColor,
  },
  subtitle: {
    fontSize: '20px',
    color: '#6B708F',
    fontWeight: 'Medium',
    marginBottom: '20px',
    marginTop: '-20px',
  },
  color: {
    color: "#FFFFFF"
  },
  invertedCurve: {
    paddingTop: '0px'
  },
  alignStart: {
    alignItems: 'flex-start'
  },
  imageContainerAlignRight: {
    textAlign: 'right',
    '@media (max-width: 700px)': {
      textAlign: 'left !important',
    },
  },
  imageContainerAlignCenter: {
    textAlign: 'right',
    '@media (max-width: 700px)': {
      textAlign: 'center !important',
    },
  }
}));

function ImageText(props) {
  const {
    title,
    text,
    image,
    reverse,
    colored,
    actionText,
    actionUrl,
    list,
    actionType,
    ImageProps,
    TextProps,
    TitleProps,
    ButtonProps,
    underline,
    subtitle,
    exchangeBox,
    imageAlt, //this comes from the strapi proerty
    countryIdSelected,
    backgroundImage,
    section,
    color,
    invertedCurve,
    page,
    bankInfo,
    bankInfoImage,
    element,
    promotion
  } = props;
  const classes = useStyles();
  const alt_tag = imageAlt || getImageAlt(image);
  const logo_alt_tag = getImageAlt(bankInfoImage)
  const rootStyle = {};
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const colorStyles = color ? { color } : null;
  const imageContainerRef = useRef(null);
  const [imageRatio, setImageRatio] = useState(0);
  const [imageHeight, setImageHeight] = useState('0px');

  const markdownOptions = {
    overrides: {
      p: { component: 'span' },
    }
  }

  useEffect(() => {
    if (imageContainerRef.current && imageRatio) {
      setImageHeight(`${imageContainerRef.current.getBoundingClientRect().width / imageRatio}px`)
    }
  }, [imageRatio, imageContainerRef && imageContainerRef.current && imageContainerRef.current.getBoundingClientRect().width])

  if (backgroundImage) {
    rootStyle.background = `url(${backgroundImage}) no-repeat center`;
    rootStyle.backgroundSize = 'cover';
  }

  if (isMobile) {
    if (backgroundImage) {
      rootStyle.background = '#FFFFFF';
    }
    if (bankInfo) {
      rootStyle.textAlign = 'left'
    } else {
      rootStyle.textAlign = 'center'
    }
  }
  rootStyle.backgroundColor = colored ? colored : '#FFFFFF';

  const goToUrl = async () => {
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    let query = null;
    if (promotion) {
      query = addReferralLink()
    }
    router.push({ pathname: actionUrl, query });
  }

  const addReferralLink = () => {
    let _args = {}
    _args = { promo: promotion };
    return _args;
  }

  return (
    <div
      className={classnames(classes.root, invertedCurve ? classes.invertedCurve : null)}
      style={rootStyle}
    >
      <Grid
        container
        className={classnames(classes.container, (bankInfo) ? classes.alignStart : classes.alignContainer)}
        direction={reverse ? 'row-reverse' : 'row'}
        spacing={!isMobile ? 5 : 0}
        alignItems="center"
      >
        <Grid item xs={12} md={6} className={classes.textArea}>
          {bankInfoImage && <img src={bankInfoImage} alt={logo_alt_tag} loading="lazy" />}

          <Typography
            variant="h2"
            marked="center"
            className={page === 'logout' ? classes.mediumTitle + ' ' + classes.textInfoNoMargin : classes.mediumTitle + ' ' + classes.textInfo}
            component="h2"
            style={colorStyles}
            {...TitleProps}
          >
            {title}
            {page === 'logout' ? <CustomUnderlineMediumHeading color={"#1160F7"} page='logout' /> : null}
          </Typography>
          {subtitle && (
            <Typography component="div" variant="subtitle1" style={colorStyles} className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
          {underline && <CustomUnderlineBigHeading color="#1160F7" />}
          <Typography component="div" variant="body1" marked="center" className={classnames(classes.body1, classes.textAligner)} style={colorStyles} {...TextProps}>
            {typeof text == 'string' ? <Markdown children={text} options={markdownOptions} /> : text}
          </Typography>
          {actionText && element !== 'first' ?
            <CustomButton
              className={classes.actionButton}
              type={actionType || 'outlinedBlue'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </CustomButton>
            : null}
          {list}
        </Grid>
        {image ? (
          <Grid
            item
            xs={12}
            md={6}
            className={classnames(reverse ? '' : element === 'second' && isMobile ? classes.imageContainerAlignCenter : classes.imageContainerAlignRight)}
            ref={imageContainerRef}
          //style={{ height: imageHeight }}
          >
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <Image
                url={image}
                {...ImageProps}
                className={element === 'first' && !isMobile ? classes.imageLogoutWorld : classes.image}
              />
            </div>
          </Grid>
        )
          :
          exchangeBox ?
            <Grid item xs={12} md={6}>
              <ExchangeInputBox countryIdSelected={countryIdSelected} side={reverse ? 'left' : 'right'} autoFocus={false} />
            </Grid> : section
        }
        <Grid item xs={12} className={classes.textArea}>
          {actionText && element === 'first' ?
            <CustomButton
              className={classes.mobileActionButton}
              type={actionType || 'outlinedBlue'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </CustomButton>
            : null}
        </Grid>
      </Grid>
    </div>
  );
}

export default ImageText;
