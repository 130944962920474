import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
// import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
// import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { getImageAlt } from '../../../shared/utility';
import CustomList from '../components/CustomListWebsite/CustomList';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';

const styles = theme => ({
    container,
    mediumTitle,
    body1,
    bigTitle: {
        ...bigTitle,
        textAlign: 'left'
    },
    root: {
        display: 'flex',
        paddingTop: '35px',
        paddingBottom: '55px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '55px',
            paddingBottom: '35px',
            clipPath: 'none',
        },
    },
    stacked: {
        display: 'flex',
        paddingTop: '40px',
        paddingBottom: '50px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
            paddingBottom: '20px',
            clipPath: 'none',
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    title_h2: {
        fontSize: '30px !important'
    },
    title_h1: {
        fontSize: '40px !important',
        "@media (max-width: 959px)": {
            fontSize: '25px !important'
            
        },
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        marginBottom: '0px',
        marginTop: '20px'
    },
    widthset: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
          },
    },
    appDownloadContainer: {
        marginBottom: "0px"
    },
    appImage: {
        width: 'auto',
        height: '4em',
        marginLeft: '0',
        marginRight: '3em',
        marginTop: '3em'
    },
    appImageGrid: {
        marginRight: '50px'
    },
    anchor: {
        pointerEvents: 'none'
    },
    text: {
        fontSize: '16px',
        opacity: '0.85',
        lineHeight: '1.7',
    },
    grey: {
        color: '#31517A !important'
    },
    white: {
        color: '#FFFFFF !important'
    },
});

function InfoColumnsWithList(props) {
    const {
        classes,
        title,
        text,
        image,
        typography,
        background,
        // actionText,
        // actionUrl,
        // actionType,
        color,
        backgroundColor,
        // contentType,
        // contentProps,
        alignLeft,
        imageAlt,
        isUnderlined,
        // reviews,
        // data,
        // countrySending,
        // promotion,
        stack,
        list,
        listPretext,
        listType,
        appDownload
    } = props;
    const tp = typography || 'h2';
    const alt_tag = imageAlt || getImageAlt(image);
    // const theme = useTheme();
    // const router = useRouter()
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const goToUrl = () => {
    //     let query = null;
    //     const pathname = location.protocol + '//' + location.host + actionUrl;
    //     if (promotion) {
    //         query = addReferralLink()
    //     }
    //     router.push({pathname, query});
    // }
    
    // const addReferralLink = () => {
    //     let _args = {}
    //     _args = { promo: promotion };
    //     return _args;
    // }

    return (
        <section className={classnames((stack)?classes.stacked:classes.root, !alignLeft && classes.textCenter)}
            style={{ background: background ? `url('${background}') center no-repeat` : backgroundColor }}>
            <Container className={classes.container}>
                <Grid container /* spacing={isMobile ? 0 : 5} */ justifyContent={alignLeft ? 'left' : 'center'} className={classes.widthset}>
                    <Grid item xs={12}>
                        {title && <Typography component="div" variant="h2" className={classnames(classes.bigTitle, classes[`title_${tp}`], classes[color], !alignLeft && classes.textCenter)}>
                            {title}
                        </Typography>}
                        {isUnderlined && <Grid item xs={12}>
                            <CustomUnderlineBigHeading/>
                        </Grid>}
                        {listPretext && <Typography component="div" variant="body1" marked="center" className={classnames(classes.text, classes[color])}>
                            <Markdown
                                children={listPretext + '\n'}
                            />
                        </Typography>}
                        {list && <CustomList
                                    listItems={list}
                                    type={listType}>
                                </CustomList>}
                        {text && <Typography component="div" variant="body1" marked="center" className={classnames(classes.text, classes[color])}>
                            <Markdown
                                children={text}
                            />
                        </Typography>}
                        {image && <Grid item xs={12} /* className={classes.featuresBox} */>
                            <img
                                className={classes.image}
                                src={image}
                                alt={alt_tag}
                            />
                        </Grid>}
                        {appDownload && <Grid container spacing={8} direction="row" className={classes.appDownloadContainer}>{"\n"}
                        <Grid item xs={12} /* sm={6} */ className={classes.appImageGrid}>
                            <a href="https://apps.apple.com/us/app/remitly-transfer-money-abroad/id674258465" target="_blank" className={classes.anchor}>
                            <img src="/img/landing/app_store.svg" className={classes.appImage} alt="app store icon"/>
                            </a>
                            
                            <a href="https://play.google.com/store/apps/details?id=com.remitly.androidapp&hl=en" target="_blank" className={classes.anchor}>
                            <img src="/img/landing/play_store.svg"  className={classes.appImage} alt="play store icon"/>
                            </a>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <a href="https://play.google.com/store/apps/details?id=com.remitly.androidapp&hl=en" target="_blank" className={classes.anchor}>
                            <img src="/img/landing/play_store.svg"  className={classes.appImage}/>
                            </a>
                        </Grid> */}
                        </Grid>}
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

InfoColumnsWithList.propTypes = {
    actionText: PropTypes.string,
    actionUrl: PropTypes.string,
    actionType: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    typography: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.any,
    backgroundColor: PropTypes.string,
    contentType: PropTypes.string,
    stack: PropTypes.bool,
    list: PropTypes.array,
    listPretext: PropTypes.string,
    listType: PropTypes.string,
    appDownload: PropTypes.bool
};

export default withStyles(styles)(InfoColumnsWithList);