import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { container } from '../globalStyles';

const styles = () => ({
    container,
    root: {
        // flexGrow: 1,
        width: '100%'
    },
    reasonBox: {
        maxWidth: '300px',
        height: '380px',
        borderRadius: "10px",
        padding: "50px 30px !important",
        boxShadow: "5px 6px 20px rgba(11, 46, 100, 0.25)",
        margin: 'auto',
        "@media (max-width:959px)": {
            marginTop: '50px'
        }
    },
    image: {
        maxWidth: '200px',
        maxHeight: '100px',
    },
    subtitle: {
        marginTop: '30px',
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '35px',
        color: '#31517A'
    },
    text: {
        marginTop: '30px',
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '23px',
        color: '#626680'
    }
});

function ExchangeReasons(props) {
    const { classes } = props;
    return (
        <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/dollar-bag.svg'
                    className={classes.image}
                    alt="dollar bag"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>Inexpensive</Typography>
                <Typography className={classes.text}>No fees and top exchange rates. Get the most out of your money by checking our live comparison table.</Typography>        
            </Grid>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/shield-lock-black.svg'
                    className={classes.image}
                    alt="shield lock"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>Always Safe</Typography>
                <Typography className={classes.text}>Feel comfort knowing your money is safe. Remitbee is accredited by the Better Business Bureau online reliability program.</Typography>
            </Grid>
            <Grid item xs={12} className={classes.reasonBox}>
                <img
                    src='/img/landing/magnifying-glass.svg'
                    className={classes.image}
                    alt="magnifying glass"
                    loading="lazy"
                />
                <Typography className={classes.subtitle}>100% Transparent</Typography>
                <Typography className={classes.text}>At Remitbee we don’t have any hidden fees or charges. Know exactly how much you’ll receive.</Typography>
            </Grid>
        </Grid>
    );
}

ExchangeReasons.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExchangeReasons);