import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTableRow from '@mui/material/TableRow';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { fetchCountriesRest } from '../../../../redux/system/actions';

const CANADA_CURRENCY_CODE = 'CAD';
const CANADA_CURRENCY_NAME = 'Canadian dollar';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(5)
  },
  head: {
    backgroundColor: '#fff',
  },
  sendingValue: {
    color: "#1160F7",
    fontFamily: 'Poppins',
    fontSize: "16px",
    fontWeight: 600,
    "&:hover": {
      color: "#31517A",
      cursor: "pointer"
    },
  },
  sendingLink: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  receivingValue: {
    color: "#37517A",
    fontSize: "16px",
    fontFamily: 'Poppins',
    fontWeight: 400
  },
  currencyNameRow: {
    background: "#fff",
    borderBottom: '1px solid #CACCD5',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      textAlign: 'center'
    }
  },
  currencyName: {
    color: '#31517A',
    fontSize: "18px",
    lineHeight: "26px",
    minHeight: '26px',
    margin: 0,
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  evenRow: {
    background: "#FBFCFF",
  },
  table: {
    background: '#fff',
    borderRadius: '5px',
    border: '1px solid #CACCD5',
  },
});

const sendingValues = [1, 5, 10, 20, 50, 100, 150, 200, 250, 500, 1000, 1200, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000];

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: '0.75px solid #CACCD5',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3)
    }
  },
}))(MuiTableCell);

const TableRow = withStyles(() => ({
  root: {
    borderStyle: 'none',
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}))(MuiTableRow);

function CurrencyExchangeTable(props) {
  const { classes, countries, fetchCountries } = props;
  const router = useRouter();
  const [currencyCode, setCurrencyCode] = useState(null);
  const [rate, setRate] = useState(1);
  const [currencyName, setCurrencyName] = useState(null);
  // const [countryName, setCountryName] = useState(null);
  const [tranferRates, setTransferRates] = useState([]);
  const [values] = useState(props.values || 'cad-to-usd');
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const redirect = (path) => {
    router.push(path);
  };

  useEffect(() => {
    const getCountries = async () => {
      const result = await fetchCountries();
      setTransferRates(result);
      setLoading(false);
    }
    if (countries) {
      setTransferRates(countries);
      setLoading(false);
    }
    else getCountries();
  }, [countries]);

  useEffect(() => {
    const currencyCode = props[0] ? props[0].currency_code : props.currency_code;
    if (currencyCode) setCurrencyCode(props.currency_code);
  }, [props])

  const updateCurrencyInfo = (code) => {
    const selectedCountry = tranferRates.filter((country) => {
      if (country.currency_code === code) return true;
      return false;
    });
    if (selectedCountry && selectedCountry.length) {
      const { rate, currency_name } = selectedCountry[0];
      setRate(rate);
      setCurrencyName(currency_name)
      // setCountryName(country_to)
    }
  }

  useEffect(() => {
    if (tranferRates.length > 0 && currencyCode) updateCurrencyInfo(currencyCode)
  }, [currencyCode, tranferRates])

  const sendingValueRows = sendingValues.map(value => ({
    sending: `${value} ${CANADA_CURRENCY_CODE}`,
    receiving: `${Number(rate * value).toFixed(2)} ${currencyCode}`
  }));
  const receivingValueRows = sendingValues.map(value => ({
    sending: `${value} ${currencyCode}`,
    receiving: `${Number(value / rate).toFixed(2)} ${CANADA_CURRENCY_CODE}`
  }));

  const handleCurrencyClick = (value) => {
    let path = '/currency-exchange/exchange-';
    if (value) {
        path = path.concat(value.includes(CANADA_CURRENCY_CODE) ? 
        `${CANADA_CURRENCY_CODE.toLowerCase()}-to-${currencyCode.toLowerCase()}` :
        `${currencyCode.toLowerCase()}-to-${CANADA_CURRENCY_CODE.toLowerCase()}`);
      path = path.concat(`?amount=${parseFloat(value)}`);
    } else {
      path = '/';
    }

    redirect(path);
  }

  const Table = ({ rows = [], sending, receiving }) => {
    const title = sending && receiving ? `${sending} to ${receiving} exchange rate` : ''
    return (
      <TableContainer
        className={classes.table}
        components={{
          Container: () => <Paper elevation={0} />
        }}>
        <Box className={classes.currencyNameRow}>
          <Typography component='div' variant='h3' className={classes.currencyName}>{title}</Typography>
        </Box>
        <MuiTable>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={`currency-exchange-row-${index}`} className={index % 2 !== 0 ? classes.evenRow : null}>
                <TableCell>
                  <Link className={classes.sendingLink} style={{ pointer: 'cursor' }} onClick={() => handleCurrencyClick(row.sending)} > <span className={classes.sendingValue}>{row.sending}</span>  </Link>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.receivingValue}>
                    {row.receiving}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
  };

  if (loading) return <></>

  return (
    <Grid item xs={12}>
      <section className={classes.root}>
        <Grid
          container
          justifyContent="center"
          className={classes.tableContainer}
          spacing={isMobile? 4 : 8}
        >
          <Grid
            item
            xs={12} sm={6}
          >
            {values === 'cad-to-usd' && <Table
              rows={sendingValueRows.filter((value, index) => index < sendingValueRows.length / 2)}
              sending={CANADA_CURRENCY_NAME}
              receiving={currencyName}
            />}
            {values === 'usd-to-cad' && <Table
              rows={receivingValueRows.filter((value, index) => index < sendingValueRows.length / 2)}
              sending={currencyName}
              receiving={CANADA_CURRENCY_NAME}
            />}
          </Grid>
          <Grid
            item
            xs={12} sm={6}
          >
            {values === 'cad-to-usd' && <Table
              rows={sendingValueRows.filter((value, index) => index >= sendingValueRows.length / 2)}
            />}
            {values === 'usd-to-cad' && <Table
              rows={receivingValueRows.filter((value, index) => index >= sendingValueRows.length / 2)}
            />}
          </Grid>
        </Grid>
      </section>
    </Grid>
  );
}

CurrencyExchangeTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ system }) => ({
  countries: system.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountriesRest()),
});

//export default publicPage(withStyles(styles)(CurrencyExchangeTable), { skipLoading: true });
const CurrencyExchangeTableWithRedux = (connect(mapStateToProps, mapDispatchToProps)(CurrencyExchangeTable));

export default withStyles(styles)(CurrencyExchangeTableWithRedux);
