import UIGrid from '@mui/material/Grid';
import Markdown from 'markdown-to-jsx';
import { ArticleJsonLd, BreadcrumbJsonLd, CorporateContactJsonLd, FAQPageJsonLd, LocalBusinessJsonLd, NewsArticleJsonLd, ProductJsonLd, SocialProfileJsonLd } from 'next-seo';
import React, { useMemo } from 'react';
import Hero from '../../../reusableSections/Hero';
import HeroConverter from '../../../reusableSections/HeroConverter';
import HeroResource from '../../../reusableSections/HeroResource';
import JsonSeo from '../SeoComponents/Seo';
import HeroHeader from '../../../reusableSections/new/HeroHeader';
import ColumnImage from '../../../reusableSections/new/ColumnImage';
import UserReviewsSection from '../../../reusableSections/new/UserReviewsSection/UserReviewsSection';
import FullWidthSection from '../../../reusableSections/new/FullWidthSection/FullWidthSection';
import HalfWidthSection from '../../../reusableSections/new/HalfWidthSection/HalfWidthSection';
import TabsSection from '../../../reusableSections/new/TabsSection/TabsSection';
import HeroSection from '../../../reusableSections/new/HeroSection/HeroSection';
import DownloadAppSection from '../../../reusableSections/new/DownloadAppSection/DownloadAppSection';
import HalfColumnSection from '../../../reusableSections/new/HalfColumnSection';
import FullColumnSection from '../../../reusableSections/new/FullColumnSection';
import Slider from '../../../reusableSections/new/Slider';
import FAQList from '../../../reusableSections/new/FAQList';
import TopBar from '../../TopBar/TopBar';
import Disclaimer from '../../../reusableSections/Disclaimer';
import PromotionalHeroSection from '../../../../../remitbee/sections/PromotionalHeroSection/PromotionalHeroSection';
import CashPickupLandscapeImage from '../../../sections/CashPickupLandscapeImage';
import PickupLocationsMap from '../../../reusableSections/PickupLocationsMap';
import CashPickupLocationsList from '../../../sections/CashPickupLocationsList';
import DownloadAppVariableSection from '../../../sections/DownloadAppVariableSection';
import DownloadAppSections from '../../../reusableSections/new/DownloadAppSection';
import HeroFullImageSection from '../../business-perks/HeroFullImageSection';
import GiftOperators from '../../GiftCardPublic/GiftOperators';
import { Grid } from '@mui/material';
import ConverterGraph from '../../../reusableSections/new/ConverterGraph/ConverterGraph';
import ForecastSection from '../../../reusableSections/new/ForecastSection/ForecastSection';

import Section from '../../../reusableSections/Section';
import ImageText from '../../../reusableSections/ImageText';
import Steps from '../../../reusableSections/Steps';
import { Column, FullColumn, HalfColumn, HalfOrFullColumn, Row } from '../../../../common/rowColumn';
import PromoPopUp from '../../../../common/PromoPopUp';
import PromoSignUp from '../../../../common/PromoSignUp';
import AllCountriesSection from '../../../reusableSections/AllCountriesSection';
import BoxList from '../../../reusableSections/BoxList';
import CodeDissector from '../../../reusableSections/CodeDissector';
import CurvedImage from '../../../reusableSections/CurvedImage';
import CustomSectionItem from '../../../reusableSections/CustomSectionItem';
import FAQ from '../../../reusableSections/FAQ';
import FullColumnInfo from '../../../reusableSections/FullColumnInfo';
import HowItWorks from '../../../reusableSections/HowItWorks';
import InfoColumnsWithList from '../../../reusableSections/InfoColumnsWithList';
import OtherServices from '../../../reusableSections/OtherServices';
import ProConList from '../../../reusableSections/ProConList';
import ProsCons from '../../../reusableSections/ProsCons';
import TextWithBG from '../../../reusableSections/TextWithBG';
import TrustPilotWidget from '../../../reusableSections/TrustPilotWidget';
import DownloadAppsSection from '../../../sections/DownloadAppsSection';
import CustomList from '../../CustomListWebsite/CustomList';
import Cards from '../sections/Cards';
import ConversionTable from '../sections/ConversionTable';
import SimpleConversionTable from '../sections/SimpleConversionTable';
import Features from '../sections/Features';
import CurrencyListSection from '../../../../common/CurrencyListSection';
import RatesGraph from '../../../../rates/RatesGraph';
import BlogSection from '../../../reusableSections/new/BlogSection/BlogSection';
import FaqSection from '../../../../../remitbee/sections/FaqSection/FaqSection';
import HalfWidthContactSection from '../../../../../remitbee/sections/ContactSection/HalfWidthContactSection';
import FullWidthContactSection from '../../../../../remitbee/sections/ContactSection/FullWidthContactSection';


// const Column = dynamic(() => import('../../../../common/rowColumn').then(module => module.Column))
// const FullColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.FullColumn))
// const HalfColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.HalfColumn))
// const HalfOrFullColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.HalfOrFullColumn))
// const Row = dynamic(() => import('../../../../common/rowColumn').then(module => module.Row))
// const PromoPopUp = dynamic(() => import('../../../../common/PromoPopUp'))
// const PromoSignUp = dynamic(() => import('../../../../common/PromoSignUp'))
// const AllCountriesSection = dynamic(() => import('../../../reusableSections/AllCountriesSection'))
// const BoxList = dynamic(() => import('../../../reusableSections/BoxList'))
// const CodeDissector = dynamic(() => import('../../../reusableSections/CodeDissector'))
// const CurvedImage = dynamic(() => import('../../../reusableSections/CurvedImage'))
// const CustomSectionItem = dynamic(() => import('../../../reusableSections/CustomSectionItem'))
// const FAQ = dynamic(() => import('../../../reusableSections/FAQ'))
// const FullColumnInfo = dynamic(() => import('../../../reusableSections/FullColumnInfo'))
// const HowItWorks = dynamic(() => import('../../../reusableSections/HowItWorks'))
// const InfoColumnsWithList = dynamic(() => import('../../../reusableSections/InfoColumnsWithList'))
// const OtherServices = dynamic(() => import('../../../reusableSections/OtherServices'))
// const ProConList = dynamic(() => import('../../../reusableSections/ProConList'))
// const ProsCons = dynamic(() => import('../../../reusableSections/ProsCons'))
// const Section = dynamic(() => import('../../../reusableSections/Section'))
// const Steps = dynamic(() => import('../../../reusableSections/Steps'))
// const TextWithBG = dynamic(() => import('../../../reusableSections/TextWithBG'))
// const TrustPilotWidget = dynamic(() => import('../../../reusableSections/TrustPilotWidget'))
// const DownloadAppsSection = dynamic(() => import('../../../sections/DownloadAppsSection'))
// const CustomList = dynamic(() => import('../../CustomListWebsite/CustomList'))
// const Cards = dynamic(() => import('../sections/Cards'))
// const ConversionTable = dynamic(() => import('../sections/ConversionTable'))
// const SimpleConversionTable = dynamic(() => import('../sections/SimpleConversionTable'))
// const Features = dynamic(() => import('../sections/Features'))
// const CurrencyListSection = dynamic(() => import('../../../../common/CurrencyListSection'))
// const RatesGraph = dynamic(() => import('../../../../rates/RatesGraph'))
// const BlogSection = dynamic(() => import('../../../reusableSections/new/BlogSection/BlogSection'))

import NavigationBarSection from '../../../../../remitbee/sections/NavigationBarSection/NavigationBarSection';
import IBANExamples from '../../../reusableSections/new/IBANCalculator/IBANExamples';
import FooterSection from '../../../../../remitbee/sections/FooterSection/FooterSection';
import TabListSection from '../../../reusableSections/new/TabListSection/TabListSection';

// import { createTheme, ThemeProvider } from '@mui/material/styles';
// const defaultTheme = createTheme();

function DynamicContent({ data, isMobileServer }) {
  const { content, SEO, countries } = data || {};

  if (!content) return null

  const markdownOptions = useMemo(() => ({
    overrides: {
      Seo: { component: () => null },
      p: { component: 'span' },
      ArticleJsonLd: { component: ArticleJsonLd },
      BlogJsonLd: { component: ArticleJsonLd },
      BreadcrumbJsonLd: { component: BreadcrumbJsonLd },
      CorporateContactJsonLd: { component: CorporateContactJsonLd },
      FAQPageJsonLd: { component: FAQPageJsonLd },
      LocalBusinessJsonLd: { component: LocalBusinessJsonLd },
      // JobPostingJsonLd: { component: JobPostingJsonLd },
      NewsArticleJsonLd: { component: NewsArticleJsonLd },
      SocialProfileJsonLd: { component: SocialProfileJsonLd },
      ProductJsonLd: { component: ProductJsonLd },
      CurrencyListSection: { component: (props) => <CurrencyListSection {...props} countries={countries} /> },
      Row: { component: Row },
      Column: { component: Column },
      HalfColumn: { component: HalfColumn },
      FullColumn: { component: FullColumn },
      HalfOrFullColumn: { component: HalfOrFullColumn },
      Hero: { component: Hero },
      HeroConverter: { component: HeroConverter },
      HeroResource: { component: HeroResource },
      CurvedImage: { component: CurvedImage },
      Cards: { component: Cards },
      Features: { component: Features },
      InfoArea: { component: ImageText },
      Steps: { component: Steps },
      FullColumnInfo: { component: FullColumnInfo },
      InfoColumnsWithList: { component: InfoColumnsWithList },
      ConversionTable: { component: (props) => <ConversionTable {...props} countries={countries} /> },
      DownloadAppsSection: { component: DownloadAppsSection },
      Section: { component: (props) => <Section {...props} /> },
      // Header: { component: DefaultHeader },
      Header: { component: (props) => <NavigationBarSection isMobileServer={isMobileServer} {...props} /> },
      Faq: { component: FAQ },
      CodeDissector: { component: CodeDissector },
      CustomList: { component: CustomList },
      ProConList: { component: ProConList },
      ProsCons: { component: ProsCons },
      TrustPilotWidget: { component: TrustPilotWidget },
      HowItWorks: { component: HowItWorks },
      OtherServices: { component: OtherServices },
      CustomSectionItem: { component: CustomSectionItem },
      UIGrid: { component: UIGrid },
      TextWithBG: { component: TextWithBG },
      AllCountriesSection: { component: (props) => <AllCountriesSection {...props} countries={countries} /> },
      BoxList: { component: BoxList },
      RatesGraph: { component: RatesGraph },
      //new designs
      TopBar: { component: TopBar },
      HeroHeader: { component: HeroHeader },
      ColumnImage: { component: ColumnImage },
      FullColumnSection: { component: FullColumnSection },
      HalfColumnSection: { component: HalfColumnSection },
      Slider: { component: Slider },
      FaqList: { component: FAQList },
      PromoPopUp: { component: PromoPopUp },
      PromoSignUp: { component: PromoSignUp },
      SimpleConversionTable: { component: SimpleConversionTable },
      Disclaimer: { component: Disclaimer },
      PromotionalHero: { component: (props) => <PromotionalHeroSection {...props} isMobileServer={isMobileServer} /> },
      CashPickupLandscapeImage: { component: CashPickupLandscapeImage },
      PickupLocationsMap: { component: PickupLocationsMap },
      CashPickupLocationsList: { component: CashPickupLocationsList },
      DownloadAppVariableSection: { component: DownloadAppVariableSection },
      DownloadAppBannerSection: { component: DownloadAppSections },
      HeroFullImageSection: { component: HeroFullImageSection },
      GiftOperators: { component: GiftOperators },
      Grid: { component: Grid },
      FullWidthSection: { component: FullWidthSection },
      HalfWidthSection: { component: (props) => <HalfWidthSection {...props} isMobileServer={isMobileServer} /> },
      TabsSection: { component: TabsSection },
      HeroSection: { component: (props) => <HeroSection {...props} isMobileServer={isMobileServer} /> },
      DownloadAppSection: { component: DownloadAppSection },
      UserReviewsSection: { component: UserReviewsSection },
      BlogSection: { component: BlogSection },
      ConverterGraph: { component: ConverterGraph },
      IBANExamples: { component: IBANExamples },
      TabListSection: { component: TabListSection },
      Forecast: { component: ForecastSection },
      // renaming to migrate it without breaking exisiting components
      ForecastSection: { component: ForecastSection },
      PromotionalHeroSection: { component: (props) => <PromotionalHeroSection {...props} isMobileServer={isMobileServer} /> },
      FaqSection: { component: FaqSection },
      HalfWidthContactSection: { component: (props) => <HalfWidthContactSection {...props} isMobile={isMobileServer} /> },
      FullWidthContactSection: { component: (props) => <FullWidthContactSection {...props} isMobile={isMobileServer} /> },
    }
  }), []);

  const get_schema = SEO["@type"] != null && <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(SEO) }} />

  return (
    <>
      {/* <ThemeProvider theme={defaultTheme}> */}
      {get_schema}
      <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <JsonSeo {...SEO} />
        <Markdown
          children={content}
          options={markdownOptions}
        />
        <FooterSection isMobileServer={isMobileServer} />
      </div>
      {/* </ThemeProvider> */}
    </>
  );
}

export default DynamicContent;
