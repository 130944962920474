import React from "react";
import { Menu } from "@mui/material";
import { Typography } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { ListItemText, ListItem } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from "react";
import styles from '../../landingPage/components/ExchangeInputBox/styles';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'next-i18next'
import PropTypes from 'prop-types';
import { extractPredictedDate } from "../../../shared/calendar";

const useStyles = makeStyles(styles);

const PayWithInput = props => {
    const { t } = useTranslation('landing');
    const { onChangeSelect, availablePaymentTypes, additionalInfo, setSettlementHolidayDates, setFundingHolidayDates } = props;
    const [anchorel, setAnchorEl] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [freeLabel, setFreeLabel] = useState(t('free'));
    const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('xs'));
    const classes = useStyles();

    const setFundingTimeDraft = (paymentTypes, value) => {
        if (paymentTypes && value) {
            const paymentTypeValue = (value !== 'CURRENT_BALANCE') ? value : 'WALLET';
            const serviceTypeValue = 'MT';
            const paymentType = paymentTypes.find(({ payment_type, type }) => payment_type || type === paymentTypeValue);

            const fundingTimeInfo = extractPredictedDate({ paymentTypesDetails: paymentTypes, type: 'funding', serviceType: serviceTypeValue, paymentType: paymentType?.type || paymentTypeValue, t });
            const settlementTimeInfo = extractPredictedDate({ paymentTypesDetails: paymentTypes, type: 'settlement', serviceType: serviceTypeValue, paymentType: paymentType?.type || paymentTypeValue, t });
            fundingTimeInfo?.holidayDates?setFundingHolidayDates(fundingTimeInfo?.holidayDates): setFundingHolidayDates();
            settlementTimeInfo?.holidayDates? setSettlementHolidayDates(settlementTimeInfo?.holidayDates): setSettlementHolidayDates();
        }
    };

    useEffect(() => {
        const paymentType = selectedPaymentType?.payment_type;
        if (paymentType) setFundingTimeDraft(availablePaymentTypes, paymentType);
    }, [selectedPaymentType]);

    useEffect(() => {
        if (availablePaymentTypes && availablePaymentTypes.length){
            // if payment type already selected, pick the values from selected type
            // else or selected type is not available in payment types, pick the default payment type
            let selected;
            if(selectedPaymentType) {
                selected = availablePaymentTypes.find(value => value.payment_type === selectedPaymentType.payment_type);
            }
            if(!selected) {
                selected = availablePaymentTypes.find(value => value.payment_type === 'INTERAC_E_TRANSFER');
            }

            setSelectedPaymentType(selected);
            onChangeSelect(selected?.timeline?.settlement_timeline?.predicted_date || selected.settlement_time);
        }

        if (typeof window !== 'undefined') window.onscroll = () => {
            setAnchorEl(null)
        }
    }, [availablePaymentTypes]);

    useEffect(() => {
        setFreeLabel(additionalInfo && additionalInfo.first_transfer_free ? t('free_first_transfer') : t('free'));
    }, [additionalInfo])

    const handleMenu = event => {
        const target = event.currentTarget;
        setTimeout(() => {
        setAnchorEl(anchorel ? null : target);
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = paymentType => {
        setSelectedPaymentType(paymentType);
        onChangeSelect(paymentType?.timeline?.settlement_timeline?.predicted_date || paymentType.settlement_time) //set delivery date
        setAnchorEl(null);
    }

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body1" className={classes.exchangeCardSubtitle} style={{ marginRight: 5}}>
                    Pay with
                </Typography>
                <ButtonBase onClick={handleMenu}>
                    {selectedPaymentType ? <Typography variant="body1" className={classes.exchangeCardSubtitle} style={{ color: '#1160F7' }}>
                        {selectedPaymentType.label && selectedPaymentType.label.length < 15 ? selectedPaymentType.label : `${selectedPaymentType.label.substring(0, 12)}...` }
                        </Typography> :
                        <Skeleton variant="rectangular" width={60} height={23} />
                    }
                    <KeyboardArrowDownOutlinedIcon style={{ color: '#1160F7' }}/>
                </ButtonBase>
                <Menu
                    disableScrollLock={true}
                    anchorEl={anchorel}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    classes={{ paper: classes.paper }}
                    open={Boolean(anchorel)}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 4
                    }}
                >
                {availablePaymentTypes && availablePaymentTypes.length > 0 &&
                    availablePaymentTypes.map(item => {
                        return (
                        <ListItem key={item.label} onClick={() => onChange(item)} classes={{ root: classes.menuItem }}>
                            <ListItemText>
                                <span className={classes.type}>{item.label}</span>
                                {item.fees && parseFloat(item.fees) !== 0 ? 
                                    (<span className={classes.fees}>{` - ${item.fees} CAD`}</span>)
                                    : (<span className={classes.fees}>{' - '}<span className={classes.noFees}>{freeLabel}</span></span>)
                                }
                            </ListItemText>
                        </ListItem>
                        )
                    })
                }
            </Menu>
            </Box>
            {isNotMobile && <Box flexGrow={1}></Box>}
            {selectedPaymentType && selectedPaymentType.fees ? (
                <Typography variant="body2" className={classes.exchangeCardText}>
                    {selectedPaymentType.fees && parseFloat(selectedPaymentType.fees) !== 0 ? 
                    `${selectedPaymentType.fees} CAD`
                    : <span className={classes.noFees}>{freeLabel}</span>}
                </Typography>
            ) : <Skeleton variant="rectangular" width={90} height={23} /> }
        </>
    )
}

PayWithInput.propTypes = {
    onChangeSelect: PropTypes.func,
    additionalInfo: PropTypes.object,
    availablePaymentTypes: PropTypes.array,
}

PayWithInput.defaultProps = {
    onChangeSelect: () => {
        return
    },
    additionalInfo: {},
    availablePaymentTypes: []
}

export default PayWithInput;