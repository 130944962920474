// import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { bigTitle, body1, container } from '../globalStyles';

const styles = (theme) => ({
  container,
  bigTitle,
  body1,
  root: {
    display: 'flex',
    textAlign: 'left',
    paddingTop: '100px',
    paddingBottom: '140px',
    color: '#FFFFFF !important',
    background: 'url("/img/landing/app_bg.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#FFFFFF !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '100px',
      background: 'url("/img/landing/small_app_bg.png")',
      backgroundPosition: 'center',
    },
    marginBottom: '-40px'
  },
  textInfo: {
    color: '#ffffff !important',
    textAlign: 'left',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    float: 'left',
    paddingRight: '10px',
  },
  firstAppImage: {
    [theme.breakpoints.down('sm')]:{
        marginBottom: '30px'
    },
},
  appstoresLogo: {
    paddingTop: '30px',
  },
  phoneInput: {
    backgroundColor: 'white',
    borderRadius: '4px',
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
  },
  storeLink: {
    maxWidth: '180px',
    maxHeight: '75px',
    float: 'left',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '140px',
    },
  }
});

function DownloadAppsSection(props) {
  const { classes, title, text } = props;
  // const [phoneNumber, setPhoneNumber] = React.useState('');
  // const [linkSent, setLinkSent] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);
  // const [validationMessage, setValidationMessage] = React.useState(null);

  // const handleButtonClick = async () => {
  //   let phoneOnlyNumbers = phoneNumber
  //       .replace(/-/g, '')
  //       .replace(/\(/g, '')
  //       .replace(/\)/g, '')
  //       .replace(/ /g, '')

  //   if(phoneOnlyNumbers.length < 10) {
  //       setValidationMessage(t('invalid_phone_number'));
  //       return;
  //   }
  //   setLoading(true);
  //   const response = await sendSms(phoneNumber);
  //   setValidationMessage(null);
  //   setLoading(false);
  //   if(response) {
  //     setLinkSent(true);
  //   } else {
  //     setValidationMessage(t('something_went_wrong'));
  //   }
  // };

  // const linkSentText = (smsSentText) => <Typography component="div">{smsSentText}</Typography>

  // const sendSmsForm = (actionText, sendSmsText) => (
  //   <Grid 
  //       item container
  //       spacing={0}
  //       // alignItems="center"
  //       // justifyContent="center"
  //   >
  //     <Grid item xs={12}>
  //       <label htmlFor="phone-input">{sendSmsText}</label>
  //     </Grid>
  //     <Grid item xs={12} sm={6}>
  //       <TextField
  //         fieldId="phone-input"
  //         format="(###) ###-####"
  //         className={classes.phoneInput}
  //         error={validationMessage}
  //         value={phoneNumber}
  //         onChange={(e) => setPhoneNumber(e.target.value)}
  //         placeholder={t('enter_phone_number')}
  //       />
  //     </Grid>
  //     <Grid item xs={12} sm={6}>
  //       {/* ^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$ */}
  //       <Button 
  //           style={{
  //               borderBottomLeftRadius: "0px", 
  //               borderTopLeftRadius: "0px",
  //               height: "48px",
  //               marginLeft: "-5px",
  //           }} 
  //           disabled={loading}
  //           onClick={handleButtonClick} 
  //       >
  //           {actionText}
  //       </Button>
  //     </Grid>
  //   </Grid>
  // );

  const mobileStores = () => (
    <Grid container className={classes.appstoresLogo}>
    <Grid item xs={12} >
        <a target='_blank' href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500">
            <img
                // style={{ marginLeft: '-10px' }}
                src='/img/landing/app_store.svg'
                className={classes.storeLink + " " + classes.firstAppImage}
                alt="App store icon"
            />
        </a>
        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA">
            <img
                src='/img/landing/play_store.svg'
                className={classes.storeLink}
                alt="Play store icon"
            />
        </a>
    </Grid>
</Grid>
  );

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container + ' ' + classes.appContainer}>
        <Grid item xs={12} md={7}>
          <Typography
            variant="h2"
            marked="center"
            className={classes.bigTitle + ' ' + classes.textInfo}
            component="h2"
          >
            {title}
          </Typography>
          <Typography component="div" marked="center" className={classes.body1 + ' ' + classes.textInfo}>
            {text}
            <br />
          </Typography>
          <Grid container className={classes.appstoresLogo}>
            {mobileStores() 
                // isMobile 
                // ? mobileStores() 
                // : (
                //     linkSent 
                //     ? linkSentText(smsSentText)
                //     : sendSmsForm(actionText, sendSmsText)
                // )
            }
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

DownloadAppsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadAppsSection);
