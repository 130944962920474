import { ApolloClient, InMemoryCache, Observable, createHttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import https from 'https';

const GRAPHQL_URL = (process.env.STRAPI_URL || 'https://cms.remitbee.com') + '/graphql';

const isStagingEnv = process.env.REACT_APP_ENV === 'staging';

const stagingHttpLinkOpts = isStagingEnv ?
    {
        fetchOptions: {
            agent: new https.Agent({
                rejectUnauthorized: false
            })
        }
    }
    : {};

const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
    ...stagingHttpLinkOpts
});

const timeoutLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
        const handle = setTimeout(() => {
            observer.error(new Error('Request timeout'));
        }, 12000); // 12 second timeout

        forward(operation).subscribe({
            next: response => {
                clearTimeout(handle);
                observer.next(response);
            },
            error: error => {
                clearTimeout(handle);
                observer.error(error);
            },
            complete: () => {
                clearTimeout(handle);
                observer.complete();
            },
        });
    });
});

const timeoutHttpLink = ApolloLink.from([timeoutLink, httpLink]);

// const originMiddleware = new ApolloLink((operation, forward) => {
//     operation.setContext(({ headers = {} }) => ({
//         headers: {
//             ...headers,
//             // origin: `https://remitbee.com`
//         },
//     }));
//     return forward(operation);
// });

const link = ApolloLink.from([timeoutHttpLink]);

const apolloClientOpts = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
};

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: apolloClientOpts
});

export default client;
