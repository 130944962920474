const styles = () => ({
    card: {
        boxShadow: '5px 6px 20px rgba(11, 46, 100, 0.25)',
        background: '#fff',
        borderRadius: '10px',
        width: '100%',
        marginTop: '3em',
        padding: '30px 20px 30px 20px',
    },
    accordion: {
        marginTop: '30px',
        width: '100%',
    },
    cellContent: {
        margin: 'auto',
        display: 'block',
    },
    table: {
        borderCollapse: 'separate',
    },
    valueStyle: {
        fontSize: '16px',
        color: '#6B708F',
        lineHeight: '1.7',
    },
    captionStyle: {
        fontSize: '16px',
        color: '#37517A',
        "@media (max-width: 959px)": {
           fontSize:'22px',
           paddingBottom: '15px',
           textAlign:'center',
        },
        "@media (max-width: 370px)": {
            fontSize:'20px',
         },
    },
    listBoxes: {
        borderRadius: '10px'
    },
    valueLink: {
        cursor: 'pointer',
        color: '#1160F7'
    }
})

export default styles;