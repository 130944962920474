import { makeStyles } from '@mui/styles';
import appleStore from '../../../../../public/img/landing/app-store-download.png';
import googleStore from '../../../../../public/img/landing/google-play-download.png';

const styles = makeStyles(theme => ({
    appleStore: {
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '0px'
        },
    },
    appleStoreImage: {
        "@media (max-width: 454px)": {
            marginBottom: '20px'
        },
    }    
}))
function AppStoreButton (){
    const classes = styles();
    const appleStoreURL = encodeURI("https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500");
    const googleStoreURL = encodeURI("https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA");
    
    return (
        <div style={{marginBottom: '20px'}}>
            <a href={appleStoreURL} target="_blank" className={classes.appleStore}>
                <img src={appleStore} className={classes.appleStoreImage} alt="apple store icon"/>
            </a>
            <a href={googleStoreURL} target="_blank">
                <img src={googleStore} alt="google store icon"/>
            </a>                                        
        </div>
    )
}

export default AppStoreButton;
