import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import OperatorCardPublic from './OperatorCardPublic';
import { container } from '../../globalStyles'
import { Box, Container, Typography } from '@mui/material';
import { Button } from '../../../common';
import { getCountriesPublicQuery, getOperatorsByServiceAndCountryPublic } from '../../../../redux/services/actions';
import Skeleton from '@mui/material/Skeleton';
import CountrySelection from '../../../services-v2/CountrySelection';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    ...container,
    margin: '80px 10px',
    [theme?.breakpoints?.down('sm')]: {
      margin: '60px 0'
    }
  },
  giftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '32px',
    alignSelf: 'stretch',
    marginTop: '48px',
  },
  countryDropDown: {
    maxWidth: '100%',
    width: '500px',
    textAlign: 'left',
    margin: '0 auto',
  },
  bigTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    width: 'calc(100% + 2%)',
    lineHeight: '60px',
    color: '#31517A',
    [theme?.breakpoints?.up('sm')]: {
      fontSize: '48px',
      lineHeight: '72px',
    },
    [theme?.breakpoints?.down('sm')]: {
      fontSize: '30px',
      lineHeight: '45px',
    },
  },
  text: {
    width: '80%',
    display: 'block',
    fontFamily: 'Inter',
    fontWeight: 400,
    textAlign: 'center',
    color: '#626680',
    [theme?.breakpoints?.up('sm')]: {
      fontSize: '18px',
      lineHeight: '34px',
    },
    [theme?.breakpoints?.down('sm')]: {
      fontSize: '16px',
      lineHeight: '26px',
      display: 'contents',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    alignSelf: 'stretch',
    textAlign: 'center'
  },
  operators: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '32px',
    fontFamily: 'Inter',
    [theme?.breakpoints?.down('sm')]: {
        gap: '16px'
    }
  }
}));



const GiftOperators = ({ title, text, defaultCountry = 'India', serviceId }) => {
    const classes = useStyles();
    const [selectedCountry,setSelectedCountry] = useState(0);
    const [selectedService] = useState(serviceId ? parseInt(serviceId) : 2);
    const [viewMore,setViewMore] = useState(false);
    const [countriesLoading,setCountriesLoading] = useState(false)
    const [operatorsLoading,setOperatorsLoading] = useState(false)
    const [countries,setCountries] = useState([]);
    const [operators,setOperators] = useState([]);

    const handleCountryChange = (newCountry) => {
        setSelectedCountry(newCountry?.countryId)
    }

    const loadCountries = async () => {
      let { data, success } = await getCountriesPublicQuery(selectedService);
      if(success && data && data.getCountriesPublic) {
        const countryList = data.getCountriesPublic?.countries;
        if(!countryList) return;
        let selectedCountry = null;
        setCountries(countryList);
        countryList.forEach(country => {
          if(country.name === defaultCountry)
            selectedCountry = country.countryId;
        });
        if(!selectedCountry)
          selectedCountry = countryList.filter(country => country.name === 'United States')[0]?.countryId || countryList[0]?.countryId;
        setSelectedCountry(selectedCountry)
      }
     }

     const loadOperatorsByCountry = async (countryId) => {
      if(!selectedCountry) return;
      const { data, success } = await getOperatorsByServiceAndCountryPublic(countryId, selectedService);
      if(success && data && data.getOperatorsByServicesAndCountryPublic) {
        setOperators(data.getOperatorsByServicesAndCountryPublic?.operators)
      }
     }

    useEffect(() => {
       setCountriesLoading(true)
       loadCountries()
       setCountriesLoading(false)
    }, [])

    useEffect(() => {
      setOperatorsLoading(true)
      setOperators([])
      if(selectedCountry) loadOperatorsByCountry(selectedCountry)
      setOperatorsLoading(false)
    }, [selectedCountry])

  return (
    <Container className={classes.wrapper}>
    <Box className={classes.header}>
       <Typography component="div" variant="h2" className={classes.bigTitle}>{title}</Typography> 
       <Typography component="div" variant="h2" className={classes.text}>{text}</Typography>
    </Box>    
    <div className={classes.giftContainer}>
        <section>
            {(!countriesLoading && countries.length) ? <div className={classes.countryDropDown}>
            <CountrySelection
             countries={countries}
             countryId={selectedCountry}
             handleCountryChange={handleCountryChange}
             label={<span>Available vendors for<span className="field__label-required" /></span>}
             placeholder="Select a country"
            />
            </div> : <Skeleton width={500} height={48} variant='rectangular'></Skeleton>}
        </section>
        {(!operatorsLoading && operators.length) ? <div className={classes.operators}>
            {operators.slice(0,!viewMore ? 12 : operators.length ).map(operator => (
                <OperatorCardPublic
                  key={operator.label}
                  label={operator.label}
                  image={operator.imageUrl}
                />
            ))}
        </div> : <div className={classes.operators}>{[...new Array(4)].map((each,index) => <Skeleton key={index} variant='rectangular' width={220} height={150} />)}</div>}
        <Button onClick={() => setViewMore(!viewMore)} fullWidth style={{background: 'none !important'}} variant='text'>{!viewMore ? "View all" : "Show less"}</Button>
    </div>
    </Container>
  )
}

export default GiftOperators