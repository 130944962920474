import React from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(5)
  },
  title: {
    borderLeft: '3px solid #1160F7',
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    textAlign: 'left',
    color: '#31517A',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600
  },
  content: {
    margin: theme.spacing(2, 3, 0),
    textAlign: 'left',
    color: '#626680',
    fontSize: '16px',
    lineHeight: '24px'
  },
  index: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: theme.spacing(3),
  }
});

const NumberedSteps = ({ list, classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (list)
    return (
      <Grid container className={classes.container} spacing={isMobile ? 4 : 0}>
        {list.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <div className={classes.index}>
            <img src={`/new/landing/steps/${index+1}.svg`} alt={`Number ${index + 1}`} />
            </div>
            <Typography variant='h2' className={classes.title}>{item.title}</Typography>
            <Typography className={classes.content}>
              <Markdown children={item.text} />
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  else return null;
};

export default withStyles(styles)(NumberedSteps);
