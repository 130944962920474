import { Box, Link } from '@mui/material';
import { Accordion as ExpansionPanel } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { FullColumn, Row } from './rowColumn';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    expansionPanel: {
        border: '1px solid #e9e9e9'
    },
    heading: {
        fontSize: '18px',
        color: theme.palette.primary.dark,
        fontWeight: 500
    },
    link: {
        fontSize: '16px'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    divider: {
        height: '1.5px',
        marginBottom: '20px',
        backgroundColor: '#E9E9E9'
    },
    pt_10: { paddingTop: 10 },
    detailsRoot: {
        padding: theme.spacing(0, 4, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3, 3),
        }
    },
    summaryRoot: {
        minHeight: 0,
        padding: theme.spacing(3)
    },
    summaryExpanded: {
        minHeight: '0 !important',
        margin: theme.spacing(0, 1, 0),
        marginBottom: '-24px',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginRight: 0,
            marginLeft: 0
        }
    },
    summaryContent: {
        margin: '0 !important'
    }
}));

function Accordion({ editable = false, defaultExpanded = false, onClickEdit, heading, content, toogle, className, contentClass }) {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [isExpanded, setIsExpanded] = useState(defaultExpanded)

    return (
        <div className={classes.root + " " + className}>
            <ExpansionPanel elevation={0} className={contentClass} defaultExpanded={defaultExpanded} expanded={isExpanded} classes={{ root: classes.expansionPanel }}>
                <AccordionSummary classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded, content: classes.summaryContent }}>
                    <Grid container justifyContent="space-between">
                        <Box display='flex' style={{ width: '100%' }} alignItems='center'>
                            <Box flexGrow={1}>
                                <Typography className={classes.heading}>{heading}</Typography>
                            </Box>
                        
                            {editable &&
                                <Box>
                                    <Link className={classes.link} onClick={() => {
                                        isExpanded ? onClickEdit ? onClickEdit() : {} : setIsExpanded(true)
                                    }}>{isExpanded ? t('Edit') : t('View')}</Link>
                                </Box>
                            }
                        </Box>

                        {toogle &&
                            <Grid xs={1} item>
                                <Link onClick={() => {
                                    isExpanded ? setIsExpanded(false) : setIsExpanded(true)
                                }}>{<img style={isExpanded ? {} : { transform: 'rotate(180deg)' }} src="/icons/other/expand.png" alt="Expand" />}</Link>
                            </Grid>
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.detailsRoot }}>
                    <Row>
                        <FullColumn className={classes.pt_10}>
                            {content}
                        </FullColumn>
                    </Row>
                </AccordionDetails>
            </ExpansionPanel>
        </div>
    );
}

Accordion.propTypes = {
    editable: bool,
    defaultExpanded: bool,
    onClickEdit: func
}

export default Accordion