import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = theme => ({
  container: {
    paddingBottom: '40px',
    background: "url('/new/landing/quote.svg') right bottom no-repeat",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '15px',
      backgroundSize: '67px 61px'
    }
  },
  tag: {
    textAlign: 'left',
    fontFamily: 'Poppins, Heebo',
    paddingBottom: theme.spacing(2),
    fontWeight: 700,
    color: '#1160F7',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    }
  },
  quote: {
    textAlign: 'left',
    fontFamily: 'Poppins, Heebo',
    fontWeight: 500,
    color: '#31517A',
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '64px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '54px',
    }
  }
});

const Quote = ({classes, tag, quote}) => {
  return (
    <Grid item container className={classes.container}>
      <Grid item xs={12}>
        {tag && <Typography component="div" className={classes.tag}>{tag}</Typography>}
      </Grid>
      <Grid item xs={10}>
        {quote && <Typography component="div" className={classes.quote}>{quote}</Typography>}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Quote);
