import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { useRouter } from 'next/router';
import React, { useRef, useEffect, useState } from 'react';
import { getImageAlt, parseArrFromString } from '../../../../shared/utility';
import Tabs from '../../components/Tabs/Tabs';
import { container, whiteColor } from '../../globalStyles';
import VisibilitySensor from 'react-visibility-sensor';
import VideoPopUp from '../../../common/VideoPopUp';
import NextImage from 'next/image';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import Spacing from '../../../../remitbee/styles/spacing/Spacing';
import Typography from '../../../../remitbee/components/typography/Typography';
import Button from '../../../../remitbee/components/button/Button';
import CustomUnderlineBigHeading from '../../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import Image from '../../../../remitbee/components/image/Image';

const styles = (theme) => ({
  container,
  body1: {
    color: '#626680',
    lineHeight: '34px',
    fontFamily: 'Inter',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  },
  root: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      clipPath: 'none',
    },
  },
  fullImageRoot: {
    minWidth: '425px',
  },
  imageLogoutWorld: {
    maxWidth: '100%',
    marginLeft: '-90px'
  },
  textAligner: {
    //marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  textInfo: {
    fontFamily: 'Inter',
    //marginBottom: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      lineHeight: '40px'
    },
  },
  textInfoMobile: {
    fontFamily: 'Inter',
    //marginBottom: theme.spacing(2),
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      textAlign: 'center',
      lineHeight: '40px'
    },
  },
  textInfoNoMargin: {
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  mobileActionButton: {
    display: 'none',
    '@media (max-width: 500px)': {
      display: 'flex',
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '18px'
    },
  },
  image: {
    maxWidth: '100%',
  },
  imageZoom: {
    maxWidth: '100%',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '350px',
    },
  },
  actionButton: {
    marginTop: theme.spacing(4),
  },
  alignContainer: {
    alignItems: 'center',
  },
  colored: {
    backgroundColor: whiteColor,
  },
  subtitle: {
    color: '#626680',
    fontWeight: 400,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '44px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '38px',
    }
  },
  tabs: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  list: {
    padding: 0,
    marginTop: theme.spacing(2)
  },
  noPadding: {
    padding: 0,
  },
  listIcon: {
    minWidth: '36px'
  },
  listText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '27px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  },
  listItem: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    paddingTop: 0,
    color: '#626680',
    lineHeight: '27px',
    backgroundColor: 'transparent !important',
    "&:hover": {
      backgroundColor: 'transparent !important'
    }
  },
  listItemText: {
    margin: 0,
    fontFamily: 'Inter'
  },
  color: {
    color: "#FFFFFF"
  },
  mobileTag: {
    fontFamily: 'Inter',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    color: '#1160F7',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '23.5px',
    }
  },
  tag: {
    fontFamily: 'Inter',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    color: '#1160F7',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '23.5px',
    }
  },
  alignStart: {
    alignItems: 'flex-start'
  },
  imageContainerAlignLeft: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(0),
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6),
    },
  },
  imageContainerAlignRight: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      height: '100%',
    },
  },
  imageContainerAlignBottom: {
    width: '100%',
    maxHeight: 350,
    paddingTop: theme.spacing(5)
  },
  leftBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6)
    }
  },
  rightBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6)
    }
  },
  iframeDiv: {
    display: 'flex',
    justifyContent: 'right',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '720px'
    },
  },
  iframe: {
    height: '364px',
    borderRadius: '20px',
    borderStyle: 'solid',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  videodiv: {
    display: 'flex',
    justifyContent: 'right',
    paddingRight: theme.spacing(2),
  },
  video: {
    height: '364px',
    width: '324px',
    borderStyle: 'solid',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  videoZoom: {
    maxWidth: '100%',
    width: 'auto',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '400px'
  },
  fullImageColumn: {
    left: '10% !important',
    right: 'auto !important',
    height: '80% !important',
    minHeight: '80% !important',
    marginBottom: "0px !important",
    minWidth: '425px !important',
    maxWidth: '100% !important',
    maxHeight: '80% !important',
    [theme.breakpoints.down('sm')]: {
      left: '10% !important',
      right: 'auto !important',
      height: '80% !important',
      minHeight: '80% !important',
      marginBottom: "0px !important",
      minWidth: '425px !important',
      maxWidth: '100% !important',
      maxHeight: '80% !important',
    },
  },
  imageContainer: {
    position: 'relative', width: '100%', height: '100%'
  },
  stepsWrapper: {
    maxWidth: '600px',
    margin: '0 auto',
    borderRadius: '10px',
    textAlign: 'left',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    marginBottom: '24px',
    padding: '24px',
    border: '1px solid #CACCD5',
  },
  stepNumber: {
    border: '1px solid #1160F7',
    color: '#007bff',
    fontWeight: 'bold',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '24px',
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px',
    },
    [theme.breakpoints.down('md')]: {
      width: '25px',
      height: '25px',
    },
  },
  stepNumberWithoutBorder: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '24px',
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px',
    },
    [theme.breakpoints.down('md')]: {
      width: '25px',
      height: '25px',
    },
  },
  stepContent: {
    width: "400px",
    [theme.breakpoints.down('md')]: {
      width: "250px",
    }
  },
  stepContentTitle: {
    fontSize: '18px !important',
    color: '#31517A !important',
    fontWeight: '700 !important',
    fontFamily: 'Inter !important',
  },
  stepContentText: {
    fontSize: '16px',
    color: '#626680',
    fontFamily: 'Inter',
  }
});

const ColumnImage = (props) => {
  const {
    classes,
    title,
    text,
    image,
    video,
    reverse,
    colored,
    actionText,
    actionUrl,
    tag,
    list,
    actionType,
    ImageProps,
    TextProps,
    TitleProps,
    ButtonProps,
    underline,
    subtitle,
    imageAlt,
    backgroundImage,
    color,
    bankInfo,
    bankInfoImage,
    element,
    promotion,
    tabs,
    centerTitleOnMobile,
    reverseOrder,
    zoom,
    VideoProps,
    videoPop,
    fullSizeImage,
    paddingTop = false,
    social,
    type
  } = props;
  const videoRef = useRef();
  const containerRef = useRef();
  const router = useRouter()
  const content = parseArrFromString(list);
  const tabsContent = tabs ? parseArrFromString(tabs) : null;
  const socialContent = social ? parseArrFromString(social) : null;
  const videoProps = VideoProps ? parseArrFromString(VideoProps)[0] : null;
  const alt_tag = imageAlt || getImageAlt(image);
  const logo_alt_tag = getImageAlt(bankInfoImage);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const rootStyle = { paddingTop: paddingTop ? isMobile ? '40px' : '80px' : '0px' };
  const colorStyles = color ? { color } : null;
  const reverseOrderOnMobile = isMobile && reverseOrder;
  const [height, setHeight] = useState('100%'); // eslint-disable-line no-unused-vars
  const [isVisible, setIsVisible] = useState(false);

  const [imageRatio, setImageRatio] = useState(0);
  const [imageHeight, setImageHeight] = useState();

  function getButtonStyle(input) {
    const styles = {
        green: "filledPrimary",
        white: "filledWhite"
    };
    return styles[input] || "filled";
  }
  const [buttonActionType, setButtonActionType] = React.useState(getButtonStyle(actionType));
  React.useEffect(() => {
    setButtonActionType(getButtonStyle(actionType));
  },[actionType]);

  useEffect(() => {
    if (containerRef.current && imageRatio) {
      const height = isMobile ?
        (containerRef.current.getBoundingClientRect().width + 40) * imageRatio :
        (containerRef.current.getBoundingClientRect().width - 48) * imageRatio
        ;
      setImageHeight(`${height}px`)
    }
  }, [imageRatio, isMobile, containerRef && containerRef.current && containerRef.current.getBoundingClientRect().width])

  if (videoProps && videoProps.loop && typeof videoProps.loop === 'string') videoProps.loop = Boolean(videoProps.loop)
  let videoPropsNew = { ...videoProps };
  delete videoPropsNew.videoProps1
  delete videoPropsNew.videoProps2

  useEffect(() => {
    if (zoom && videoRef && videoRef.current) {
      if (videoProps && videoProps.speed) videoRef.current.playbackRate = videoProps.speed;
      const value = containerRef.current.getBoundingClientRect().width * 65 / 96 * zoom;
      setHeight(`${value}px`);
    }
  }, [videoRef])

  // useEffect(() => {
  //   if (zoom && image) {
  //     const img = new Image();
  //     img.src = image;
  //     const size = containerRef.current.getBoundingClientRect().width;
  //     img.onload = () => {
  //       const value = img.height / (img.width / size) * zoom;
  //       setHeight(`${value}px`);
  //     };
  //   }
  // }, [image])

  useEffect(() => {
    if (isVisible && !video.includes('youtube.com/embed/')) {
      videoRef.current.play();
    }
  }, [isVisible]);

  if (backgroundImage) {
    rootStyle.background = `url(${backgroundImage}) no-repeat center`;
    rootStyle.backgroundSize = 'cover';
  }
  if (isMobile) {
    if (backgroundImage) {
      rootStyle.background = 'transparent';
    }
  }
  rootStyle.backgroundColor = colored ? colored : 'transparent';

  const goToUrl = async () => {
    let query = null;
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (actionUrl && actionUrl.includes('signup?')) {
      router.push(actionUrl);
      return
    }
    if (promotion) {
      query = addReferralLink()
    }
    router.push({ pathname: actionUrl, query });
  }

  const addReferralLink = () => {
    let _args = {}
    _args = { promo: promotion };
    return _args;
  }

  const renderList = () => {
    if (content) return (
      <List dense className={classes.list}>
        {content.map((item, index) => (
          <ListItem disableGutters classes={{ root: classes.listItem }} key={index}>
            <ListItemIcon className={classes.listIcon}>
              <img src="/new/list-icon.svg" alt="List icon" width="21px" height="21px" />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              {typeof item.text == 'string' ? <Markdown children={item.text} /> : item.text}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <div
      className={classes.root}
      style={rootStyle}
    >
      <Grid
        container
        className={classnames(classes.container, classes.noPadding, (bankInfo) ? classes.alignStart : classes.alignContainer)}
        direction={reverse ? 'row' : 'row-reverse'}
        alignItems="center"
      >
        {image && !reverseOrderOnMobile && (
          <Grid
            item
            xs={10}
            md={6}
            className={classnames(reverse ? classes.imageContainerAlignLeft : classes.imageContainerAlignRight)}
            ref={containerRef}
          >
            {/* <div className={zoom ? classes.wrapper : null} style={{ height: height }}>
              <img src={image} style={zoom ? { transform: `scale(${zoom})` } : null} className={element === 'first' && !isMobile ? classes.imageLogoutWorld : zoom ? classes.imageZoom : classes.image} alt={alt_tag} {...ImageProps} loading="lazy" />
            </div> */}
            {/* <div className={!fullSizeImage && classes.imageContainer}> */}
              <Image
                className={classnames(element === 'first' && !isMobile ? classes.imageLogoutWorld : classes.image, fullSizeImage && classes.fullImageColumn)}
                alt={alt_tag}
                // layout='fill'
                // loader={({ src, width }) => `${src}?w=${width}`}
                // loading='lazy'
                url={image}
                {...ImageProps}
                // onLoad={({ naturalWidth, naturalHeight }) => {
                //   setImageRatio(naturalHeight / naturalWidth)
                // }}
              />
            {/* </div> */}
          </Grid>
        )}
        {video &&
          <Grid item xs={12} md={6} className={classnames(reverse ? classes.imageContainerAlignLeft : classes.imageContainerAlignRight)} ref={containerRef}>
            <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
              {video.includes('youtube.com/embed/') ?
                <div className={classes.iframeDiv}>
                  <iframe {...videoPropsNew} src={video} loading="eager" className={classes.iframe} allowFullScreen />
                </div>
                :
                <div className={zoom ? classes.wrapper : classes.videodiv}>
                  <video muted {...videoPropsNew} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" controlsList='nodownload' className={zoom ? classes.videoZoom : classes.video} controls={!videoProps} loop={videoProps} ref={videoRef} />
                </div>
              }
            </VisibilitySensor>
          </Grid>
        }
        <Grid item xs={12} md={6}>
          {bankInfoImage && <img src={bankInfoImage} alt={logo_alt_tag} loading="lazy" />}
          {tag && (
            <Typography variant='body1' className={centerTitleOnMobile ? classes.mobileTag : classes.tag}>{tag}</Typography>
          )}
          <Typography
            variant="h2"
            marked="center"
            className={centerTitleOnMobile ? classes.textInfoMobile : classes.textInfo}
            style={colorStyles}
            {...TitleProps}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" style={colorStyles} className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
          {underline && <CustomUnderlineBigHeading color="#1160F7" />}
          {text && <div className={classnames(classes.body1, classes.textAligner)} style={colorStyles} {...TextProps}>
            {typeof text == 'string' ? <Markdown children={text} /> : text}
            {
              socialContent && <>
                <div style={{ display: 'flex', gap: "10px", paddingTop: '20px' }}>
                  {socialContent.map((item) => (
                    <a href={item.link}>
                      <img src={item.path} alt="Facebook" />
                    </a>
                  ))}
                </div>
              </>
            }

          </div>}
          {
            type === 'step' && content?.length > 0 &&
            <>
              <Spacing variant='betweenSectionToInputFields' />
              {content.map((step, index) => (
                <div className={classes.stepsWrapper}>
                  <div className={`${classes.stepContainer}`
                  }>
                    <div className={content?.length - 1 === index ? classes.stepNumberWithoutBorder : classes.stepNumber}>
                      {content?.length - 1 === index ? <img src='Number.svg' /> : <span>{step.number}</span>}
                    </div>
                    <div className={classes.stepContent}>
                      <p className={classes.stepContentTitle}>{step.title}</p>
                      <p className={classes.stepContentText}><Markdown children={step.description} /></p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          }
          {tabsContent &&
            <div className={classes.tabs}>
              <Tabs data={tabsContent} />
            </div>
          }
          {type !== 'step' && list && renderList()}
          {actionText && element !== 'first' ?
            <Button
              className={classes.actionButton}
              variant={buttonActionType || 'filled'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </Button>
            : null}
        </Grid>
        {image && reverseOrderOnMobile && (
          <Grid
            item
            xs={12}
            md={6}
            className={classes.imageContainerAlignBottom}
            style={{ height: !fullSizeImage ? imageHeight : '100%' }}
          >
            {/* <div style={{ position: 'relative', width: '100%', height: '100%' }}> */}
              <Image
                className={element === 'first' && !isMobile ? classes.imageLogoutWorld : classes.image}
                alt={alt_tag}
                // layout='fill'
                // loader={({ src, width }) => `${src}?w=${width}`}
                // loading='lazy'
                url={image}
                {...ImageProps}
                // onLoad={({ naturalWidth, naturalHeight }) => {
                //   setImageRatio(naturalWidth / naturalHeight)
                // }}
              />
            {/* </div> */}
          </Grid>
        )}

        {video && isMobile && videoPop && <VideoPopUp video={video} videoPop={videoPop} />}
        {actionText && <Grid item xs={12}>
          {element === 'first' ?
            <Button
              className={classes.mobileActionButton}
              variant={buttonActionType || 'filled'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </Button>
            : null}
        </Grid>}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ColumnImage);