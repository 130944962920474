const tableCellStyle = { borderRight: '1px solid #CACCD5' };
export const REMITLY = 'remitly';
export const TRANSFER_WISE = 'transfer_wise';
export const WORLD_REMIT = 'world_remit';
export const XOOM = 'xooom';
export const CIBC = 'cibc';
export const REMITBEE = 'remitbee';
export const SRI_LANKA = 210;
export const INDIA = 103;
export const USA = 236;
export const EUR = 235;
export const PHP = 175;
export const CAN = 40;
export const SCOTIABANK = 'scotiabank';
export const TD_BANK = 'td_bank';
export const BMO = 'bmo';
export const RBC = 'rbc';
export const NATIONAL_BANK = 'national_bank';


export const activeCountries = [SRI_LANKA, INDIA, USA, EUR, PHP];

export const tableRows = [
    [
        {
            label: 'Instant Transfer Time',
            type: 'string',
        },
        {
            label: 'Same day',
            type: 'string',
        },
        {
            label: 'Same day',
            type: 'string',
        },
        {
            label: 'Same day',
            type: 'string',
        },
        {
            label: 'Same day',
            type: 'string',
        },
        {
            label: 'Same day',
            type: 'string',
        },
        {
            label: 'blank',
            type: 'icon',
        },
    ],
    [
        {
            label: 'Economic Transfer time',
            type: 'string',
        },
        {
            label: '1 Day',
            type: 'string',
        },
        {
            label: '3-5 Days',
            type: 'string',
        },
        {
            label: '3 business Days',
            type: 'string',
        },
        {
            label: '3 business Days',
            type: 'string',
        },
        {
            label: '3 business Days',
            type: 'string',
        },
        {
            label: '2 Days',
            type: 'string',
        },
    ],
    [
        {
            label: 'Pay with E-transfer',
            type: 'string',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
    ],
    [
        {
            label: 'Pay With Bill payment',
            type: 'string',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
    ],
    [
        {
            label: 'Pay with EFT',
            type: 'string',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
    ],
    [
        {
            label: 'Pay with Debit',
            type: 'string',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'check',
            type: 'icon',
        },
    ],
    [
        {
            label: 'Pay with interac online',
            type: 'string',
        },
        {
            label: 'check',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
        {
            label: 'blank',
            type: 'icon',
        },
    ],
];

export const labelRows = ['Instant Option Transfer Time', 'Economic Transfer time', 'Pay with E-transfer', 'Pay With Bill payment', 'Pay with EFT', 'Pay with Debit', 'Pay with interac online'];

export const mobileRows = [
    {
        labelPath: '/remitbee_logo.png',
        provider: REMITBEE,
        content: []
    },
    {
        labelPath: '/icons/other/scotiabank.svg',
        provider: SCOTIABANK,
        content: [],
    },
    {
        labelPath: '/icons/other/cibc_logo.svg',
        provider: CIBC,
        content: [],
    },
    {
        labelPath: '/icons/other/td_bank.svg',
        provider: TD_BANK,
        content: [],
    },
    {
        labelPath: '/icons/other/bmo.svg',
        provider: BMO,
        content: [],
    },
    {
        labelPath: '/icons/other/rbc.svg',
        provider: RBC,
        content: [],
    },
    {
        labelPath: '/icons/other/national_bank.svg',
        provider: NATIONAL_BANK,
        content: [],
    }
]

export const transferMobileRows = [
    {
        labelPath: '/remitbee_logo.png',
        provider: REMITBEE,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '1 Day'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            }
        ]
    },
    {
        labelPath: '/icons/other/remitly_logo.png',
        provider: REMITLY,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '3-5 Days'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'blank'
            }
        ],
    },
    {
        labelPath: '/icons/other/transfer_wise_logo.svg',
        provider: TRANSFER_WISE,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '3 business Days'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'blank'
            }
        ],
    },
    {
        labelPath: '/icons/other/world_remit_logo.svg',
        provider: WORLD_REMIT,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '3 business Days'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'blank'
            }
        ],
    },
    {
        labelPath: '/icons/other/xoom_logo.svg',
        provider: XOOM,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '3 business Days'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'blank'
            }
        ],
    },
    {
        labelPath: '/icons/other/cibc_logo.svg',
        provider: CIBC,
        content: [
            {
                type: 'string',
                label: 'Same day'
            },
            {
                type: 'string',
                label: '2 Days'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'blank'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'check'
            },
            {
                type: 'icon',
                label: 'blank'
            }
        ],
    },
]

export const transferRates = {
    [PHP]: [
        {
            value: 36.4300,
            provider: REMITLY
        },
        {
            value: 36.8040,
            provider: REMITBEE
        },
        {
            value: 36.9146,
            provider: TRANSFER_WISE
        },
        {
            value: 36.3375,
            provider: WORLD_REMIT
        },
        {
            value: 36.356,
            provider: XOOM
        },
        {
            value: 36.451,
            provider: CIBC
        }
    ],
    [INDIA]: [
        {
            value: 56.1050,
            provider: REMITLY
        },
        {
            value: 56.4625,
            provider: REMITBEE
        },
        {
            value: 56.4762,
            provider: TRANSFER_WISE
        },
        {
            value: 56.1136,
            provider: WORLD_REMIT
        },
        {
            value: 56.156,
            provider: XOOM
        },
        {
            value: 56.106,
            provider: CIBC
        }
    ],
    [USA]: [
        {
            value: null,
            provider: REMITLY
        },
        {
            value: 0.7606,
            provider: REMITBEE
        },
        {
            value: 0.7644,
            provider: TRANSFER_WISE
        },
        {
            value: 0.7562,
            provider: WORLD_REMIT
        },
        {
            value: 0.7562,
            provider: XOOM
        },
        {
            value: 0.7510,
            provider: CIBC
        }
    ],
    [SRI_LANKA]: [
        {
            value: 135.3600,
            provider: REMITLY
        },
        {
            value: 145.7500,
            provider: REMITBEE
        },
        {
            value: 141.5934,
            provider: TRANSFER_WISE
        },
        {
            value: 140.9366,
            provider: WORLD_REMIT
        },
        {
            value: 140.5934,
            provider: XOOM
        },
        {
            value: 140.334,
            provider: CIBC
        }
    ],
    [EUR]: [
        {
            value: null,
            provider: REMITLY
        },
        {
            value: 0.6379,
            provider: REMITBEE
        },
        {
            value: 0.6424,
            provider: TRANSFER_WISE
        },
        {
            value: 0.6321,
            provider: WORLD_REMIT
        },
        {
            value: 0.6324,
            provider: XOOM
        },
        {
            value: 0.6340,
            provider: CIBC
        }
    ],
}

export const spotRateMapping = {
    [PHP]: 36.98,
    [CAN]:1.28242,
    [INDIA]: 56.75,
    [USA]: 1.28242,
    [SRI_LANKA]: 142.57,
    [EUR]: 0.64 
}

export const feeRates = {
    [INDIA]: {
        [REMITLY]: [
            {
                rate: 100,
                fee: 1.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [REMITBEE]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [TRANSFER_WISE]: [
            {
                rate: 100,
                fee: 2.93
            },
            {
                rate: 500,
                fee: 5.91
            }, {
                rate: 1000,
                fee: 9.63
            }, {
                rate: 3000,
                fee: 24.52
            },
            {
                rate: 9000,
                fee: 69.18
            }
        ],
        [WORLD_REMIT]: [
            {
                rate: 100,
                fee: 1.99
            },
            {
                rate: 500,
                fee: 4.99
            }, {
                rate: 1000,
                fee: 6.99
            }, {
                rate: 3000,
                fee: 16.99
            },
            {
                rate: 9000,
                fee: 24.99
            }
        ],
        [XOOM]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [CIBC]: [
            {
                rate: 100,
                fee: 1.99
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
    },
    [PHP]: {
        [REMITLY]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [REMITBEE]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [TRANSFER_WISE]: [
            {
                rate: 100,
                fee: 3.43
            },
            {
                rate: 500,
                fee: 6.57
            }, {
                rate: 1000,
                fee: 10.49
            }, {
                rate: 3000,
                fee: 26.16
            },
            {
                rate: 9000,
                fee: 73.19
            }
        ],
        [WORLD_REMIT]: [
            {
                rate: 100,
                fee: 4.99
            },
            {
                rate: 500,
                fee: 4.99
            }, {
                rate: 1000,
                fee: 4.99
            }, {
                rate: 3000,
                fee: 4.99
            },
            {
                rate: 9000,
                fee: 4.99
            }
        ],
        [XOOM]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [CIBC]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
    },
    [USA]: {
        [REMITLY]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [REMITBEE]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [TRANSFER_WISE]: [
            {
                rate: 100,
                fee: 3.65
            },
            {
                rate: 500,
                fee: 6.23
            }, {
                rate: 1000,
                fee: 9.46
            }, {
                rate: 3000,
                fee: 22.37
            },
            {
                rate: 9000,
                fee: 61.12
            }
        ],
        [WORLD_REMIT]: [
            {
                rate: 100,
                fee: 3.99
            },
            {
                rate: 500,
                fee: 3.99
            }, {
                rate: 1000,
                fee: 3.99
            }, {
                rate: 3000,
                fee: 3.99
            },
            {
                rate: 9000,
                fee: 3.99
            }
        ],
        [XOOM]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [CIBC]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
    },
    [SRI_LANKA]: {
        [REMITLY]: [
            {
                rate: 100,
                fee: 4.99
            },
            {
                rate: 500,
                fee: 4.99
            }, 
            {
                rate: 1000,
                fee: 4.99
            }, 
            {
                rate: 3000,
                fee: 4.99
            },
            {
                rate: 9000,
                fee: 4.99
            }
        ],
        [REMITBEE]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [TRANSFER_WISE]: [
            {
                rate: 100,
                fee: 3.73
            },
            {
                rate: 500,
                fee: 6.94
            }, {
                rate: 1000,
                fee: 10.96
            }, {
                rate: 3000,
                fee: 27.03
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [WORLD_REMIT]: [
            {
                rate: 100,
                fee: 4.99
            },
            {
                rate: 500,
                fee: 5.99
            }, {
                rate: 1000,
                fee: 6.99
            }, {
                rate: 3000,
                fee: 9.99
            },
            {
                rate: 9000,
                fee: 9.99
            }
        ],
        [XOOM]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [CIBC]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
    },
    [EUR]: {
        [REMITLY]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, 
            {
                rate: 1000,
                fee: null
            }, 
            {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [REMITBEE]: [
            {
                rate: 100,
                fee: 2.99
            },
            {
                rate: 500,
                fee: 0
            }, {
                rate: 1000,
                fee: 0
            }, {
                rate: 3000,
                fee: 0
            },
            {
                rate: 9000,
                fee: 0
            }
        ],
        [TRANSFER_WISE]: [
            {
                rate: 100,
                fee: 2.79
            },
            {
                rate: 500,
                fee: 5.38
            }, {
                rate: 1000,
                fee: 8.60
            }, {
                rate: 3000,
                fee: 21.52
            },
            {
                rate: 9000,
                fee: 60.27
            }
        ],
        [WORLD_REMIT]: [
            {
                rate: 100,
                fee: 3.99
            },
            {
                rate: 500,
                fee: 3.99
            }, {
                rate: 1000,
                fee: 3.99
            }, {
                rate: 3000,
                fee: 3.99
            },
            {
                rate: 9000,
                fee: 3.99
            }
        ],
        [XOOM]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
        [CIBC]: [
            {
                rate: 100,
                fee: null
            },
            {
                rate: 500,
                fee: null
            }, {
                rate: 1000,
                fee: null
            }, {
                rate: 3000,
                fee: null
            },
            {
                rate: 9000,
                fee: null
            }
        ],
    }
}

export const exchangeRates = [
        {
            buyRate:1.2761,
            sellRate:1.2892,
            provider:REMITBEE
        },
        {
            buyRate: 1.247,
            sellRate:1.3156,
            provider: SCOTIABANK
        },
        {
            buyRate:1.2347,
            sellRate:1.3275,
            provider:CIBC
        },
        {
            buyRate:1.2456,
            sellRate:1.3192,
            provider:TD_BANK
        },
        {
            buyRate:1.2453,
            sellRate:1.3147,
            provider:BMO
        },
        {
            buyRate:1.2429,
            sellRate:1.3116,
            provider:RBC
        },
        {
            buyRate:1.2435,
            sellRate:1.3115,
            provider:NATIONAL_BANK
        },
    ]

export const trueCostList = {
    [REMITBEE]: 6.32,
    [SCOTIABANK]: 35.42,
    [CIBC]: 47.72,
    [TD_BANK]: 36.82,
    [BMO]: 37.72,
    [RBC]: 39.52,
    [NATIONAL_BANK]: 38.92 
}

export const getTableBodyStyle = (i, index) => {
    let tableCell = { ...tableCellStyle };
    if (i !== tableRows.length - 1) {
        tableCell = { ...tableCell, borderBottom: 'none' }
    }
    if (index === tableRows.length) {
        tableCell = {
            ...tableCell,
            borderRight: 'none'
        }
    }
    if (index === 0) {
        tableCell = {
            ...tableCell,
            borderRight: 'none'
        }
    }
    if (index === 1) {
        tableCell = {
            ...tableCell,
            backgroundColor: '#F3F8FF',
            borderRight: 'none',
        }
    }

    return tableCell;
}

export const getTableBottomStyle = (index) => {
    let bottomStyle = { ...tableCellStyle, textAlign: 'center', color: '#626680', borderBottom: 'none' };
    if (index === mobileRows.length - 1 || index === 0) {
        bottomStyle = {
            ...bottomStyle,
            borderRight: 'none'
        }
    }
    if (index === 0) {
        bottomStyle = {
            ...bottomStyle,
            backgroundColor: '#F3F8FF',
        }
    }

    return bottomStyle;
}