import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Markdown from 'markdown-to-jsx';
//import { FAQPageJsonLd } from 'next-seo';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import { bigTitle, body1, container, mediumTitle, subTitle } from '../globalStyles';

const styles = makeStyles((theme) => ({
  container,
  mediumTitle,
  body1,
  title: {
    ...bigTitle,
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important'
    },
  },
  subTitle: {
    ...subTitle, 
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
    margin: '20px 0 10px 0 !important',
  },
  root: {
    display: 'flex',
    textAlign: 'left',
    paddingTop: '60px',
    paddingBottom: '60px',
    alignItems: 'flex-start',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  faqWrapper: {
    paddingTop: '0 !important',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '60px !important',
    },
  },
  faqCard: {
    padding: '34px 24px 34px 24px ',
    boxShadow: '6px 10px 22px 0px rgba(18,71,155,0.20)',
  },
  image: {
    maxWidth: '60px',
    maxHeight: '58px',
  },
  helpWrapper: {
    padding: '12px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '60px !important',
    },
  },
  helpList: {
    listStyle: 'none',
    position: 'relative',
    padding: '0',
  },
  helpListItem: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  helpListSubItem: {
    display: 'inline-flex',
    flexShrink: '0',
    fontSize: '20px !important',
    fontWeight: '500',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
  },
  helpListLink: {
    textDecoration: 'none',
    color: '#31517A',
  },
  faqHeading: {
    textAlign: 'left',
    color: '#31517A',
    paddingBottom: '30px',
  },
  mainSubtitle: {
    fontSize: '20px',
    color: "#1160F7",
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '15px', 
    },
  }, 
  titleContainer: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2), 
    },
  }, 
  itemTitle: {
    fontWeight: '400',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  }
}));

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderBottom: '1px solid #E9E9E9',
    '&:not(:last-child)': {},
    '&:before': {
      borderBottom: '1px solid #E9E9E9',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    minHeight: 60,
    padding: '0 !important',
    '&$expanded': {
      minHeight: 60,
    },
    userSelect: "auto"
  },
  content: {
    color: '#37517A',
    '& p': {
      fontWeight: '400',
      fontSize: '18px',
    },
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    color: '#6B708F',
    paddingLeft: '0 !important',
    paddingBottom: '30px',
    lineHeight: '1.8',
  },
}))(MuiExpansionPanelDetails);


function FaqSection({
  title,
  helpContent,
  faqContent,
  secondaryTitle,
  secondaryText,
  subtitle,
  isContentParsed
}) {
  const [expanded, setExpanded] = React.useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const faqItems = isContentParsed ? faqContent : parseArrFromString(faqContent);
  const helpItems =isContentParsed ? helpContent : helpContent && parseArrFromString(helpContent);
  const classes = styles();

  return (
    <div className={classes.root} id="contact-us-section">
     {/* <FAQPageJsonLd
        mainEntity={faqItems.map(item => {
          return {
            questionName: item.title,
            acceptedAnswerText: item.text,
          }
        })}
      /> */}
      <Grid container spacing={3} className={classes.container} justifyContent="center">
        {!helpItems && <Grid item xs={12} className={classes.title}>
        {subtitle && <Typography component="div" variant="subtitle1" className={classes.mainSubtitle}>
            {subtitle}
          </Typography>}
          <div className={classes.titleContainer}><Typography component="div" variant="h3" className={classes.title}>
            {title}
          </Typography></div>
        </Grid>}
        <Grid item xs={12} md={helpItems ? 6 :12} className={classes.faqWrapper}>
          <Card className={classes.faqCard} >
            <CardContent>
              {helpItems && <Typography component="div" variant="h3" className={classes.faqHeading + ' ' + classes.subTitle}>
                {helpItems && title}
              </Typography>}
              {faqItems.map((item, index) => {
                return (
                  <Accordion
                    square
                    key={item.title}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index + 1}d-content`}
                      id={`panel${index + 1}d-header`}
                      expandIcon={<AddIcon />}
                    >
                      <Typography component="div" variant="h6" className={classes.itemTitle}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.text && (
                        <Typography component="div" variant="body1">
                          <Markdown children={item.text} />
                        </Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        {helpItems && (
          <Grid item xs={12} md={helpItems ? 6 : 12} className={classes.helpWrapper}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                marked="center"
                className={classes.mediumTitle + ' ' + classes.textInfo}
                component="h2"
              >
                {secondaryTitle}
              </Typography>
              {secondaryText && (
                <Typography component="div" marked="center" className={classes.body1}>
                  <Markdown children={secondaryText} />
                </Typography>
              )}
              <Grid item xs={12}>
                <ul className={classes.helpList}>
                  {helpItems.map((item) => {
                    return (
                      <li key={item.label} className={classes.helpListItem}>
                        <img
                          src={item.image}
                          className={classes.image + ' ' + classes.helpListSubItem}
                          alt={item.imageAlt}
                          loading="lazy"
                        />
                        <a href={item.link} className={classes.helpListLink}>
                          <Typography
                            variant="h3"
                            className={classes.textInfo + ' ' + classes.helpListSubItem}
                            component="h3"
                          >
                            {item.label}
                          </Typography>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

FaqSection.propTypes = {
  // title: PropTypes.string,
};

export default FaqSection;